import React from 'react'
import { isFunction } from 'ramda-adjunct'

export const withOptionalHelpModal = arg => WrappedComponent => {
  return class extends React.Component {
    displayName = `${WrappedComponent.displayName}withHelpModal`
    modals = {}

    openModal = () => this.modal.open()

    render() {
      const HelpModalComponent = isFunction(arg) ? arg(this.props) : undefined
      if (HelpModalComponent === undefined) return <WrappedComponent {...this.props} />
      return (
        <>
          <HelpModalComponent modalRef={ref => this.modal = ref}/>
          <WrappedComponent {...this.props} openModal={this.openModal} />
        </>
      )
    }
  }
}

export const withHelpModal = HelpModalComponent => WrappedComponent => {
  return class extends React.Component {
    modals = {}

    openModal = (title, copy, actionLabel, linkTo) => this.modal.open(title, copy, actionLabel, linkTo)

    render() {
      if (HelpModalComponent === undefined) return <WrappedComponent {...this.props} />
      return (
        <>
          <HelpModalComponent modalRef={ref => this.modal = ref}/>
          <WrappedComponent {...this.props} openModal={this.openModal} />
        </>
      )
    }
  }
}