import React from 'react'
import { HelpModal } from '../help/help-modal.component'

export const MinimumEnrollmentAlert = ({modalRef}) =>
  <HelpModal
    innerRef={modalRef}
    actionLabel="Got it!"
    title='Minimum enrollment requirement not met'
  >
    You need at least 12 credits in your major to qualify for TAP this semester.
  </HelpModal>
