import React from "react"
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { Box } from "./custom-box.component"
import { WhyUse } from "./why-use.component"

const styles = theme => ({
  noBold: {
    fontWeight: 'normal'
  },
  marginRight: {
    marginRight: theme.spacing.unit * 2
  },
  marginTop: {
    marginTop: theme.spacing.unit * 2
  },
  marginBottom: {
    marginBottom: theme.spacing.unit * 2
  },
  link: {
    color: "inherit"
  },
  bullet: {
    width: 16,
    filter: 'brightness(0.15) sepia(1) hue-rotate(180deg) saturate(5)',
    marginRight: theme.spacing.unit * 2
  }
})

export const StudentDetails = pipe(
  withStyles(styles)
)(({classes, studentData, cboStudentId}) => (
  <>
    <Box paddingTop paddingBottom>
      <Typography variant="subtitle2" color="inherit" gutterBottom>
        STUDENT NAME
      </Typography>
      <Box color="white">&nbsp;</Box>

      <Typography variant="subtitle2" color="inherit" className={classes.marginTop}>PROGRAM</Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>{studentData.programName}</Typography>

      <Typography variant="subtitle2" color="inherit" className={classes.marginTop}>STUDENT ID</Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        {cboStudentId}
      </Typography>

      <Typography variant="subtitle2" color="inherit" className={classes.marginTop}>COLLEGE</Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        {studentData.institution}
      </Typography>
    </Box>

    <Box color="secondary" paddingTop paddingBottom>
      <Typography variant="subtitle1" color="inherit">
        <i><b>* How was your risk calculated?</b></i>
      </Typography>

      <Typography variant="subtitle2" color="inherit" gutterBottom className={classes.marginTop}>YOUR DATA</Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Semester started college: <b>{studentData.semesterStarted}</b>
      </Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Cumulative GPA: <b>{studentData.cumulativeGpa}</b>
      </Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Earned Credits: <b>{studentData.creditsEarned}</b>
      </Typography>
     <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Opportunity program: <b>{studentData.inOpportunityProgram ? 'Yes' : 'No'}</b>
      </Typography>

      <Typography variant="subtitle2" color="inherit" gutterBottom className={classes.marginTop}>ASSUMPTIONS (estimated)</Typography>
      {studentData.noSemestersTapReceived && (
        <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
          Semesters of TAP received: <b>{studentData.noSemestersTapReceived}</b>
        </Typography>
      )}
      {studentData.noSemestersPellReceived && (
        <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
          Semesters of Pell received: <b>{studentData.noSemestersPellReceived}</b>
        </Typography>
      )}
      {studentData.noSemestersExcelsiorReceived && (
        <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
          Semesters of Excelsior received: <b>{studentData.noSemestersExcelsiorReceived}</b>
        </Typography>
      )}
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Required GPA for major: <b>2.0</b>
      </Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Attempted credits: <b>{studentData.creditsAttempted}</b>
      </Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
        Enrolled credits: <b>{studentData.creditsEnrolled}</b>
      </Typography>

      <Typography variant="subtitle2" color="inherit" className={classes.marginTop}>
        If any of the information is not accurate, visit FutureGrad
        (<a href="http://www.future-grad.com/claim-profile" className={classes.link}>www.future-grad.com/claim-profile</a>)
        to claim and revise your user profile.
      </Typography>
    </Box>

    <WhyUse />
  </>
))