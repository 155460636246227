import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { pipe } from 'ramda'
import React from 'react'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'
import { withTracking } from '../../common/trackClicks'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { StepSection } from './step-section.component'
import { EmptyIcon } from '../../checklist/item.component'
import { HelpLinkSmall } from '../../help/help-link-small.component'
import { HelpModal } from '../../help/help-modal.component'
import { withOptionalHelpModal } from '../../help/with-help-modal'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { checklistItems } from '../checklist-items'

const styles = theme => ({
  checkbox: {
    padding: 0,
    paddingRight: theme.spacing.unit
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  }
})

export const Checklist = pipe(
  excelsiorRegistrationStep('checklist'),
  withExcelsiorRegistrationData
)(class extends React.Component {
  handleChecklistChange = key => () =>
    this.props.handleChange({checklist: {...this.props.data.checklist, [key]: !this.props.data.checklist[key]}})

  render () {
    return (
      <StepSection header="See if you meet the requirements below">
        {checklistItems(this.props.data.relation).map(({key, checklistTitle, title, help}) =>
          <ExcelsiorChecklistItem
            key={key}
            title={checklistTitle}
            helpTitle={title}
            helpContent={help}
            checked={this.props.data.checklist[key]}
            onChange={this.handleChecklistChange(key)}
          />
        )}
      </StepSection>
    )
  }
})

const ExcelsiorChecklistItem = pipe(
  withStyles(styles),
  withOptionalHelpModal(({helpTitle, helpContent}) => props =>
    <HelpModal innerRef={props.modalRef} title={helpTitle}>{helpContent}</HelpModal>
  ),
  withTracking(props => ({category: CATEGORIES.excelsior, action: ACTIONS.openHelp, label: props.title}))
)(({checked, title, onChange, openModal, classes, track}) =>
  <div className={classes.item}>
    <Checkbox
      className={classes.checkbox}
      checked={checked}
      onChange={onChange}
      icon={<EmptyIcon fontSize="large" border />}
      checkedIcon={<CheckCircle fontSize="large"/>}
    />
    <div>
      <Typography variant='body2'>
        {title} <HelpLinkSmall text='What does this mean?' onClick={track(openModal)}/>
      </Typography>
    </div>
  </div>
)