import { withStyles } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames'
import ButtonBase from "@material-ui/core/ButtonBase"

const styles = theme => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.unit * 0.25}px 0`,
    alignItems: 'center',
    minWidth: '100%'
  },
  withBorder: {
    margin: '0 -20px',
    padding: '16px 20px',
    borderTop: `1px solid ${theme.palette.background.grey}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.background.grey}`
    }
  },
  withDarkBorder: {
    margin: '0 -20px',
    padding: '16px 20px',
    borderTop: `1px solid ${theme.palette.background.dark}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.background.dark}`
    }
  },
  withBackground: {
    backgroundColor: theme.palette.background.grey,
  },
  danger: {
    backgroundColor: 'red'
  },
  pointer: {
    cursor: 'pointer'
  },
  withDarkHover: {
    '&:hover': {
      backgroundColor: theme.palette.background.grey
    }
  },
  withLightHover: {
    '&:hover': {
      backgroundColor: `${theme.palette.background.paper} !important`
    }
  }
})

export const Row = withStyles(styles)(({classes, withBorder, withDarkBorder, withBackground, danger, children, onClick}) =>
  <ButtonBase
    disableRipple={!Boolean(onClick)}
    component="div"
    onClick={onClick}
    className={classNames(
      classes.row,
      {
        [classes.withBorder]: withBorder,
        [classes.withDarkBorder]: withDarkBorder,
        [classes.withBackground]: withBackground,
        [classes.withDarkHover]: onClick && !withBackground,
        [classes.withLightHover]: onClick && withBackground,
        [classes.danger]: danger,
        [classes.pointer]: onClick
      }
    )}
  >
    {children}
  </ButtonBase>
)