import { map, addIndex } from 'ramda'

const programPursuit = [
  {paymentNumber: 1, previousSemesterCompletedCredits: '0'},
  {paymentNumber: 2, previousSemesterCompletedCredits: '6'},
  {paymentNumber: 3, previousSemesterCompletedCredits: '6'},
  {paymentNumber: 4, previousSemesterCompletedCredits: '9'},
  {paymentNumber: 5, previousSemesterCompletedCredits: '9'},
  {paymentNumber: 6, previousSemesterCompletedCredits: '12'},
  {paymentNumber: 7, previousSemesterCompletedCredits: '12'},
  {paymentNumber: 8, previousSemesterCompletedCredits: '12'},
  {paymentNumber: 9, previousSemesterCompletedCredits: '12'},
  {paymentNumber: 10, previousSemesterCompletedCredits: '12'}
]

export const TAP_RULES =
  map(
    rules => addIndex(map)((rule, idx) => ({...programPursuit[idx], ...rule}), rules),
    {
      bachelor: [
        {gpa: '0.00', earnedCredits: '0'},
        {gpa: '1.5', earnedCredits: '3'},
        {gpa: '1.8', earnedCredits: '9'},
        {gpa: '1.8', earnedCredits: '21'},
        {gpa: '2.0', earnedCredits: '33'},
        {gpa: '2.0', earnedCredits: '45'},
        {gpa: '2.0', earnedCredits: '60'},
        {gpa: '2.0', earnedCredits: '75'},
        {gpa: '2.0', earnedCredits: '90'},
        {gpa: '2.0', earnedCredits: '105'},
      ],
      bachelorInOpportunityProgram: [
        {gpa: '0.00', earnedCredits: '0'},
        {gpa: '1.1', earnedCredits: '3'},
        {gpa: '1.2', earnedCredits: '9'},
        {gpa: '1.3', earnedCredits: '21'},
        {gpa: '2.0', earnedCredits: '33'},
        {gpa: '2.0', earnedCredits: '45'},
        {gpa: '2.0', earnedCredits: '60'},
        {gpa: '2.0', earnedCredits: '75'},
        {gpa: '2.0', earnedCredits: '90'},
        {gpa: '2.0', earnedCredits: '105'},
      ],
      associate: [
        {gpa: '0.00', earnedCredits: '0'},
        {gpa: '1.3', earnedCredits: '6'},
        {gpa: '1.5', earnedCredits: '15'},
        {gpa: '1.8', earnedCredits: '27'},
        {gpa: '2.0', earnedCredits: '39'},
        {gpa: '2.0', earnedCredits: '51'},
      ],
      associateInOpportunityProgram: [
        {gpa: '0.00', earnedCredits: '0'},
        {gpa: '0.5', earnedCredits: '3'},
        {gpa: '0.75', earnedCredits: '9'},
        {gpa: '1.3', earnedCredits: '18'},
        {gpa: '2.0', earnedCredits: '30'},
        {gpa: '2.0', earnedCredits: '45'},
        {gpa: '2.0', earnedCredits: '60'},
      ],
    }
  )