import React from 'react'
import { pipe } from "ramda"
import { withApiData } from "../common/with-api-data"
import { AidDetailsPage } from "../eligibility/aid-details-page.component"
import {sortedRequirementsForAidType} from "../common/requirements-utils"
import {aidNameForType} from "../registration/financial-aid/financial-aid-types"

export const AidDashboardDetails = pipe(
  withApiData('/api/eligibility')
)(({data: {financialAid}, match: {params: {type}}}) =>
  <AidDetailsPage
    aidName={aidNameForType(type)}
    requirements={sortedRequirementsForAidType(type, financialAid)}
  />
)