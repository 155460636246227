import { pipe } from 'ramda'
import React from 'react'
import {withStyles} from "@material-ui/core"
import MaterialButton from '@material-ui/core/Button'
import { Button } from '../../common/button.component'

const styles = (theme) => ({
  actionButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    flex: '1 1 auto',
    '&:not(:last-child)': {
      marginRight: '10px'
    },
    textTransform: 'none'
  }
})

export const NavigationButtons = pipe(
  withStyles(styles)
)(
  ({classes, handleNextClick, handleBackClick, nextLabel, disabled}) =>
    <div className={classes.actionButtons}>
      {
        handleBackClick &&
        <MaterialButton
          className={classes.button}
          onClick={handleBackClick}
          variant="outlined"
          size="small"
        >
          Back
        </MaterialButton>
      }
      {
        handleNextClick &&
        <Button
          text={nextLabel || "Next"}
          onClick={handleNextClick}
          disabled={disabled}
        />
      }
    </div>
)