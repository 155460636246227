export const rollbarAccessToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
export const environment = process.env.REACT_APP_ENVIRONMENT || 'local'
export const bitbucketCommit = process.env.REACT_APP_BITBUCKET_COMMIT || 'unknown'
export const rollbarEnabled = !!rollbarAccessToken
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '745880010384-q5qgredmgeiu9gsqtdsj9nsuohur1gq8.apps.googleusercontent.com'
export const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || "c34y713e"
export const googleAnalyticsTrackingId = process.env.REACT_APP_GA_TRACKING_ID || "UA-113490405-3"
export const helpheroId = process.env.REACT_APP_HELPHERO_ID || ""


export const apiUrl = (path) => {
  return path
}
