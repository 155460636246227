import { pipe } from 'ramda'
import React from 'react'
import { withUser } from '../../providers/user.provider'
import { postDataWithHandler } from '../../utils/api-utils'
import { TextInput } from '../common/text-input.component'
import { withHelpModal } from '../help/with-help-modal'
import { withDataValidation } from '../registration/common/with-data-validation'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import { Header } from '../common/header.component'
import {ActionSection} from "../common/action-section.component"
import {Button} from "../common/button.component"
import {TextLink} from "../common/text-link.component"
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"
import { Section } from "../common/section.component"

export const CunyResendVerification = pipe(
  withDataValidation({
    schoolEmail: {
      guard: ({schoolEmail}) => /.+@((.+\.edu)|u2i.com|getyenko.com)$/.test(schoolEmail),
      msg: "Please enter your school email - it ends with .edu"
    },
  }),
  withUser,
  withHelpModal(SimpleHelpModal)
)(class extends React.Component {
  displayName = 'CunyResendVerification'

  state = {schoolEmail: ''}

  handleInputChange = key => event => this.setState({[key]: event.target.value})

  handleForgotPasswordClick = () => this.props.validate(this.state) && postDataWithHandler(
    '/api/user/resend-verification',
    {schoolEmail: this.state.schoolEmail},
    (status, {data}) => {
      switch (status) {
        case 200: {
          this.props.openModal("Verification link sent!", "Check your email to verify your account.", "Got it")
          break
        }
        default: {
          this.props.openModal(...getErrorMessage(data.reason))
          break
        }
      }
    }
  )

  propsForTextInput = key => ({
    value: this.state[key],
    onChange: this.handleInputChange(key),
    error: this.props.errors[key]
  })

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <Header
          title="Your account is not verified?"
          subtitle="Don't worry, we got you. Just give us your school email."
        />

        <TextInput  type="email" label="School email" {...this.propsForTextInput('schoolEmail')}/>

        <ActionSection>
          <Button text="Send verification link" onClick={this.handleForgotPasswordClick} />
          <TextLink label="Log In" to={routes.CUNY_LOGIN} />
        </ActionSection>
      </Section>
    )
  }
})