import React from "react"
import { withStyles } from "@material-ui/core"
import { pipe } from "ramda"
import Typography from "@material-ui/core/Typography"
import { Row } from "../common/row.component"
import { TableCell } from "../common/table-cell.component"
import { routes } from "../routing/routes-paths"
import { withRouter } from "react-router-dom"
import { trackEvent } from "../../utils/intercom-analytics"
import { Button } from "../common/button.component"

const styles = theme => ({
  goals: {
    margin: `0 -${theme.spacing.unit * 2.5}px`,
    padding: theme.spacing.unit * 2.5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  prediction: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '100%'
  },
  grades: {
    margin: `${theme.spacing.unit * 1.5}px 0 ${theme.spacing.unit * 2.5}px 0`
  },
  header: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  row: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    minHeight: theme.spacing.unit * 7,
    marginBottom: 1
  },
  grade: {
    width: '0.7em',
    textAlign: 'left',
    fontSize: '24px',
  }
})

export const Goals = pipe(
  withStyles(styles),
  withRouter
)(class extends React.Component {
  handleClick = () => {
    trackEvent('updated-goal', {source: 'dashboard/update-goal'})
    this.props.history.push(routes.COURSES_GRADE_PICKER)
  }

  render () {
    const {classes, goals, academicPrediction} = this.props

    return (
      <div data-tour="dashboard_goals" className={classes.goals}>
        <AcademicPrediction academicPrediction={academicPrediction} />
        <GoalsTable goals={goals}/>
        <Button
          onClick={this.handleClick}
          text="Update your goals"
          variant="outlined"
          color="inherit"
        />
      </div>
    )
  }
})

const GoalsTable = pipe(
  withStyles(styles)
)(class extends React.Component {
  render () {
    const {classes, goals} = this.props

    return(
      <div className={classes.grades}>
        <div className={classes.header}>
          <Row>
            <TableCell heading>Classes</TableCell>
            <TableCell heading center>Credits</TableCell>
            <TableCell heading center>Grades</TableCell>
          </Row>
        </div>
        {goals.map(({name, credits, targetGrade}, index) =>
          <div className={classes.row} key={index}>
            <TableCell>{name}</TableCell>
            <TableCell center>{credits}</TableCell>
            <TableCell center>
              <Typography className={classes.grade}>
                {targetGrade}
              </Typography>
            </TableCell>
          </div>
        )}
      </div>
    )
  }
})

const AcademicPrediction = pipe(
  withStyles(styles)
)(({classes, academicPrediction}) =>
  <div className={classes.prediction}>
    <div>
      <Typography variant="h5" color="inherit">Cumulative GPA</Typography>
      <Typography variant="h3" color="inherit">{academicPrediction.cumulativeGpa}</Typography>
    </div>
    <div>
      <Typography variant="h5" color="inherit">Earned Credits</Typography>
      <Typography variant="h3" color="inherit">{academicPrediction.cumulativeEarnedCredits}</Typography>
    </div>
  </div>
)