import { concat, dropWhile, includes, intersection, find, pipe, prop, reverse, drop, without, T } from 'ramda'
import { FINANCIAL_AID_TYPES } from './financial-aid/financial-aid-types'
import { routes } from "../routing/routes-paths"

const pages = [
  {path: '/registration/basics', guard: T},
  {path: '/registration/counseling-organization', guard: T},
  {path: '/registration/financial-aid/receiving', guard: T},
  {
    path: '/registration/financial-aid/lost',
    guard: ({financialAid}) => without(financialAid.receiving, FINANCIAL_AID_TYPES).length > 0
  },
  {
    path: '/registration/financial-aid/interested-in',
    guard: ({financialAid}) => !includes('EXCELSIOR', concat(financialAid.receiving, financialAid.lost))
  },
  {
    path: '/registration/financial-aid-details/excelsior',
    guard: ({financialAid}) => includes('EXCELSIOR', concat(financialAid.receiving, financialAid.interestedIn))
  },
  {
    path: '/registration/financial-aid-details/tap',
    guard: ({financialAid}) => includes('TAP', concat(financialAid.receiving, financialAid.lost))
  },
  {
    path: '/registration/financial-aid-details/federal',
    guard: ({financialAid}) => intersection(['PELL', 'STAFFORD'], concat(financialAid.receiving, financialAid.lost)).length > 0
  },
  {path: '/registration/academic-record', guard: T},
  {path: routes.DASHBOARD, guard: T}
]

const getNextRoute = (pages, currentPath, state) =>
  pipe(
    dropWhile(({path}) => path !== currentPath),
    drop(1),
    find(({guard}) => guard(state)),
    prop('path')
  )(pages)

export const next = (currentPath, state) => getNextRoute(pages, currentPath, state)
export const prev = (currentPath, state) => getNextRoute(reverse(pages), currentPath, state)

export const getRegistrationStep = (currentStep, state) => {
  switch (currentStep) {
    case 'financial_aid': return '/registration/financial-aid/receiving'
    case 'financial_aid_details': return next('/registration/financial-aid/interested-in', state)
    case 'academic_record': return '/registration/academic-record'
    case 'organization_affiliation': return '/registration/counseling-organization'
    default: return '/registration/basics'
  }
}