import { withStyles } from '@material-ui/core'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  expansionPanel: {
    margin: '0px -20px',
    '&:before': {
      opacity: 0
    }
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.background.grey
  }
})

const Disclaimer = pipe(
  withStyles(styles),
)(({classes, title, content}) =>
  <ExpansionPanel className={classes.expansionPanel}>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon fontSize="large"/>}
                           className={classes.expansionPanelSummary}>
      <Typography variant="body2" className="bold">{title}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography variant="body2">{content}</Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>
)

export const FutureGradDisclaimer = () =>
  <Disclaimer
    title="DISCLAIMER - NOT OFFICIAL RECORD"
    content="FutureGrad’s analysis and estimates are based on the information you provide to us and
        published SAP requirements on institutions’ websites. They are for illustrative and informational
        purposes only. All official financial aid information is stored in your institution’s financial aid
        system. The final determination of eligibility will be made ONLY by the Office of Financial Aid."
   />

export const ExcelsiorDisclaimer = () =>
  <Disclaimer
    title="DISCLAIMER: NOT OFFICIAL"
    content="This screener is an informational tool to help students better understand the eligibility requirements
    for the Excelsior Scholarship. It is not affiliated with the NYS Higher Education Services Corporation (HESC),
    SUNY or CUNY. The final determination of eligibility for the Excelsior Scholarship will be made by HESC only."
  />
