import classnames from 'classnames'
import React from 'react'
import Typography from '@material-ui/core/Typography'

export const Title = ({children}) =>
  <Typography gutterBottom component="h1" variant="h3">{children}</Typography>

export const Header = ({children, bold}) =>
  <Typography className={classnames({bold})} gutterBottom component="h2" variant="h6">{children}</Typography>

export const Paragraph = ({children}) =>
  <Typography align="justify" paragraph component="p" variant="body1">{children}</Typography>

export const Subtitle = ({children}) =>
  <Typography paragraph component="p" variant="subtitle1">{children}</Typography>

export const MailTo = ({email}) =>
  <a href={`mailto:${email}`}>{email}</a>

export const LinkTo = ({link}) =>
  <a href={link}>{link}</a>

export const ListItem = ({marker, children}) =>
  <div style={{display: 'flex'}}>
    <Typography component="p" variant="body1" style={{minWidth: '30px'}}>{marker}</Typography>
    <Typography component="p" variant="body1">{children}</Typography>
  </div>
