import Typography from '@material-ui/core/Typography'
import Fuse from 'fuse.js'
import { pipe } from 'ramda'
import React from 'react'
import Downshift from 'downshift'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { boxShadow } from '../../../styles/theme'
import { withApiData } from '../../common/with-api-data'

function renderInput(inputProps) {
  const {InputProps, classes, ref, ...other} = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

function renderSuggestion({suggestion, index, itemProps, highlightedIndex, selectedItem}) {
  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem && (selectedItem.name || '').indexOf(suggestion.name) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <Typography variant="body2">{suggestion.name}</Typography>
    </MenuItem>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 9999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    top: 60,
    boxShadow: boxShadow,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    fontSize: 16
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
})

export const SchoolSearch = pipe(
  withStyles(styles),
  withApiData((props) => props.institutionsListPath || `/api/institutions`)
)(class extends React.Component {
  componentDidMount() {
    this.fuse = new Fuse(
      this.props.data,
      {
        caseSensitive: true,
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["name"]
      }
    )
  }

  getSuggestions = value => {
    return this.fuse.search(value).slice(0, 5)
  }

  render () {
    const {classes, onChange, data: institutions, value, error, placeholder, noLabel} = this.props
    return (
      <div className={classes.root}>
        <Downshift
          id="downshift-simple"
          onChange={onChange}
          itemToString={item => (item ? item.name : '')}
          initialSelectedItem={institutions.find(({id}) => id === value)}
        >
          {({
              getInputProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  placeholder: placeholder || 'Which college do you attend?',
                }),
                label: !noLabel && (placeholder || 'Which college do you attend?'),
                error: Boolean(error),
                helperText: Boolean(error) && error
              })}
              <div {...getMenuProps()}>
                {isOpen && inputValue ? (
                  <Paper className={classes.paper} square>
                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({item: suggestion}),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    )
  }
})
