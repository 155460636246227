import { Typography, withStyles } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import { pipe } from 'ramda'
import React from 'react'
import { Input } from '../common/input.component'
import { withDataValidation } from '../common/with-data-validation'
import { SisLink } from '../common/sis-link.component'
import { RemedialCoursesHelp } from '../help/remedial-courses-help.component'
import { MajorDeclaredHelp } from '../help/major-declared-help.component'
import { TapSemestersHelp } from '../help/tap-semesters-help.component'
import { GreyBox } from '../../common/grey-box.component'
import { CheckboxWithLabel } from '../common/checkbox.component'
import { Section } from '../../common/section.component'
import { RegistrationStep } from '../common/registration-step.component'
import { YesNoButtons } from '../common/yes-no-buttons.component'

const styles = theme => ({
  tapTitle: {
    marginBottom: theme.spacing.unit
  }
})

export const Tap = pipe(
  withStyles(styles),
  withDataValidation({
    paymentsReceived: {
      guard: ({paymentsReceived}) => (paymentsReceived === undefined) ||
        (0 <= parseInt(paymentsReceived, 10) && parseInt(paymentsReceived, 10) <= 10),
      msg: 'Should be between 0 and 10'
    }
  })
)(class extends React.Component {
  constructor(props) {
    super(props)

    const {paymentsReceived, hasDeclaredMajor, remedial} = this.props.details
    this.state = {
      paymentsReceived: paymentsReceived,
      hasDeclaredMajor: hasDeclaredMajor || false,
      remedial: {
        math: remedial.math || false,
        english: remedial.english || false,
        writing: remedial.writing || false
      }
    }
  }

  handlePaymentsReceivedChange = event => this.setState({paymentsReceived: event.target.value})
  handleMajorDeclaredChange = value => () => this.setState({hasDeclaredMajor: value})
  handleRemedialChange = course => () => this.setState({
    remedial: {
      ...this.state.remedial,
      [course]: !this.state.remedial[course]
    }
  })

  updatedDetails = () => [{type: 'TAP', details: this.state}]
  handleNextClick = () => this.props.validate(this.state) && this.props.handleAidDetails(this.updatedDetails(), true)
  handleBackClick = () => this.props.handleAidDetails(this.updatedDetails(), false)

  render() {
    return (
      <RegistrationStep
        stepTitle="Financial Aid Details (4/5)"
        title="About your TAP grant"
        subTitle={<>This information can be found in <SisLink/> under Financial Aid.</>}
        handleNextClick={this.handleNextClick}
        handleBackClick={this.handleBackClick}
      >
        <Typography variant="h5" className={this.props.classes.tapTitle}>TAP Grant</Typography>
        <Section
          header="How many semesters of TAP have you received?"
          helpText="Does this need to be accurate?"
          helpModal={TapSemestersHelp}
          trackingLabel="TAP - payments received"
        >
          <Input
            value={this.state.paymentsReceived}
            onChange={this.handlePaymentsReceivedChange}
            error={this.props.errors.paymentsReceived}
            placeholder='Ex. 2'
          />
        </Section>
        <Section
          header="Have you officially declared a major yet?"
          helpText="Why is this important?"
          helpModal={MajorDeclaredHelp}
          trackingLabel="TAP - major"
        >
          <YesNoButtons
            value={this.state.hasDeclaredMajor}
            handleYesClick={this.handleMajorDeclaredChange(true)}
            handleNoClick={this.handleMajorDeclaredChange(false)}
          />
        </Section>
        <GreyBox>
          If you have not yet declared your major, you will have to in order to keep your financial aid. Look out for
          your school's deadline.
        </GreyBox>
        <Section
          header="Have you taken (or need to take) any developmental/remedial courses?"
          helpText="Why is this important?"
          helpModal={RemedialCoursesHelp}
          trackingLabel="TAP - remedial"
        >
          <FormGroup row>
            <CheckboxWithLabel
              checked={this.state.remedial.math}
              onChange={this.handleRemedialChange('math')}
              label="Math"
            />
            <CheckboxWithLabel
              checked={this.state.remedial.english}
              onChange={this.handleRemedialChange('english')}
              label="English"
            />
            <CheckboxWithLabel
              checked={this.state.remedial.writing}
              onChange={this.handleRemedialChange('writing')}
              label="Writing"
            />
          </FormGroup>
        </Section>
      </RegistrationStep>
    )
  }
})
