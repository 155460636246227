import { map, pipe, prop, sortBy, sum } from 'ramda'
import React from 'react'
import { Button } from '../common/button.component'
import { CourseItem } from './course-item.component'
import { withApiData } from '../common/with-api-data'
import { withCourseDialog } from './with-course-dialog'
import { routes } from "../routing/routes-paths"
import { ActionSection } from "../common/action-section.component"
import { Row } from "../common/row.component"
import { TableCell } from "../common/table-cell.component"
import { withStyles } from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { trackEvent } from "../../utils/intercom-analytics"
import { withHelpModal } from "../help/with-help-modal"
import { HelpModal } from "../help/help-modal.component"
import { SubSection } from "../common/sub-section.component"


const styles = theme => ({
  marginTop: {
    marginTop: theme.spacing.unit * 2
  }
})

const EnrollmentAlert = ({modalRef}) =>
  <HelpModal
    innerRef={modalRef}
    actionLabel="Got it!"
    title="Enrolled credits don't match"
  >
    Your declared enrolled credits don't match with the sum of enrolled credits in courses.
  </HelpModal>

export const CourseList = pipe(
  withStyles(styles),
  withCourseDialog,
  withHelpModal(EnrollmentAlert),
  withApiData('/api/grades', {}, 'grades'),
  withApiData(({term}) => `/api/academic-record/${term}`, {}, 'academicRecord'),
  withRouter
)(class extends React.Component {
  sumCoursesCredits = (courses) => pipe(
    map(prop('credits')),
    sum
  )(courses)

  enrollmentRequirementMet = () => {
    const {creditsEnrolled} = this.props.academicRecord
    return creditsEnrolled == null || this.sumCoursesCredits(this.props.courses) >= creditsEnrolled
  }

  handleButtonClick = () => {
    if (this.enrollmentRequirementMet()) {
      trackEvent('added-classes')
      this.props.history.push(this.props.hasGoals ? routes.COURSES_DEGREE_PICKER : routes.ELIGIBILITY_START)
    } else {
      this.props.openModal()
    }
  }

  render() {
    const {openDialog, courses, grades, refreshCourseData} = this.props

    return (
      <>
        {courses.length > 0 &&
        <Row>
          <TableCell heading>COURSE NAME</TableCell>
          <TableCell heading center>CREDITS</TableCell>
          <TableCell heading/>
        </Row>
        }
        {sortBy(prop('id'), courses).map(courseData =>
          <CourseItem
            key={courseData.id}
            data={courseData}
            grades={grades}
            refreshCourseData={refreshCourseData}
            openDialog={openDialog}
          />
        )}

        <SubSection secondary marginBottom>
          <Row>
            <TableCell bold>Enrolled Credits in courses</TableCell>
            <TableCell
              center
              error={!this.enrollmentRequirementMet()}
            >
              {this.sumCoursesCredits(courses)}
            </TableCell>
            <TableCell/>
          </Row>
          <Row>
            <TableCell bold>Enrolled Credits</TableCell>
            <TableCell center>{this.props.academicRecord.creditsEnrolled}</TableCell>
            <TableCell/>
          </Row>
        </SubSection>

        <ActionSection>
          <Button
            dataTour="add_classes_button"
            marginBottom
            variant="outlined"
            text={courses.length > 0 ? '+ Add another course' : '+ Add a course'}
            onClick={openDialog({})}
          />
          <Button onClick={this.handleButtonClick} disabled={courses.length === 0} text="Done"/>
        </ActionSection>
      </>
    )
  }
})
