import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Header, MailTo, Paragraph, Subtitle, Title, ListItem, LinkTo } from './common.component'

export const TermsOfService = () =>
  <>
    <Title>FUTUREGRAD TERMS OF USE</Title>
    <Subtitle>
      Last Updated: October 2, 2019
    </Subtitle>

    <Header>
      INTRODUCTION
    </Header>

    <Paragraph>
      Yenko, Inc. owns and operates the FutureGrad web and mobile-based platform (“FutureGrad Platform”, “Platform”,
      “us” or “we”). By accessing the FutureGrad Platform, you agree to be bound by these Terms of Use. In addition, you
      represent that you are lawfully able to enter into contracts and agree to be bound by these Terms of Use. If you
      have agreed to these Terms of Use on behalf of your organization, you represent that you have the authority to
      bind that organization to this Agreement, and that you have the right to upload or authorize upload of student
      data. If you do not have the necessary authority, or if you do not agree with these Terms of Use, then you may not
      use the FutureGrad Platform.
    </Paragraph>

    <Paragraph>
      FutureGrad reserves the right to change these Terms of Use at any time, however we will provide you with notice
      prior to making such changes. Your continued use of the FutureGrad Platform constitutes your agreement to any
      updated terms. Should we make material changes to the Terms of Use impacting the FutureGrad Platform, we will
      provide you with notice prior to making such changes and request your consent in accordance with applicable law.
      The “last updated” date indicates when these Terms of Use were last revised.
    </Paragraph>

    <Header>
      INTELLECTUAL PROPERTY
    </Header>

    <Paragraph>
      You acknowledge that the FutureGrad Platform, including all trademarks, service marks and logos, are owned by or
      licensed to FutureGrad and are protected by copyright and other intellectual property rights, and that you have no
      rights to transfer or reproduce the FutureGrad Platform, or prepare any derivative works with respect to, or to
      disclose confidential information pertaining to, the FutureGrad Platform. This Agreement does not transfer or
      convey any rights of ownership in or related to the FutureGrad Platform, or intellectual property rights owned by
      FutureGrad to you.
    </Paragraph>

    <Header>
      LICENSE
    </Header>

    <Paragraph>
      In order to allow FutureGrad to provide the Platform, you hereby grant to FutureGrad a limited, non-exclusive,
      sublicensable (as necessary to use the Platform), worldwide, royalty-free, and transferable (only to a successor)
      right and license to (i) use the information, data, content, reviews, comments and other materials uploaded by you
      to the FutureGrad Platform (“User Content”) solely for purposes of furnishing the services provided by the
      FutureGrad Platform to you and in accordance with these Terms of Use and our Privacy Policy; (ii) use, copy,
      store, distribute, publicly perform and display, modify, and create derivative works (such as changes we make so
      that your content works better with our Platform) such content as necessary to provide, improve and make the
      Platform available to you and other users, including through any future media in which the Platform may be
      distributed, (iii) use and disclose metrics and analytics regarding the User Content in an aggregate or other
      non-personally identifiable manner (including, for use in improving our service or in marketing and business
      development purposes), (iv) use any User Content that has been de-identified for product development, research or
      other purposes in accordance with applicable laws; and (v) use for other purposes permitted by the FutureGrad
      Privacy Policy. You may not modify, copy, distribute, broadcast, transmit, reproduce, publish, license, transfer,
      sell, scrape, mirror, frame, or otherwise use any information or material obtained from or through the FutureGrad
      Platform. You are solely responsible for obtaining all rights, permissions, and authorizations to provide the User
      Content for use as contemplated under this Section .You also warrant to FutureGrad that you will not use the
      FutureGrad Platform for any purpose that are unlawful, prohibited by any applicable regulation or are otherwise
      inconsistent with this Agreement.
    </Paragraph>

    <Header>
      USER RESPONSIBILITIES
    </Header>

    <Paragraph>
      To use the FutureGrad Platform you must create an account (the “Account”). In order to create your Account you
      will be asked to create an account and provide your name, email address, phone number), account information and
      password, demographic or other information (such as your school, gender, age or birthday, and other information
      about your interests and preferences), and verified third-party account credentials (collectively “Personal
      Information”). You are solely responsible for all activities that occur in connection with your account, and
      represent that you will comply with these Terms of Use and all applicable laws. You agree that you will maintain
      the confidentiality of your account name and password; notify us immediately of any unauthorized access or use of
      your account, ensure that your account is used at all times in compliance with the FutureGrad Terms of Use and
      Privacy Policy and not impersonate another user or provide false information in an attempt to gain access to the
      FutureGrad Platform.
    </Paragraph>

    <Paragraph>
      We will add information to your Account provided by your University or College or College Preparation Program.
      This will include information such as the financial aid, coursework, grades, GPA, course grades in progress, and
      any other information that the University may provide to us in connection with our Services. FutureGrad
      understands that it is important that our partner Universities and Colleges comply with the Family Education
      Rights and Privacy Act (“FERPA”) and related regulations. Certain information collected through your University or
      College that may be provided to FutureGrad that is directly related to a student and maintained by your University
      or College, may be considered an education record (“education record”) under FERPA.
    </Paragraph>

    <Header>
      RESTRICTIONS
    </Header>

    <Paragraph>
      <Paragraph>You agree that you will not (and will not allow any third party to):</Paragraph>
      <ListItem marker='(a)'>
        Upload, post, e-mail or otherwise transmit any User Content that is unlawful, harmful, threatening,
        intimidating, abusive, defamatory, obscene, libelous, invasive of another’s privacy, disrespectful, hateful, or
        racially, ethnically or otherwise objectionable;
      </ListItem>
      <ListItem marker='(b)'>
        Forge headers or otherwise manipulate identifiers in order to disguise the origin of any materials transmitted
        through the FutureGrad Platform;
      </ListItem>
      <ListItem marker='(c)'>
        Upload, post, e-mail or otherwise transmit any materials that you do not have the right to transmit under any
        law or under contractual relationships;
      </ListItem>
      <ListItem marker='(d)'>
        Upload, post, e-mail or otherwise transmit any material that infringes any patent, trademark, trade secret,
        copyright or other proprietary rights of any party;
      </ListItem>
      <ListItem marker='(e)'>
        Upload, post, e-mail or otherwise transmit any material that contains software viruses or worms or any other
        computer code, files or programs designed to disable, interrupt, destroy, redirect, monitor another user’s
        usage, limit or otherwise inhibit the functionality of any computer software or hardware or telecommunications
        equipment;
      </ListItem>
      <ListItem marker='(f)'>
        Interfere with or disrupt the FutureGrad Platform or servers or networks connected to the FutureGrad Platform,
        or disobey any requirements, procedures, policies or regulations of networks connected to the FutureGrad
        Platform; or
      </ListItem>
      <ListItem marker='(g)'>
        Intentionally or unintentionally violate any applicable local, state, national or international law or
        regulation.
      </ListItem>
      <ListItem marker='(h)'>
        Provide inaccurate information or impersonate another person or entity.
      </ListItem>
    </Paragraph>

    <Header>
      PRIVACY
    </Header>

    <Paragraph>
      FutureGrad represents that it will comply with all applicable laws, including those related to protection of
      personally identifiable information stored and maintained through the FutureGrad Platform.
    </Paragraph>

    <Paragraph>
      We are committed to maintaining your privacy, and maintain a Privacy Policy, which may be viewed
      {' '}<LinkTo link='https://future-grad.com/privacy'/>. All personally identifiable information you provide to us
      is
      subject to the
      Privacy Policy, and acceptance of these Terms of Use constitutes consent to our collection and use of personal
      information as described in the Privacy Policy.
    </Paragraph>

    <Header>
      FAMILY EDUCATIONAL RIGHTS AND PRIVACY ACT (“FERPA”)
    </Header>

    <Paragraph>
      If you are employed by a school or district subject to FERPA, you agree to appoint FutureGrad as a “school
      official” as that term is defined in FERPA and as interpreted by the Family Policy Compliance Office, and
      determine that FutureGrad has a “legitimate educational interest” for the purpose of delivering the Services in
      accordance with these Terms. FutureGrad agrees that it shall be bound by all relevant provisions of FERPA,
      including operating under the direct control of your school or district with respect to handling of “personally
      identifiable information” from “education records,” as those terms are defined in FERPA. FutureGrad further agrees
      that personally identifiable information from students will not be disclosed to third parties except as required
      to provide Services to you contemplated in this Agreement. Any third party users will be bound to manage
      personally identifiable information in compliance with the Privacy Policy, security policies and all applicable
      laws, and to use the personally identifiable information for the sole and limited purpose of providing the
      Services to the user.
    </Paragraph>

    <Paragraph>
      FutureGrad may use education records that have been de-identified for product development, research or other
      purposes permitted by applicable law (“de-Identified data”), including:
    </Paragraph>

    <Paragraph>
      <ListItem marker='&#9679;'>
        To demonstrate the effectiveness of the FutureGrad Platform, including in our marketing materials; and
      </ListItem>
      <ListItem marker='&#9679;'>
        To develop and improve our educational products.
      </ListItem>
    </Paragraph>

    <Paragraph>
      FutureGrad agrees not to attempt to re-identify the de-Identified data and not to transfer the de-Identified data
      to a third-party unless that party agrees not to attempt re-identification.
    </Paragraph>

    <Header>
      SECURITY
    </Header>

    <Paragraph>
      FutureGrad implements and maintains commercially reasonable security practices appropriate to the nature of the
      personally identifiable information collected and maintained through the FutureGrad Platform, in order to protect
      such information from unauthorized access, destruction, use, modification or disclosure. Such practices include,
      but are not limited to use of firewalls, encryption and authentication techniques. The Internet, however, is not
      perfectly secure, nor are all security risks reasonably foreseeable, and FutureGrad shall not be responsible for
      security incidents not reasonably within its control.
    </Paragraph>

    <Header>
      USER CONTENT
    </Header>

    <Paragraph>
      The Platform may contain areas in which you may post reviews or make comments. By using these areas, you
      acknowledge and agree that the User Content you provide in these areas may be available to other users. FutureGrad
      is not liable for any statements, representations or comments provided by its users in any public forum. Although
      FutureGrad has no obligation to screen, edit or monitor any of the comments posted to its Platform, FutureGrad
      reserves the right to remove, edit or refuse to post such User Content you provide in these areas in its sole
      discretion.
    </Paragraph>

    <Paragraph>
      You may also provide us with suggestions, comments or other feedback (“Feedback“) about our products and services.
      We may use Feedback for any purpose without obligation of any kind in connection with our business, including the
      enhancement of our products and the Platform.
    </Paragraph>

    <Header>
      OUR POLICY TOWARD CHILDREN
    </Header>

    <Paragraph>
      The FutureGrad Site and Platform are not intended for use by children under the age of 13 and does not knowingly
      collect personal information from children under 13 years of age. If you believe that your child may have provided
      us with personal information without your consent, you may contact us at <MailTo email="support@future-grad.com"/> If
      we learn that we have collected Personal Information of a child under 13 we will take steps to delete such
      information from our files as soon as possible.
    </Paragraph>

    <Header>
      CHANGES TO SITE
    </Header>

    <Paragraph>
      FutureGrad may add, change, discontinue or remove any portion of the FutureGrad Platform at any time, without
      notice.
    </Paragraph>

    <Header>
      LINKS
    </Header>

    <Paragraph>
      The FutureGrad Platform may contain links to third party websites, including social networking websites. These
      links are provided for your convenience, and inclusion of links on the FutureGrad Platform does not suggest an
      endorsement. We are not responsible for the contents or transmission of any linked site or for ensuring that the
      linked sites are error and virus free. Linked sites are subject to their own terms of use and privacy policies,
      and we encourage you to read them.
    </Paragraph>

    <Header>
      INDEMNIFICATION
    </Header>

    <Paragraph>
      To the extent permissible by law, you will defend and indemnify FutureGrad and hold it and its affiliates,
      officers, directors, managers, employees, agents, vendors, merchants sponsors, providers, and licensors harmless
      from any and all claims, actions, demands, proceedings, losses, deficiencies, damages, liabilities, costs, and
      expenses (including but not limited to reasonable attorneys’ fees and all related costs and expenses) incurred by
      them as a result of any claim, judgment, or adjudication related to or arising directly or indirectly from any or
      all of the following: (i) your use of the FutureGrad Platform; (ii) any information you submit, post, or transmit
      through the FutureGrad Platform; (iii) breach of any of your obligations, representations, or warranties in these
      Terms of Use; or (iv) your violation of any rights of another person.
    </Paragraph>

    <Header bold>
      DISCLAIMER OF WARRANTIES
    </Header>

    <Paragraph>
      THE FUTUREGRAD PLATFORM IS PROVIDED “AS IS” AND “AS-AVAILABLE,” WITH ALL FAULTS, AND WITHOUT WARRANTY OF ANY KIND.
      EXCEPT FOR ANY EXPRESS WARRANTY PROVIDED HEREIN, FUTUREGRAD AND ITS LICENSORS AND SUPPLIERS DISCLAIM ALL OTHER
      WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
      FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, QUALITY OF INFORMATION, OR TITLE/NON-INFRINGEMENT, AND ALL SUCH
      WARRANTIES ARE HEREBY SPECIFICALLY DISCLAIMED.
    </Paragraph>

    <Paragraph>
      FUTUREGRAD ASSUMES NO RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS ON THE FUTUREGRAD PLAFORM, ANY FAILURES, DELAYS
      OR INTERRUPTIONS IN THE PLATFORM’S ACCESSIBILITY, ANY LOSSES OR DAMAGES ARISING FROM THE USE OF THE PLATFORM, ANY
      CONDUCT BY OTHER USERS ON THE FUTUREGRAD PLATFORM, OR UNAUTHORIZED ACCESS TO OR USE OF THE FUTUREGRAD PLATFORM
    </Paragraph>

    <Header bold>
      LIMITATION OF LIABILITY
    </Header>

    <Paragraph>
      YOU UNDERSTAND AND AGREE THAT FUTUREGRAD WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PRODUCTS, USE, DATA OR
      OTHER INTANGIBLE LOSSES, EVEN IF FUTUREGRAD HAS BEEN ADVISED OF THE POSSIBILITIES OF THOSE DAMAGES, RESULTING FROM
      YOUR USE OR INABILITY TO USE THE FUTUREGRAD PLATFORM, SERVICES, CONTENT OR SOFTWARE, THE COST OF OBTAINING
      SUBSTITUTE SERVICES RESULTING FROM ANY LOSS OF DATA, INFORMATION, ENTERED INTO THROUGH THE PLATFORM, OR STATEMENTS
      OR CONDUCT OF ANY THIRD PARTY, OR ANY OTHER MATTER RELATED TO THE PLATFORM, SERVICES, CONTENT OR SOFTWARE. YOU
      UNDERSTAND AND AGREE THAT YOUR USE OF THE FUTUREGRAD PLATFORM IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE
      FUTUREGRAD OR ITS AFFILIATES DIRECTLY OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING
      FROM YOUR USE OF THE FUTUREGRAD PLATFORM.
    </Paragraph>

    <Paragraph>
      CERTAIN STATE JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
      CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY
      NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
    </Paragraph>

    <Header>
      NOTICES
    </Header>

    <Paragraph>
      We will provide you any notices regarding the FutureGrad Platform by posting the notice on the Platform, as
      applicable, or by sending to you by e-mail to the email address you maintain as part of your account.
    </Paragraph>

    <Header>
      APPLICABLE LAW; JURISDICTION AND VENUE
    </Header>

    <Paragraph>
      We control the FutureGrad Platform from our offices within the United States. We make no representation that the
      FutureGrad Platform is appropriate, legal or available for use in other locations. You may not use or export the
      Content in violation of United States export laws and regulations. Any claim relating to the FutureGrad Platform
      shall be governed by the laws of the State of New York, without reference to its choice of law provisions. If
      there is a dispute between you and us, you expressly agree that exclusive jurisdiction and venue reside in the
      state and federal courts located in New York, NY.
    </Paragraph>

    <Header>
      SEVERABILITY
    </Header>

    <Paragraph>
      If any of the part of these Terms of Use are determined to be invalid or unenforceable pursuant to applicable law,
      they will be severable from the remainder of these Terms of Use and will not cause the invalidity or
      unenforceability of the remainder of these Terms of Use.
    </Paragraph>

    <Header>
      DIGITAL MILLENNIUM COPYRIGHT ACT
    </Header>

    <Paragraph>
      <Paragraph>
        If you believe that materials hosted by FutureGrad infringe your copyright, you (or your agent) may notice
        requesting that the materials be removed. Notice must be provided in writing and must include the following
        information:
      </Paragraph>
      <ListItem marker='a.'>
        A signature of the person authorized to act on behalf of the owner of the copyright interest;
      </ListItem>
      <ListItem marker='b.'>
        Identification of the copyrighted work that you claim has been infringed
      </ListItem>
      <ListItem marker='c.'>
        The location of the work you claim is infringing (e.g., URL) or enough detail that we may find it;
      </ListItem>
      <ListItem marker='d.'>
        Your name, address, telephone number, and e-mail address;
      </ListItem>
      <ListItem marker='e.'>
        A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright
        owner, its agent, or the law; and
      </ListItem>
      <ListItem marker='f.'>
        A statement, made under penalty of perjury, that the information in your notice is accurate and that you are the
        copyright owner or authorized to act on the copyright owner's behalf.
      </ListItem>
    </Paragraph>

    <Paragraph>
      Notices should be sent to:<MailTo email="support@future-grad.com"/>.
    </Paragraph>

    <Paragraph>
      <Typography variant='body1' component='p' className='bold'>Contact Information.</Typography> Please contact us at
      {' '}<MailTo email="support@future-grad.com"/> if you have any questions about our Terms of Use.
    </Paragraph>
  </>
