import { FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { pipe } from 'ramda'
import React from 'react'
import { RegistrationStep } from '../common/registration-step.component'
import { withHelpModal } from '../../help/with-help-modal'
import { MissingSchoolHelp } from './missing-school-help.component'
import { postData } from '../../../utils/api-utils'
import { Section } from '../../common/section.component'
import { withDataValidation } from '../common/with-data-validation'

export const MissingSchool = pipe(
  withDataValidation({institution: {}}),
  withHelpModal(MissingSchoolHelp)
)(class extends React.Component {
  state = {institution: ''}

  handleInstitutionChange = (event) => this.setState({institution: event.target.value})

  handleSubmit = () =>
    this.props.validate(this.state) &&
    postData(
      '/api/registration/missing-school',
      this.state,
      () => this.props.openModal("", "Great! We'll let you know when we add your college/university.", "Got it!")
    )

  render() {
    return (
      <RegistrationStep
        title='Coming soon'
        subTitle='Get early access once we expand to your college/university'
        handleNextClick={this.handleSubmit}
        nextLabel='I want early access!'
      >
        <Section>
          <FormControl fullWidth>
            <TextField
              label="Which college do you attend?"
              value={this.state.institution}
              onChange={this.handleInstitutionChange}
              error={Boolean(this.props.errors.institution)}
              helperText={this.props.errors.institution}
            />
          </FormControl>
        </Section>
      </RegistrationStep>
    )
  }
})
