import { routes } from "../routing/routes-paths"

const errorMessages = {
  'BAD_CREDENTIALS': ["Your information doesn't match", "Your username and password don't match. Please try again.", "Try again", undefined],
  'PASSWORD_EXPIRED': ["Your password has expired", "For safety, we ask for a new password every 180 days. Please reset your password.", "Reset password", routes.CUNY_FORGOT_PASSWORD],
  'ACCOUNT_LOCKED': ["Your account is locked", "Your account was locked after too many attempts to login. Please reset your password.", "Reset password", routes.CUNY_FORGOT_PASSWORD],
  'ACCOUNT_NOT_VERIFIED': ["Please verify your account", "You need to verify your account to access FutureGrad. Check your email for the verification link.", "Got it", undefined],
  'ACCOUNT_ALREADY_REGISTERED': ["You've already registered", "You already have an account with us. Just log in.", "Log in", routes.CUNY_LOGIN],
  'ACCOUNT_NOT_FOUND': ["We couldn't find your email", "Looks like we don't have an account with this email. Make sure you are using your CUNY email address. If you have any questions, please reach out to us at support@future-grad.com.", "Got it", undefined],
  'EMAIL_ALREADY_IN_USE': ["Email is already in use", "Your preferred email address is already in use. Please add a different email address.", "Got it", undefined],
  'TERMS_AND_CONDITIONS_NOT_ACCEPTED': ["Accept Terms of Service", "Please agree to the Terms of Service to create your account.", "Got it", undefined],
  'CONFIRMATION_TOKEN_EXPIRED': ["Your verification link has expired", "No worries! Request a new link.", "Request new link", routes.CUNY_RESEND_VERIFICATION],
  'CONFIRMATION_TOKEN_INCORRECT': ["Your verification link is invalid", "No worries! Request a new link.", "Request new link", routes.CUNY_RESEND_VERIFICATION],
  'CONFIRMATION_TOKEN_USED': ["Your verification link has been already used", "You already have a verified account with us. Just log in.", "Log in", routes.CUNY_LOGIN],
  'PASSWORD_RESET_TOKEN_EXPIRED': ["Your password reset link has expired", "No worries! Request a new link.", "Request new link", routes.CUNY_FORGOT_PASSWORD],
  'PASSWORD_RESET_TOKEN_INCORRECT': ["Your password reset link is invalid", "No worries! Request a new link.", "Request new link", routes.CUNY_FORGOT_PASSWORD],
  'PASSWORD_RESET_TOKEN_USED': ["Your password reset link has been already used", "No worries! Request a new link.", "Request new link", routes.CUNY_FORGOT_PASSWORD],
  'PASSWORD_REUSED': ["You have already used this password", "Please choose a new password.", "Got it!", undefined],
  'ACCOUNT_ALREADY_VERIFIED': ["You've already verified", "You already have an verified account with us. Just log in.", "Log in", routes.CUNY_LOGIN],
  'ACCOUNT_NOT_REGISTERED': ["We couldn't find your account", "Looks like you don't have an registered account. Please sign up first.", "Sign Up", routes.CUNY_REGISTRATION]
}

export const getErrorMessage = reason => errorMessages[reason]
  || ["Looks like something went wrong", "We are currently working to fix this. Please check back later.", 'Try again']