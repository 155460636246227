import React from 'react'
import { trackEvent } from '../../utils/google-analytics'
import { isFunction } from 'ramda-adjunct'

export const withTracking = (data) => (WrappedComponent) => {
  return class extends React.Component {
    render() {
      const {category, action, label, value} = isFunction(data) ? data(this.props) : data
      const track = (originalAction) => ({...args}) => {
        trackEvent({category, action, label, value})
        originalAction && originalAction.apply(this, args)
      }
      return <WrappedComponent {...this.props} track={track}/>
    }
  }
}

