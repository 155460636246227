import { FormControl } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { Dropdown } from '../../registration/common/dropdown.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

const OPTIONS = [
  {value: "201609", label: "Fall 2016"},
  {value: "201702", label: "Spring 2017"},
  {value: "201709", label: "Fall 2017"},
  {value: "201802", label: "Spring 2018"},
  {value: "201809", label: "Fall 2018"},
  {value: "201902", label: "Spring 2019"},
  {value: "201909", label: "Fall 2019"},
  {value: "202002", label: "Spring 2020"},
  {value: "202009", label: "Fall 2020"},
  {value: "202102", label: "Spring 2021"},
  {value: "202109", label: "Fall 2021"},
]

export const ExcelsiorFirstReceived = pipe(
  excelsiorRegistrationStep('excelsiorFirstReceived', 'About your school'),
  withExcelsiorRegistrationData
)(({handleChange, data: {excelsiorFirstReceived}, errors: {excelsiorFirstReceived: error}}) =>
  <StepSection
    helpText="As a recipient of the Excelsior Scholarship, you have one full year (365 days) to demonstrate that you are still on track to graduate on time. By knowing when you first received the scholarship, we can help you stay on track to meet the critical requirements."
    header="When did you first receive the Excelsior Scholarship?"
  >
    <FormControl fullWidth>
      <Dropdown
        placeholder="Select semester"
        options={OPTIONS}
        value={excelsiorFirstReceived}
        onChange={event => handleChange({excelsiorFirstReceived: event.target.value})}
        error={error}
      />
    </FormControl>
  </StepSection>
)