import React from "react"
import Checkbox from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Box } from "./custom-box.component"

export const NextSteps = ({classes, data}) => {
  return (
    <Box color="grey">
      {data.nextSteps && data.nextSteps.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            <b>What to do next?</b>
          </Typography>

          {data.nextSteps.map(step => (
            <Grid container key={step}>
              <Grid item><Checkbox className={classes.bullet} /></Grid>
              <Grid item xs><Typography variant="subtitle2" className={classes.noBold}>{step}</Typography></Grid>
            </Grid>
          ))}

          {/*<hr className={classes.divider}/>*/}
        </>
      )}

      <Typography variant="subtitle1" gutterBottom className={classes.marginTop}>
        <i><b>Set goals for this semester</b></i>
      </Typography>

      <Typography variant="subtitle2" className={classes.noBold}>
        Visit <a className={classes.link} href="http://www.future-grad.com/claim-profile">www.future-grad.com/claim-profile</a> to set
        course by course goals for this semester. See how it could impact next semester’s financial aid.
      </Typography>
    </Box>
  )
}