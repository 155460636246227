import { pipe } from 'ramda'
import React from 'react'
import rollbar from '../../rollbar'
import { ErrorPage } from './error-page.component'
import { withUser } from '../../providers/user.provider'
import { withRouter } from "react-router-dom"

export const ErrorBoundary = pipe(
  withUser,
  withRouter
)(class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: null}
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    rollbar.configure({
      payload: {
        person: {
          id: this.props.user.id
        }
      }
    })
    rollbar.error(error)
  }

  render() {
    return this.state.hasError
      ? <ErrorPage />
      : this.props.children
  }
})
