import ReactGA from 'react-ga'
import { googleAnalyticsTrackingId } from '../config'

export const initialize = () => ReactGA.initialize(googleAnalyticsTrackingId)

export const trackPageView = (page) => {
  ReactGA.set({page})
  ReactGA.pageview(page)
}

export const trackEvent = ({category, action, label, value}) => {
  ReactGA.event({
    category, action, label, value
  })
}

export const CATEGORIES = {
  onboarding: 'Onboarding',
  dashboard: 'Dashboard',
  excelsior: 'Excelsior'
}

export const ACTIONS = {
  openHelp: 'open help',
  openRiskHelp: 'open risk help',
  openExternal: 'open external',
}
