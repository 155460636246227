import { without } from 'ramda'
import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { FINANCIAL_AID_TYPES } from './financial-aid-types'
import { Excelsior } from './excelsior.component'
import { Lost } from './lost.component'
import { PrivateRoute } from '../../routing/private-route.component'
import { Receiving } from './receiving.component'

export const FinancialAid = class extends React.Component {
  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          <PrivateRoute
            path="/registration/financial-aid/receiving"
            component={Receiving}
            onSubmit={this.props.onSubmit('receiving')}
            values={this.props.financialAid.receiving}
            options={FINANCIAL_AID_TYPES}
          />
          <PrivateRoute
            path="/registration/financial-aid/lost"
            component={Lost}
            onSubmit={this.props.onSubmit('lost')}
            values={this.props.financialAid.lost}
            options={without([...this.props.financialAid.receiving], FINANCIAL_AID_TYPES)}
          />
          {
            !this.props.financialAid.receiving.includes('EXCELSIOR') && !this.props.financialAid.lost.includes('EXCELSIOR') &&
            <PrivateRoute
              path="/registration/financial-aid/interested-in"
              values={this.props.financialAid.interestedIn}
              component={Excelsior}
              onSubmit={this.props.onSubmit('interestedIn')}
            />
          }
        </Switch>
      </Router>
    )
  }
}