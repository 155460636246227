import { omit, pipe, prop, any, map } from 'ramda'
import * as React from 'react'
import { postData } from '../../utils/api-utils'
import { withApiData } from '../common/with-api-data'

const EligibilityDataContext = React.createContext({})

export const EligibilityDataConsumer = EligibilityDataContext.Consumer

const NONE_WITHDRAWAL_POINT = {value: 'NONE', label: 'NONE'}
export const PAST_WITHDRAWAL_DEADLINE = {value: 'PAST_WITHDRAWAL_DEADLINE', label: 'After December 12'}

export const WITHDRAWAL_POINTS = [
  // {value: 'BEFORE_TAP_CERTIFICATION', label: 'Before October 15'},
  {value: 'BEFORE_CENSUS_DATE', label: 'Before November 6'},
  {value: 'AFTER_CENSUS_DATE', label: 'After November 12'},
  PAST_WITHDRAWAL_DEADLINE
]

export const EligibilityDataProvider = pipe(
  withApiData('/api/goals')
)(class extends React.Component {
  displayName = 'EligibilityDataProvider'

  constructor(props) {
    super(props)

    this.state = {
      goals: props.data.goals.map(course => omit("id", {...course, courseId: course.id})),
      withdrawalPointIdx: this.initWithdrawalPointIdx(props.data.withdrawalPoint),
      financialAid: [],
      academicPrediction: {}
    }
  }

  handleGradeChange = idx => data => this.updateGoal(idx, {...this.state.goals[idx], targetGradeId: data.value, targetGrade: data.label})
  handleDegreeChange = idx => () => this.updateGoal(idx, {...this.state.goals[idx], inMajor: !this.state.goals[idx].inMajor })
  handleWithdrawalPointChange = delta => () => this.setState({
    withdrawalPointIdx: (this.state.withdrawalPointIdx + delta + WITHDRAWAL_POINTS.length) % WITHDRAWAL_POINTS.length
  })

  initWithdrawalPointIdx = withdrawalPoint => withdrawalPoint === NONE_WITHDRAWAL_POINT.value || withdrawalPoint === null
    ? 0
    : WITHDRAWAL_POINTS.findIndex(({value}) => withdrawalPoint === value)

  isWithdrawalPresent = () => pipe(map(prop('targetGrade')), any(grade => grade === 'W'))(this.state.goals)
  getWithdrawalPoint = () => this.isWithdrawalPresent()
    ? WITHDRAWAL_POINTS[this.state.withdrawalPointIdx]
    : NONE_WITHDRAWAL_POINT
  isPastWithdrawalDeadline = () =>
    this.isWithdrawalPresent() && this.getWithdrawalPoint().value === PAST_WITHDRAWAL_DEADLINE.value
  notAllGoalsSet = () => this.state.goals.some(goal => goal.targetGradeId == null)

    updateGoal = (idx, newGoal) => this.setState({
    goals: [
      ...this.state.goals.slice(0, idx),
      newGoal,
      ...this.state.goals.slice(idx + 1)
    ]
  })

  saveGoals = (callback) =>
    postData('/api/goals',
      {
        goals: this.state.goals,
        withdrawalPoint: this.getWithdrawalPoint().value
      },
      callback
    )

  getEligibilityByGoals = (callback) =>
    postData('/api/eligibility',
      {
        goals: this.state.goals.map(({id, targetGradeId}) => ({courseId: id, targetGradeId})),
        withdrawalPoint: this.getWithdrawalPoint().value
      },
      ({data}) => this.setState(({...data}), callback)
    )

  updateInMajor = (callback) =>
    postData(
      '/api/courses/degree',
      {courses: this.state.goals.map(({id, inMajor}) => ({courseId: id, inMajor}))},
      callback
    )

  render() {
    return (
      <EligibilityDataContext.Provider value={{
        goals: this.state.goals,
        financialAid: this.state.financialAid,
        academicPrediction: this.state.academicPrediction,
        getEligibilityByGoals: this.getEligibilityByGoals,
        updateInMajor: this.updateInMajor,
        handleGradeChange: this.handleGradeChange,
        handleDegreeChange: this.handleDegreeChange,
        handleWithdrawalPointChange: this.handleWithdrawalPointChange,
        isWithdrawalPresent: this.isWithdrawalPresent,
        getWithdrawalPoint: this.getWithdrawalPoint,
        isPastWithdrawalDeadline: this.isPastWithdrawalDeadline,
        saveGoals: this.saveGoals,
        notAllGoalsSet: this.notAllGoalsSet
      }}>
        {this.props.children}
      </EligibilityDataContext.Provider>
    )
  }
})

export const withEligibilityData = WrappedComponent => props =>
  <EligibilityDataConsumer>
    {value => <WrappedComponent {...value} {...props} />}
  </EligibilityDataConsumer>