import { find, pipe, prop } from 'ramda'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { UserConsumer } from '../../providers/user.provider'
import { routes } from "./routes-paths"

const homePages = [
  {path: routes.ADMIN_INSTITUTIONS_LIST, guard: ({role}) => role === 'ADMIN'},
  {path: routes.ADMIN_CUNY_STUDENTS, guard: ({role}) => role === 'CUNY_ADMIN' || role === 'CUNY_SUPERADMIN'},
  {path: routes.REGISTRATION, guard: ({registrationStatus, role}) => registrationStatus !== 'finished' && role !== 'STUDENT_EXCELSIOR'},
  // {path: routes.TERM_REVISION, guard: ({incompleteMilestone: {milestone}}) => milestone === 'ACADEMIC_RECORD_CONFIRMATION'},
  // {path: routes.TERM_ENROLLMENT, guard: ({incompleteMilestone: {milestone}}) => milestone === 'TERM_ENROLLMENT'},
  {path: routes.DASHBOARD, guard: ({role}) => role === 'STUDENT'},
  {path: routes.EXCELSIOR_ELIGIBILITY, guard: ({role}) => role === 'STUDENT_EXCELSIOR'}
]

const getHomePage = (userInfo) =>
  pipe(
    find(({guard}) => guard(userInfo)),
    prop('path')
  )(homePages)

export const Home = () =>
  <UserConsumer>
    {user => <Redirect to={getHomePage(user)}/>}
  </UserConsumer>