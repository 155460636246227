import { createMuiTheme } from '@material-ui/core'

export const defaultTheme = createMuiTheme({
  shadows: Array(25).fill('none'),
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff'
    },
    'background': {
      'paper': '#fff',
      'grey': '#f5f5f5',
      'dark': '#daddd8',
      'default': '#fff',
      'yellow': '#fab131',
      'torquoise': '#7cb8b6'
    },
    'primary': {
      'light': '#23467c',
      'main': '#152c4f',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': '#f59c8b',
      'main': '#ed5a3f',
      'contrastText': '#fff'
    },
    'highlight': {
      'main': 'rgba(251, 119, 80, 1)'
    },
    'error': {
      'light': '#e57373',
      'main': '#f44336',
      'dark': '#d32f2f',
      'contrastText': '#fff'
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '\'Source Sans Pro\', sans-serif',
    fontSize: 16,
    subtitle1: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    body1: {
      fontSize: '18px',
    },
    body2: {
      fontSize: '16px'
    }
  }
})
