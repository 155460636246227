import { Input } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import { RangeRule } from './range-rule.component'
import { getLabel } from '../rules'
import { Dropdown } from '../../registration/common/dropdown.component'
import { RuleStep } from './rule-step.component'
import { FLAT } from '../rules'

export const RuleEditor = ({title, stepNumber, placeholder, requirement, onChange, valueTypes, ruleTypes, valueTypeComponent}) => {
  const handleTypeChange = event => {
    const type = event.target.value
    const value = type === FLAT ? undefined : [{from: '', to: '', value: ''}]
    onChange({...requirement, type, value})
  }
  const handleValueTypeChange = event => onChange({...requirement, valueType: event.target.value})
  const handleValueChange = value => onChange({...requirement, value})

  const valueTypeSelector = () =>
    <Dropdown value={requirement.valueType} onChange={handleValueTypeChange} options={valueTypes}/>

  return (
    <RuleStep stepNumber={stepNumber} title={title}>
      <Dropdown onChange={handleTypeChange} value={requirement.type} options={ruleTypes}/>
      {
        requirement.type !== undefined && (requirement.type === FLAT
          ? <FormGroup row>
            <Input
              placeholder={placeholder}
              onChange={(event) => handleValueChange(event.target.value)}
              value={requirement.value || ''}
            />
            {valueTypes && valueTypeSelector()}
          </FormGroup>
          : <RangeRule
            valueTypeComponent={valueTypeComponent || (valueTypes && valueTypeSelector())}
            label={getLabel(ruleTypes, requirement.type)}
            onChange={handleValueChange}
            values={requirement.value}
          />)
      }
    </RuleStep>
  )
}
