import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Box } from "./custom-box.component"

export const Alert = ({classes}) => (
  <Box color="secondary" marginBottom>
    <Grid container alignItems="center">
      <Grid item>
        <Typography variant="subtitle1" color="inherit" className={classes.marginRight}><b>COVID-19</b></Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle2" color="inherit" className={classes.noBold}>
          Snapshot does not reflect any financial aid award adjustment due to COVID-19. Email <a className={classes.link} href="mailto:support@future-grad.com">support@future-grad.com</a> [Subject: COVID-19 Appeal] with appeal details.
        </Typography>
      </Grid>
    </Grid>
  </Box>
)