import { withStyles } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { pipe } from 'ramda'
import React from 'react'
import { HelpLinkSmall } from '../../help/help-link-small.component'

const styles = theme => ({
  input: {
    width: theme.spacing.unit * 17,
    padding: theme.spacing.unit
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
  },
  helperText: {
    marginTop: 0,
    marginLeft: theme.spacing.unit
  }
})

export const Input = pipe(
  withStyles(styles)
)(({classes, value, onChange, error, adornment, placeholder, errorHelpText, openErrorHelpModal}) => {
  const displayError = Boolean(error)
  const textFieldProps = {
    value: value || (value === 0 ? 0 : ''),
    onChange: onChange,
    error: displayError,
    type: 'number',
    required: true,
    inputProps: {className: classes.input},
    labelWidth: 0,
    startAdornment: adornment && <InputAdornment position="start">{adornment}</InputAdornment>,
    placeholder: placeholder
  }

  return (
    <div className={classes.row}>
      <OutlinedInput {...textFieldProps}/>
      <div className={classes.helperText}>
        {displayError && <FormHelperText error>{error}</FormHelperText>}
        {errorHelpText && <HelpLinkSmall text={errorHelpText} onClick={openErrorHelpModal}/>}
      </div>
    </div>
  )
})