import { withStyles } from '@material-ui/core'
import MobileStepper from '@material-ui/core/MobileStepper'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { pipe } from 'ramda'
import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import checklistIllustration from '../../assets/images/checklist_illustration.png'
import { Image } from '../common/image.component'
import { TipBox } from '../common/tip-box.component'
import IconButton from "@material-ui/core/IconButton"

const styles = theme => ({
  carousel: {
    margin: '0 -20px',
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '960px'
  },
  stepper: {
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main
  },
  dot: {
    backgroundColor: 'rgba(256,256,256, 0.5)'
  },
  dotActive: {
    backgroundColor: theme.palette.common.white
  },
  arrow: {
    display: 'block',
    color: theme.palette.common.white,
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  disabled: {
    visibility: 'hidden'
  }
})

export const Reminders = pipe(
  withStyles(styles)
)(class extends React.Component {
  state = {step: 0}

  handleStepChange = step => this.setState({step})

  handleBack = () => this.setState(({step}) => ({step: step > 0 ? step - 1 : 0}))

  handleNext = () => this.setState(({step}, {reminders}) => ({step: step < reminders.length - 1 ? step + 1 : step }))


  render() {
    const {reminders, classes} = this.props
    const {step} = this.state

    return (
      <div data-tour="reminders" className={classes.carousel}>
        <SwipeableViews
          axis='x'
          index={step}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
        >
          {reminders.map((reminderCopy, idx) =>
            <TipBox
              key={idx}
              image={<Image src={checklistIllustration}/>}
              secondary
              title="REMINDER"
              carousel
            >
              {reminderCopy}
            </TipBox>
          )}
        </SwipeableViews>

        {reminders.length > 1 &&
          <MobileStepper
            variant="dots"
            steps={reminders.length}
            position="static"
            activeStep={step}
            className={classes.stepper}
            classes={{dotActive: classes.dotActive, dot: classes.dot}}
            nextButton={
              <IconButton className={classes.arrow} classes={{disabled: classes.disabled}} onClick={this.handleNext} disabled={step === reminders.length - 1}>
                <KeyboardArrowRight />
              </IconButton>
            }
            backButton={
              <IconButton className={classes.arrow} classes={{disabled: classes.disabled}} onClick={this.handleBack} disabled={step === 0}>
                <KeyboardArrowLeft />
              </IconButton>}
          />
        }
      </div>
    )
  }
})
