import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'
import { pipe, prop, map, sum, filter } from 'ramda'
import React from 'react'
import graduationCapIcon from '../../assets/images/graduation_cap_icon.svg'
import targetIllustration from '../../assets/images/checked_target_illustration.png'
import { Image } from '../common/image.component'
import { TipBox} from '../common/tip-box.component'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import { withEligibilityData } from '../eligibility/eligibility-data.provider'
import { withHelpModal } from '../help/with-help-modal'
import { Section } from '../common/section.component'
import { MinimumEnrollmentAlert } from './minimum-enrollment-alert.component'
import {Button} from "../common/button.component"
import {ActionSection} from "../common/action-section.component"
import { routes } from "../routing/routes-paths"
import { TextLink } from "../common/text-link.component"

const styles = theme => ({
  marginBottom: {
    marginBottom: theme.spacing.unit * 2
  },
  graduationIcon: {
    width: theme.spacing.unit * 3
  }
})

const MINIMUM_ENROLLMENT_REQUIREMENT = 12

export const CoursesDegreePicker = pipe(
  withStyles(styles),
  withEligibilityData,
  withHelpModal(MinimumEnrollmentAlert)
)(class extends React.Component {
  displayName = 'CoursesDegreePicker'

  state = {gotIt: false}

  componentDidMount() {
    if (this.props.goals.length === 0) this.props.history.push(routes.COURSE_INFO)
  }

  sumMajorCredits = (courses) => pipe(
    filter(prop('inMajor')),
    this.sumCoursesCredits
  )(courses)

  sumCoursesCredits = (courses) => pipe(
    map(prop('credits')),
    sum
  )(courses)

  minimumEnrollmentRequirementMet = () => this.sumMajorCredits(this.props.goals) >= MINIMUM_ENROLLMENT_REQUIREMENT

  handleNextClick = () => this.minimumEnrollmentRequirementMet() || this.state.gotIt
    ? this.props.updateInMajor(() => this.props.history.push(routes.COURSES_GRADE_PICKER))
    : this.setState({gotIt: true}, this.props.openModal)

  render() {
    const {classes} = this.props;
    return (
    <Section fullHeight noMarginBottom>
        <Typography variant="h4">First tell us which courses count towards your degree?</Typography>
        <TipBox image={<Image src={targetIllustration} />} title="Did you know" primary>
          Your courseload must include at least 12 credits in your major to qualify for financial aid.
        </TipBox>
        <SubSection>
          <Typography variant="body2" className={classnames('bold', classes.marginBottom)}>
            Select the classes that counts towards your degree
          </Typography>
          <Row>
            <TableCell heading>CLASSES FOR FALL 2021</TableCell>
            <TableCell heading center>CREDITS</TableCell>
            <TableCell heading center>DEGREE?</TableCell>
          </Row>
          {this.props.goals.map(({name, credits, inMajor}, idx) =>
            <Row key={idx} withBorder onClick={this.props.handleDegreeChange(idx)}>
              <TableCell>{name}</TableCell>
              <TableCell center>{credits}</TableCell>
              <TableCell center>{inMajor && <img src={graduationCapIcon} alt="In major" className={classes.graduationIcon} />}</TableCell>
            </Row>
          )}
        </SubSection>
        <SubSection secondary marginBottom>
          <Row>
            <TableCell bold>Credits towards major</TableCell>
            <TableCell
              center
              error={!this.minimumEnrollmentRequirementMet()}
            >
              {this.sumMajorCredits(this.props.goals)}
            </TableCell>
            <TableCell/>
          </Row>
          <Row>
            <TableCell bold>EnrolledCredits</TableCell>
            <TableCell center>{this.sumCoursesCredits(this.props.goals)}</TableCell>
            <TableCell/>
          </Row>
        </SubSection>
        <ActionSection>
          <Button text="Next" onClick={this.handleNextClick} disabled={this.sumMajorCredits((this.props.goals)) === 0}/>
          <TextLink label="Edit courses" to={routes.COURSE_INFO} />
        </ActionSection>
      </Section>
    )
  }
})