import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'
import { SwitchButton } from './switch-button.component'

export const YesNoButtons = ({className, value, handleYesClick, handleNoClick, yesLabel, noLabel}) =>
  <div className={className}>
    <FormGroup row>
      <SwitchButton noBorderRadius active={value === false} label={noLabel || "No"} onClick={handleNoClick}/>
      <SwitchButton noBorderRadius active={value === true} label={yesLabel || "Yes"} onClick={handleYesClick}/>
    </FormGroup>
  </div>
