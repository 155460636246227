import { pipe } from 'ramda'
import React from 'react'
import { withApiData } from '../common/with-api-data'
import { CourseList } from './course-list.component'
import { withUser } from "../../providers/user.provider"
import { Header } from "../common/header.component"
import { Section } from "../common/section.component"

export const CourseInfo = pipe(
  withApiData('/api/courses'),
  withUser
)(class extends React.Component {
  componentDidMount() {
    this.props.user.refreshUserData()
  }

  refreshCourseData = () => {
    this.props.refreshData()
    this.props.user.refreshUserData()
  }

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <Header
          title="Add your classes"
          subtitle="Enter your the courses for current term as a first step before using eligibility calculator"
        />
        <CourseList
          courses={this.props.data}
          refreshCourseData={this.refreshCourseData}
          term={this.props.user.currentTerm}
          hasGoals={this.props.user.hasGoals}
        />
      </Section>
    )
  }
})