import React, { useState } from 'react'
import { pipe } from 'ramda'
import { GoogleLogin } from "react-google-login"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { UserConsumer, withUser } from '../../providers/user.provider'
import { googleClientId } from "../../config"
import { Button } from "../common/button.component"
import Grid from "@material-ui/core/Grid"
import { TextInput } from "../common/text-input.component"
import { routes } from "../routing/routes-paths"
import { withRouter } from "react-router-dom"

const styles = theme => ({
  body: {
    padding: `${theme.spacing.unit * 2}px 0`
  }
})

export const ClaimProfile = pipe(
  withUser,
  withRouter,
  withStyles(styles)
)(({user: {loaded, authenticated}, history, classes}) => {
  const [studentId, setStudentId] = useState('');
  const [program, setProgram] = useState('');
  const [error, setError] = useState(undefined);

  const success = (response, callback) => {
    const email = response && response.profileObj && response.profileObj.email
    const idToken = response && response.tokenId

    callback(email, idToken, studentId, program, failure)
  }

  const failure = (response) => {
    const reason =  response && response.data && response.data.reason
    setError(reason || "Something went wrong...")
  }

  const GoogleSignIn = props => (
    <UserConsumer>
      {({ googleLoginClaimProfile }) =>
        <GoogleLogin
          clientId={googleClientId}
          render={renderProps => <Button
            disabled={!studentId || !program}
            text="Sign Up with Google Account"
            variant="contained"
            onClick={() => { setError(undefined); renderProps.onClick(); }}
          />}
          onSuccess={response => success(response, googleLoginClaimProfile)}
          onFailure={failure}
        />
      }
    </UserConsumer>
  )

  return (
    loaded && (
      <div>
        <Typography variant="h3" className="bold">
          Claim your profile and join FutureGrad, your financial aid helper
        </Typography>
        <Typography variant="body1" gutterBottom>
          Financial aid can be so confusing, but it doesn't have to be. FutureGrad helps you keep track of all the
          requirements and deadlines so you don't lose your aid. And it's free!
        </Typography>

        <div className={classes.body}>
          <Grid container justify="center">
            <Grid item xs={12} sm={7}>
              <Grid container justify='center'>
                <TextInput
                  onChange={event => setStudentId(event.target.value)}
                  label='Student ID'
                  value={studentId}
                  fullWidth
                  noMarginBottom
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container justify='center'>
                <TextInput
                  onChange={event => setProgram(event.target.value)}
                  label='Program name'
                  value={program}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container justify='center'>
                {error && <Typography>{error}</Typography>}
              </Grid>
              <Grid container justify='center'>
                {!authenticated && <GoogleSignIn/>}
                {authenticated && history.push(routes.ROOT)}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  )
})