import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import SearchIcon from '@material-ui/icons/Search'
import withStyles from '@material-ui/core/styles/withStyles'
import { ascend, descend, pipe, prop, sort } from 'ramda'
import React from 'react'
import { withStudentDialog } from './with-student-dialog'
import { withApiData } from '../common/with-api-data'
import { CustomTableCell } from '../course-info/custom-table-cell.component'
import { fetchCsv, fetchData } from '../../utils/api-utils'
import { Grid } from "@material-ui/core"

const styles = theme => ({
  download: {
    marginBottom: theme.spacing.unit * 3
  }
});

export const StudentsList = pipe(
  withApiData('/api/admin/students_details/is_csv_ready', {}, 'csvGenerationDate'),
  withApiData('/api/admin/students_details', {}, 'students'),
  withStudentDialog,
  withStyles(styles)
)(class extends React.Component {
  state = {orderBy: 'userId', orderDirection: 'desc'}

  sortByFn = () => (this.state.orderDirection === 'asc' ? ascend : descend)(prop(this.state.orderBy))

  createSortHandler = field => () => field === this.state.orderBy
    ? this.setState({orderDirection: this.state.orderDirection === 'asc' ? 'desc' : 'asc'})
    : this.setState({orderBy: field, orderDirection: 'asc'})

  renderHeader = (field, label) =>
    <CustomTableCell>
      <TableSortLabel
        active={this.state.orderBy === field}
        direction={this.state.orderDirection === 'asc' ? 'desc' : 'asc'}
        onClick={this.createSortHandler(field)}
      >
        {label}
      </TableSortLabel>
    </CustomTableCell>

  handleRequestCsv = () => {
    fetchData('/api/admin/students_details/request_csv',
      response => {  console.log(response) },
      error => { console.log(error) }
    )
  }

  handleDownloadCsv = () => {
    fetchCsv('/api/admin/students_details/download_csv',
      blob => {
        const url = window.URL.createObjectURL(new Blob([blob], { type: "text/csv" }))
        window.open(url);
      },
      error => { console.log(error) }
    )
  }

  render() {
    const {classes, students, openDialog, csvGenerationDate} = this.props

    return (
      <div>
        <Grid container spacing={8}>
          <Grid item>
            <Button
              // fullWidth
              variant="contained"
              color="primary"
              className={classes.download}
              onClick={this.handleRequestCsv}
            >
              Request new CSV
            </Button>
          </Grid>
          <Grid item>
            <Button
              // fullWidth
              disabled={!csvGenerationDate || csvGenerationDate === ""}
              variant="contained"
              color="primary"
              className={classes.download}
              onClick={this.handleDownloadCsv}
            >
              Download CSV {csvGenerationDate ? `(${csvGenerationDate})` : ""}
            </Button>
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              {this.renderHeader("userId", "User ID")}
              {this.renderHeader("firstName", "Name")}
              {this.renderHeader("email", "Email")}
              {this.renderHeader("registrationStep", "Registration Step")}
              {this.renderHeader("institutionName", "Institution")}
              {this.renderHeader("counselingOrganizations", "Counseling Organization")}
              {this.renderHeader("createDateTime", "Created At")}
              <CustomTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {sort(this.sortByFn(), students).map(studentData =>
              <StudentItem
                key={studentData.userId}
                {...studentData}
                openDialog={openDialog(studentData)}
              />
            )}
          </TableBody>
        </Table>
      </div>
    )
  }
})

const StudentItem = ({openDialog, userId, firstName, lastName, email, registrationStep, institutionName, createDateTime, counselingOrganizations}) =>
  <TableRow>
    <CustomTableCell>{userId}</CustomTableCell>
    <CustomTableCell>{`${firstName} ${lastName}`}</CustomTableCell>
    <CustomTableCell>{email}</CustomTableCell>
    <CustomTableCell>{registrationStep}</CustomTableCell>
    <CustomTableCell>{institutionName}</CustomTableCell>
    <CustomTableCell>{counselingOrganizations.join(', ')}</CustomTableCell>
    <CustomTableCell>{createDateTime && createDateTime.slice(0, 10)}</CustomTableCell>
    <CustomTableCell onClick={openDialog}>
      <SearchIcon/>
    </CustomTableCell>
  </TableRow>