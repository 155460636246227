import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import React from 'react'
import { withDataValidation } from '../common/with-data-validation'
import { CheckboxWithLabel } from '../common/checkbox.component'
import { Section } from '../../common/section.component'
import { RegistrationStep } from '../common/registration-step.component'
import { ORGANIZATIONS } from './organizations'
import { includes, without, append, pipe } from 'ramda'

export const CounselingOrganization = pipe(
  withDataValidation({
    consent: {required: false, guard: ({consent, names}) => names.length === 0 || consent, msg: 'Consent Required'}
  })
)(class extends React.Component {
  constructor(props) {
    super(props)
    const { names, consent } = this.props.counselingOrganizations
    this.state = {names, consent: consent || false}
  }

  handleOrganizationChange = name => () => this.setState(state => {
    const names = includes(name, this.state.names) ? without(name, this.state.names) : append(name, this.state.names)
    return {names, consent: state.consent && names.length > 0}
  })
  handleConsentChange = event => this.setState({consent: !this.state.consent})

  handleNextClick = () => this.props.validate(this.state) && this.props.handleCounselingOrganization(this.state, true)
  handleBackClick = () => this.props.handleCounselingOrganization(this.state, false)

  render() {
    return (
      <RegistrationStep
        stepTitle="Program (2/5)"
        title="Are you affiliated with any of the following  college counseling organizations"
        handleNextClick={this.handleNextClick}
        handleBackClick={this.handleBackClick}
      >
        <Section header="Select all that apply">
          <FormGroup>
            {ORGANIZATIONS.map(name =>
              <CheckboxWithLabel
                key={name}
                label={name}
                checked={includes(name, this.state.names)}
                onChange={this.handleOrganizationChange(name)}
              />
            )}
          </FormGroup>
        </Section>

        {
          this.state.names.length > 0 &&
          <FormGroup row>
            {Boolean(this.props.errors.consent) && <FormHelperText error>{this.props.errors.consent}</FormHelperText>}
            <CheckboxWithLabel
              checked={this.state.consent}
              onChange={this.handleConsentChange}
              label="I agree that FutureGrad may share my information with my organization"
              error={Boolean(this.props.errors.consent)}
            />
          </FormGroup>
        }
      </RegistrationStep>
    )
  }
})
