import * as qs from 'query-string'
import { pipe } from 'ramda'
import React from 'react'
import { withUser } from '../../providers/user.provider'
import { postDataWithHandler } from '../../utils/api-utils'
import { TextInput } from '../common/text-input.component'
import { withHelpModal } from '../help/with-help-modal'
import { withDataValidation } from '../registration/common/with-data-validation'
import { Header } from '../common/header.component'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import {ActionSection} from "../common/action-section.component"
import {Button} from "../common/button.component"
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"
import { Section } from "../common/section.component"

export const CunyResetPassword = pipe(
  withDataValidation({
    password: {
      guard: ({password}) => password.length >= 8,
      msg: "Password is shorter than 8 chars"
    },
    passwordConfirmation: {
      guard: ({password, passwordConfirmation}) => password === passwordConfirmation,
      msg: "Password and Password Confirmation don't match."
    }
  }),
  withUser,
  withHelpModal(SimpleHelpModal)
)(class extends React.Component {
  displayName = 'CunyResetPassword'

  state = {password: '', passwordConfirmation: ''}

  handleInputChange = key => event => this.setState({[key]: event.target.value})

  handleResetPasswordClick = () => this.props.validate(this.state) && postDataWithHandler(
    '/api/user/reset-password',
    {token: qs.parse(this.props.location.search).token, password: this.state.password},
    (status, {data}) => {
      switch (status) {
        case 200: {
          this.props.openModal("Your password has been successfully reset!", "Please log in.", "Log in", routes.CUNY_LOGIN)
          break
        }
        default: {
          this.props.openModal(...getErrorMessage(data.reason))
          break
        }
      }
    }
  )

  propsForTextInput = key => ({
    value: this.state[key],
    onChange: this.handleInputChange(key),
    error: this.props.errors[key]
  })

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <Header
          title="Reset your password"
          subtitle="Set your new password"
        />

        <TextInput  label="Password" type="password" {...this.propsForTextInput('password')} noMarginBottom />
        <TextInput  label="Password Confirmation" type="password" {...this.propsForTextInput('passwordConfirmation')}/>

        <ActionSection>
          <Button text="Reset password" onClick={this.handleResetPasswordClick} />
        </ActionSection>
      </Section>
    )
  }
})