import { Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { pipe } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'

const styles = theme => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    marginRight: theme.spacing.unit * 2
  }
})

export const ActionButtons = pipe(
  withStyles(styles)
)(({classes, addDisabled, onAdd, deleteDisabled, onDelete, saveDisabled, onSave, institutionId}) =>
  <div className={classes.actionButtons}>
    <Button onClick={onAdd} className={classes.button} variant="outlined" disabled={addDisabled}>
      Add
    </Button>
    <Button onClick={onDelete} className={classes.button} variant="outlined" disabled={deleteDisabled}>
      Delete
    </Button>
    <Button onClick={onSave} className={classes.button} variant="contained" color="primary" disabled={saveDisabled}>
      Save
    </Button>
    <Link to={`/admin/rules/${institutionId}`} style={{ textDecoration: 'none' }}>
      <Button variant="contained">
        Cancel
      </Button>
    </Link>
  </div>
)