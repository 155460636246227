import { pipe } from 'ramda'
import React from 'react'
import { LostAidHelp } from '../help/lost-aid-help.component'
import { Section } from '../../common/section.component'
import { withAidSelection } from './with-aid-selection'
import { AidSelector } from './aid-selector.component'
import { RegistrationStep } from '../common/registration-step.component'

export const Lost = pipe(
  withAidSelection,
)(
  ({handleNextClick, handleBackClick, options, financialAid, handleAidSelection}) =>
    <RegistrationStep
      stepTitle="Financial Aid Type (3/5)"
      title="Have you stopped receiving any of the financial aid listed below?"
      handleNextClick={handleNextClick}
      handleBackClick={handleBackClick}
    >
      <Section
        header="Select all that apply."
        helpText="Why did I lose my financial aid?"
        helpModal={LostAidHelp}
        trackingLabel="Lost aid help"
      >
        <AidSelector
          options={options}
          financialAid={financialAid}
          handleAidSelection={handleAidSelection}
        />
      </Section>
    </RegistrationStep>
)