import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { YesNoButtons } from '../../registration/common/yes-no-buttons.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

export const OpportunityProgram = pipe(
  excelsiorRegistrationStep('inOpportunityProgram', 'About your school'),
  withExcelsiorRegistrationData
)(({handleChange, data: {inOpportunityProgram}}) =>
  <StepSection
    helpText="Students enrolled in SEEK, College Discovery, EOP or HEOP get 1-2 semesters of additional Excelsior Scholarship funding."
    header="Are you in SEEK, College Discovery, EOP or HEOP?"
  >
    <YesNoButtons
      value={inOpportunityProgram}
      handleYesClick={() => handleChange({inOpportunityProgram: true})}
      handleNoClick={() => handleChange({inOpportunityProgram: false})}
    />
  </StepSection>
)