import { FormControl } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { DEGREE_TYPE_OPTIONS } from '../../registration/basics/basics-options'
import { Dropdown } from '../../registration/common/dropdown.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

export const DegreeType = pipe(
  excelsiorRegistrationStep('degreeType', 'About your school'),
  withExcelsiorRegistrationData
)(({handleChange, data: {degreeType}, errors: {degreeType: error}}) =>
  <StepSection
    helpText="For most students, eligibility for Excelsior is dependent on being on track to graduate from an Associate’s program in 2 years and a Bachelor’s program in 4 years."
    header="Tell us which degree program you’re enrolled in"
  >
    <FormControl fullWidth>
      <Dropdown
        placeholder="Select your degree program"
        options={DEGREE_TYPE_OPTIONS}
        value={degreeType}
        onChange={event => handleChange({degreeType: event.target.value})}
        error={error}
      />
    </FormControl>
  </StepSection>
)