import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import classnames from 'classnames'
import React from 'react'

const styles = theme => ({
  button: {
    width: `${theme.spacing.unit * 17}px`,
    textTransform: 'none'
  },
  contained: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  noBorderRadius: {
    borderRadius: 0
  },
  error: {
    borderColor: theme.palette.error.main
  }
})

export const SwitchButton = withStyles(styles)(({classes, active, label, onClick, noBorderRadius, error}) =>
  <Button
    className={classnames({
      [classes.button]: true,
      [classes.contained]: active,
      [classes.noBorderRadius]: noBorderRadius,
      [classes.error]: error
    })}
    variant={active ? 'contained' : 'outlined'}
    color={active ? 'secondary' : "default"}
    onClick={onClick}
  >
    {label}
  </Button>
)
