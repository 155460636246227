import React from "react"
import Link from "react-router-dom/Link"
import Grid from "@material-ui/core/Grid"
import { Box } from "./custom-box.component"
import Typography from "@material-ui/core/Typography"
import { getProgramIdFromName } from "./utils"

export const StudentList = ({ programName, students }) => {
  return (
    <Box color="grey">
      <Grid container alignItems="center" spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h4" color="inherit"><b>{programName}</b></Typography>
        </Grid>
        {students.sort().map(studentId => (
          <Grid item xs={12} key={studentId}>
            <Link to={`/cbo/eligibility/${getProgramIdFromName(programName)}/${studentId}`}>https://future-grad.com/cbo/eligibility/{getProgramIdFromName(programName)}/{studentId}</Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}