import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { Input } from '../../registration/common/input.component'

export const EnrolledCredits = pipe(
  excelsiorRegistrationStep('enrolledCredits', 'About your academic record'),
  withExcelsiorRegistrationData
)(({handleChange, data: {enrolledCredits}, errors: {enrolledCredits: error}}) =>
  <StepSection
    helpText="For most students to renew the Excelsior Scholarship, you need to be on track to graduate in your major within 2 years for an Associate’s program and 4 years for a Bachelor’s program. One of the factors they evaluate is your enrolling in at least 12 credits each semester in your degree program. So if you are an Economics major, you need to enroll in at least 12 credits that fulfill the degree or elective requirements for a bachelor’s degree in Economics."
    header="How many credits are you enrolled in this semester toward your major?"
  >
    <Input
      onChange={event => handleChange({enrolledCredits: event.target.value})}
      placeholder='Ex 12'
      value={enrolledCredits}
      error={error}
    />
  </StepSection>
)
