import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import * as classnames from 'classnames'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  tableCell: {
    padding: theme.spacing.unit * 0.5,
    border: 0,
    '&:last-child': {
      padding: theme.spacing.unit * 0.5
    }
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  }
})

export const CustomTableCell = pipe(
  withStyles(styles)
)(({classes, onClick, children, align, ...props}) =>
  <TableCell
    onClick={onClick}
    align={align}
    className={classnames({
      [classes.alignCenter]: align === 'center',
      [classes.alignRight]: align === 'right',
    })}
    classes={{root: classes.tableCell,}}
    {...props}
  >
    {children}
  </TableCell>
)