import { FormControl } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { Dropdown } from '../../registration/common/dropdown.component'
import { Section } from '../../common/section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

const OPTIONS = [
  {value: "201609", label: "Fall 2016"},
  {value: "201702", label: "Spring 2017"},
  {value: "201709", label: "Fall 2017"},
  {value: "201802", label: "Spring 2018"},
  {value: "201809", label: "Fall 2018"},
  {value: "201902", label: "Spring 2019"},
  {value: "201909", label: "Fall 2019"},
  {value: "202002", label: "Spring 2020"},
  {value: "202009", label: "Fall 2020"},
  {value: "202102", label: "Spring 2021"},
  {value: "202109", label: "Fall 2021"},
]

export const FirstEnrolled = pipe(
  excelsiorRegistrationStep('firstEnrolled', 'About your school'),
  withExcelsiorRegistrationData
)(({handleChange, data: {firstEnrolled}, errors: {firstEnrolled: error}}) =>
  <Section header="When did you first enroll in college?">
    <FormControl fullWidth>
      <Dropdown
        placeholder="Select a semester"
        options={OPTIONS}
        value={firstEnrolled}
        onChange={event => handleChange({firstEnrolled: event.target.value})}
        error={error}
      />
    </FormControl>
  </Section>
)