import { pipe } from 'ramda'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { withUser } from '../../providers/user.provider'
import { routes } from "./routes-paths"

export const ExcelsiorRoute = pipe(
  withUser
)(({component: Component, user: {loaded, authenticated, role}, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      loaded
        ? authenticated
          ? role === 'STUDENT_EXCELSIOR'
            ? <Component {...props} {...rest} />
            : <Redirect to={{pathname: routes.HOMEPAGE, state: {from: props.location}}}/>
          : <Component {...props} {...rest} />
        : null
    }
  />
)
