import React from 'react'
import { any, pipe } from 'ramda'
import {sortedRequirementsForAidType} from '../common/requirements-utils'
import {aidNameForType} from '../registration/financial-aid/financial-aid-types'
import { withEligibilityData } from './eligibility-data.provider'
import { routes } from "../routing/routes-paths"
import { AidDetailsPage } from "./aid-details-page.component"

export const AidEligibilityDetails = pipe(
  withEligibilityData
)(class extends React.Component {
  componentDidMount() {
    if (any(({targetGradeId}) => targetGradeId === null, this.props.goals))
      this.props.history.push(routes.COURSES_GRADE_PICKER)
    else if (this.props.financialAid.length === 0)
      this.props.getEligibilityByGoals()
  }

  componentDidUpdate() {
    if (this.props.financialAid.length === 0) this.props.history.push(routes.ELIGIBILITY_START)
  }

  render() {
    if (this.props.financialAid.length === 0) return null
    const {financialAid, match: {params: {type}}} = this.props

    return (
      <AidDetailsPage
        aidName={aidNameForType(type)}
        requirements={sortedRequirementsForAidType(type, financialAid)}
      />
    )
  }
})