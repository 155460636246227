import { pipe } from 'ramda'
import React from 'react'
import { SisLink } from '../common/sis-link.component'
import { Section } from '../../common/section.component'
import { withAidSelection } from './with-aid-selection'
import { AidSelector } from './aid-selector.component'
import { RegistrationStep } from '../common/registration-step.component'

export const Receiving = pipe(
  withAidSelection,
)(
  ({handleNextClick, handleBackClick, options, financialAid, handleAidSelection}) =>
    <RegistrationStep
      stepTitle="Financial Aid Type (3/5)"
      title="What financial aid do you currently receive?"
      subTitle={<>You can find this in <SisLink/></>}
      handleNextClick={handleNextClick}
      handleBackClick={handleBackClick}
    >
      <Section header="Select each one that applies">
        <AidSelector
          options={options}
          financialAid={financialAid}
          handleAidSelection={handleAidSelection}
        />
      </Section>
   </RegistrationStep>
)