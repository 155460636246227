import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export class AlertDialog extends React.Component {
  state = {open: false}
  open = () => this.setState({ open: true })
  close = (callback) => this.setState({ open: false }, () => setTimeout(callback, 100))

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.close()}
      >
        <DialogTitle>
          <Typography className="bold" variant="body1">
            {this.props.confirmation ? 'Are you sure?' : this.props.title}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => this.close()} size="small" variant="outlined" color="secondary">
            {this.props.confirmation ? 'No' : 'Cancel'}
          </Button>
          <Button onClick={() => this.close(this.props.onConfirm)} size="small" variant="contained" color="secondary">
            {this.props.confirmation ? 'Yes' : 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}