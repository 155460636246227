import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { filter, pipe } from 'ramda'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core'
import closeButton from '../../assets/images/close_button.svg'
import { withUser } from '../../providers/user.provider'
import { routes } from "../routing/routes-paths"
import IconButton from "@material-ui/core/IconButton"

const highlightedLink = theme => ({
  borderBottom: '3px solid #d0021b',
  marginBottom: theme.spacing.unit * 2 - 3,
})

const styles = theme => ({
  plainButton: {
    textTransform: 'none',
    padding: 0,
    minWidth: 0
  },
  dialogRoot: {
    zIndex: 2147483647
  },
  dialogPaper: {
    padding: '20px 20px 20px 40px',
    backgroundColor: theme.palette.background.yellow,
  },
  closeSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeImage: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
  },
  linkGroup: {
    marginBottom: theme.spacing.unit * 9
  },
  linkItem: {
    textDecoration: 'none',
    width: 'fit-content',
    marginBottom: theme.spacing.unit * 2,
    display: 'block',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': highlightedLink(theme)
  },
  linkText: {
    fontSize: 20,
  },
  activeLink: highlightedLink(theme)
})

const hasRole = (user, role) => user.role === role

const isCunySuperadmin = user => hasRole(user, 'CUNY_SUPERADMIN')
const isCunyAdmin = user => hasRole(user, 'CUNY_ADMIN')
const isCunyAdminOrSuperadmin = user =>  isCunyAdmin(user) || isCunySuperadmin(user)
const isAdmin = user => hasRole(user, 'ADMIN')

const isStudent = user => hasRole(user, 'STUDENT')

const menuLinks = [
  {path: routes.ADMIN_CUNY_STUDENTS, label: 'Students', guard: isCunyAdminOrSuperadmin},
  {path: routes.ADMIN_CUNY_ADMINS, label: 'Administrators', guard: isCunySuperadmin},
  {path: routes.ADMIN_INSTITUTIONS_LIST, label: 'Institutions', guard: isAdmin},
  {path: routes.ADMIN_STUDENTS_LIST, label: 'Students', guard: isAdmin},
  {path: routes.ADMIN_STUDENTS_ALL_SNAPSHOTS_LIST, label: "Students' snapshots", guard: isAdmin},
  {path: routes.ADMIN_SMS, label: 'Send SMS', guard: isAdmin},
  {path: routes.DASHBOARD, label: 'Dashboard', guard: isStudent},
  {path: routes.COURSES_DEGREE_PICKER, label: 'Course Info', guard: isStudent},
  {path: routes.REGISTRATION, label: 'Profile', guard: isStudent},
  {path: routes.ELIGIBILITY_DASHBOARD, label: 'Eligibility Calculator', guard: isStudent},
]

const getMenuLinks = (userInfo) =>
  pipe(
    filter(({guard}) => guard(userInfo))
  )(menuLinks)

export const Menu = pipe(
  withStyles(styles),
  withUser
)(class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {open: false}
  }

  open = () => this.setState({open: true})

  close = () => this.setState({open: false})

  render () {
    const {classes, logout, user} = this.props

    return (
      <div>
        <Button
          onClick={this.open}
          className={classes.plainButton}
        >
          <MenuIcon style={{color: 'white'}}/>
        </Button> 

        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.close}
          classes={{paper: classes.dialogPaper, root: classes.dialogRoot}}
        >
          <div className={classes.closeSection}>
            <IconButton>
              <img src={closeButton} onClick={this.close} className={classes.closeImage} alt="Close menu"/>
            </IconButton>
          </div>

          <div className={classes.linkGroup}>
            {getMenuLinks(user).map(({path, label}) =>
              <MenuNavLink key={label} path={path} label={label} onClick={this.close} />
            )}
          </div>

          <Link to={routes.ROOT} onClick={logout} className={classes.linkItem}>
            <Typography variant="body1" color="primary" className={classes.linkText}>Log Out</Typography>
          </Link>
        </Dialog>
      </div>
    )
  }
})

const MenuNavLink = pipe(
  withStyles(styles),
)(({classes, path, label, onClick}) =>
  <NavLink to={path} onClick={onClick} className={classes.linkItem} activeClassName={classes.activeLink}>
    <Typography variant="body1" color="primary" className={classes.linkText}>{label}</Typography>
  </NavLink>
)