import { prop } from 'ramda'
import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { Excelsior } from './excelsior.component'
import { Federal } from './federal.component'
import { Tap } from './tap.component'
import { PrivateRoute } from '../../routing/private-route.component'

export const FinancialAidDetails = class extends React.Component {
  detailsForType = aidType => prop('details', this.props.financialAidDetails.find(({type}) => type === aidType))

  render() {
    return (
      <Router history={this.props.history}>
        <Switch>
          <PrivateRoute
            path="/registration/financial-aid-details/excelsior"
            component={Excelsior}
            handleAidDetails={this.props.handleAidDetails}
            details={this.detailsForType('EXCELSIOR')}
            isReceiving={this.props.financialAid.receiving.includes('EXCELSIOR')}
          />
          <PrivateRoute
            path="/registration/financial-aid-details/tap"
            component={Tap}
            handleAidDetails={this.props.handleAidDetails}
            details={this.detailsForType('TAP')}
          />
          <PrivateRoute
            path="/registration/financial-aid-details/federal"
            component={Federal}
            handleAidDetails={this.props.handleAidDetails}
            details={{PELL: this.detailsForType('PELL'), STAFFORD: this.detailsForType('STAFFORD')}}
          />
        </Switch>
      </Router>
    )
  }
}