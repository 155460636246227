import { dropWhile, find, pipe, prop, reverse, drop, T, findIndex, propEq, filter } from 'ramda'
import {
  EarnedCredits, EnrolledCredits, Relation, PreviousSemesterEarnedCredits, CumulativeGpa, MajorGpaRequirement,
  DegreeType, OpportunityProgram, School, ExcelsiorFirstReceived, FirstEnrolled, Checklist
} from './steps'

const receiving = ({relation}) => relation === 'RECEIVING'
const interestedIn = ({relation}) => relation === 'INTERESTED_IN'
const enrolled = (state) => receiving(state) || interestedIn(state)

export const EXCELSIOR_PAGES = [
  {path: '/excelsior-registration/relation', component: Relation, guard: T},
  {path: '/excelsior-registration/school', component: School, guard: T},
  {path: '/excelsior-registration/degree-type', component: DegreeType, guard: enrolled},
  {path: '/excelsior-registration/first-enrolled', component: FirstEnrolled, guard: interestedIn},
  {path: '/excelsior-registration/opportunity-program', component: OpportunityProgram, guard: enrolled},
  {path: '/excelsior-registration/excelsior-first-received', component: ExcelsiorFirstReceived, guard: receiving},
  {path: '/excelsior-registration/major-gpa-requirement', component: MajorGpaRequirement, guard: enrolled},
  {path: '/excelsior-registration/enrolled-credits', component: EnrolledCredits, guard: enrolled},
  {path: '/excelsior-registration/earned-credits', component: EarnedCredits, guard: enrolled},
  {path: '/excelsior-registration/previous-semester-earned-credits', component: PreviousSemesterEarnedCredits, guard: enrolled},
  {path: '/excelsior-registration/cumulative-gpa', component: CumulativeGpa, guard: enrolled},
  {path: '/excelsior-registration/checklist', component: Checklist, guard: T},
]

const getNextRoute = (pages, currentPath, state) =>
  pipe(
    dropWhile(({path}) => path !== currentPath),
    drop(1),
    find(({guard}) => guard(state)),
    prop('path')
  )(pages)

const filteredPages = state => filter(({guard}) => guard(state), EXCELSIOR_PAGES)

export const next = (currentPath, state) => getNextRoute(EXCELSIOR_PAGES, currentPath, state)
export const prev = (currentPath, state) => getNextRoute(reverse(EXCELSIOR_PAGES), currentPath, state)

export const totalSteps = state => filteredPages(state).length
export const currentStep = (state, pathname) => findIndex(propEq('path', pathname), filteredPages(state)) + 1
