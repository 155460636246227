import { FormControl } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { Dropdown } from '../common/dropdown.component'
import { Input } from '../common/input.component'
import { withDataValidation } from '../common/with-data-validation'
import { Section } from '../../common/section.component'
import { RegistrationStep } from '../common/registration-step.component'

const OPTIONS = [
  {value: "201609", label: "Fall 2016"},
  {value: "201702", label: "Spring 2017"},
  {value: "201709", label: "Fall 2017"},
  {value: "201802", label: "Spring 2018"},
  {value: "201809", label: "Fall 2018"},
  {value: "201902", label: "Spring 2019"},
  {value: "201909", label: "Fall 2019"},
  {value: "202002", label: "Spring 2020"},
  {value: "202009", label: "Fall 2020"},
  {value: "202102", label: "Spring 2021"},
  {value: "202109", label: "Fall 2021"},
]

export const Excelsior = pipe(
  withDataValidation({
    firstReceived: {
      required: false,
      guard: ({isReceiving, firstReceived}) => !Boolean(isReceiving) || Boolean(firstReceived),
      msg: "Required"
    },
    majorGpaRequirement: {
      guard: ({majorGpaRequirement}) => 0 <= parseFloat(majorGpaRequirement) && parseFloat(majorGpaRequirement) <= 4,
      msg: 'Required number between 0 - 4'
    },
    previousSemesterCreditsEarned: {
      guard: ({previousSemesterCreditsEarned}) => parseInt(previousSemesterCreditsEarned, 10) <= 21,
      msg: 'Should be no more than 21'
    }
  })
)(class extends React.Component {
  constructor(props) {
    super(props)

    const {firstReceived, majorGpaRequirement, previousSemesterCreditsEarned} = this.props.details
    this.state = {firstReceived, majorGpaRequirement, previousSemesterCreditsEarned}
  }

  handleFirstReceivedChange = event => this.setState({firstReceived: event.target.value})
  handleMajorGpaRequirementChange = event => this.setState({majorGpaRequirement: event.target.value})
  handlePreviousSemesterCreditsEarnedChange = event => this.setState({previousSemesterCreditsEarned: event.target.value})

  updatedDetails = () => [{type: 'EXCELSIOR', details: this.state}]
  handleNextClick = () =>
    this.props.validate({...this.state, isReceiving: this.props.isReceiving}) &&
    this.props.handleAidDetails(this.updatedDetails(), true)
  handleBackClick = () => this.props.handleAidDetails(this.updatedDetails(), false)

  render() {
    return (
      <RegistrationStep
        stepTitle="Financial Aid Details (4/5)"
        title="About your Excelsior Scholarship"
        handleNextClick={this.handleNextClick}
        handleBackClick={this.handleBackClick}
      >
        {this.props.isReceiving &&
          <Section
            header="When did you first receive the Excelsior Scholarship?"
          >
            <FormControl fullWidth>
              <Dropdown
                placeholder="Select semester"
                options={OPTIONS}
                value={this.state.firstReceived}
                onChange={this.handleFirstReceivedChange}
                error={this.props.errors.firstReceived}
              />
            </FormControl>
          </Section>
        }
        <Section
          header="What is the minimum GPA required for your major?"
        >
          <Input
            value={this.state.majorGpaRequirement}
            onChange={this.handleMajorGpaRequirementChange}
            error={this.props.errors.majorGpaRequirement}
            placeholder='Ex. 2'
          />
        </Section>
        <Section
          header="How many total credits did you earn last semester in your major?"
        >
          <Input
            value={this.state.previousSemesterCreditsEarned}
            onChange={this.handlePreviousSemesterCreditsEarnedChange}
            error={this.props.errors.previousSemesterCreditsEarned}
            placeholder='Ex. 12'
          />
        </Section>
      </RegistrationStep>
    )
  }
})
