import React from "react"
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  table: {
    marginBottom: theme.spacing.unit,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  tableTitle: {
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
  },
  tableBody: {
    padding: `2px ${theme.spacing.unit}px`,
  },
  tableChildren: {
    padding: `2px ${theme.spacing.unit}px`,
    paddingTop: 0
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 'bold'
  },
  row: {
    minHeight: 22
  },
  header: {
    textTransform: 'uppercase',
    "@media print": {
      fontSize: 10
    }
  },
  cell: {
    fontWeight: 'normal',
    "@media print": {
      fontSize: 12
    }
  }
})

export const CustomTable = pipe(
  withStyles(styles)
)(({ children, classes, title, headers, rows, descriptionHeader, description }) =>
  <Grid container className={classes.table}>
    <Grid item xs={12} className={classes.tableTitle}>
      <Typography variant="subtitle1" className={classes.title}>{title}</Typography>
    </Grid>
    <Grid item xs={12} className={classes.tableBody}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container alignItems="center" className={classes.row}>
            {headers.map((header, i) => (
              <Grid item xs={headers.length === 3 && i === 1 ? 6 : 3} key={header}>
                <Typography variant="subtitle2" align={i === 0 ? "left" : "center"} className={classes.header}>
                  {header}
                </Typography>
              </Grid>)
            )}
          </Grid>
          {rows.map(row => (
            <Grid container key={row[0]} alignItems="center" className={classes.row}>
              {row.map((cell, i) => (
                <Grid item xs={headers.length === 3 && i === 1 ? 6 : 3} key={`${cell}-${headers[i]}`}>
                  <Typography variant="subtitle1" align={i === 0 ? "left" : "center"} className={classes.cell}>
                    {cell}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignContent="center" className={classes.row}>
                <Typography variant="subtitle2" className={classes.header}>
                  {descriptionHeader}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Typography variant="subtitle1" className={classes.cell}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    {children && (
      <Grid item xs={12} className={classes.tableChildren}>
        {children}
      </Grid>
    )}
  </Grid>
)