import { defaultTheme } from './default-theme'

export const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '40px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.23,
        letterSpacing: 'normal',
      },
      h3: {
        fontSize: '26px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.23,
        letterSpacing: 'normal',
        marginBottom: '12px',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        marginBottom: '16px'
      },
      h5: {
        fontSize: '18px',
        fontWeight: 600,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        marginBottom: '12px',
      },
      subtitle1: {
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal'
      },
      subtitle2: {
        fontSize: '12px',
        fontWeight: 700,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal'
      }
    }
  }
}

export const boxShadow = '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
