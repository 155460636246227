import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const styles = theme => ({
  section: {
    paddingTop: `${theme.spacing.unit * 3.5}px`
  }
})

export const DashboardSection = withStyles(styles)(({classes, header, children}) =>
  <div className={classes.section}>
    {header && <Typography variant="h4">{header}</Typography>}
    {children}
  </div>
)