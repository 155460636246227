import { pipe } from 'ramda'
import React from 'react'
import { withUser } from '../../providers/user.provider'
import landingImage from '../../assets/images/landing.svg'
import { withHelpModal } from '../help/with-help-modal'
import { SimpleGoogleSignIn } from '../signin/google-signin.component'
import { IdleLogoutAlert } from './idle-logout-alert.component'
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { Link } from "react-router-dom"
import { Button } from "../common/button.component"
import { routes } from "../routing/routes-paths"

const styles = theme => ({
  body: {
    padding: `${theme.spacing.unit * 2}px 0`
  },
  navigation: {
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  secondaryNavigation: {
    marginTop: theme.spacing.unit * 2
  }
})

export const Homepage = pipe(
  withHelpModal(IdleLogoutAlert),
  withUser,
  withStyles(styles)
)(class extends React.Component {
  componentDidMount() {
    const {user, openModal} = this.props

    if (user.inactivityLogout) setTimeout(
      () => openModal() || user.updateUserData({inactivityLogout: false}),
      2000
    )
  }

  render() {
    const {classes} = this.props

    return (
      <div>
        <Typography variant="h3" className="bold">
          Meet FutureGrad, your financial aid helper
        </Typography>
        <Typography variant="body1" gutterBottom>
          Financial aid can be so confusing, but it doesn't have to be. FutureGrad helps you keep track of all the
          requirements and deadlines so you don't lose your aid. And it's free!
        </Typography>

        <div className={classes.body}>
          <div style={{textAlign: 'center'}}>
            <img src={landingImage} alt="Landing"/>
          </div>
        </div>

        <div className={classes.navigation}>
          <Link to={routes.DOCUMENTS_LIST} className={classes.link}>
            <Button text="Get Started" />
          </Link>
          <div className={classes.secondaryNavigation}>
            <SimpleGoogleSignIn/>
          </div>
        </div>
      </div>
    )
  }
})