import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { omit, pipe } from 'ramda'
import * as React from 'react'
import { withUser } from '../../providers/user.provider'
import { postData } from '../../utils/api-utils'
import { withApiData } from '../common/with-api-data'
import { withHelpModal } from '../help/with-help-modal'
import { Input } from '../registration/common/input.component'
import { NavigationButtons } from '../registration/common/navigation-buttons.component'
import { Section } from '../common/section.component'
import { withDataValidation } from '../registration/common/with-data-validation'
import { SuccessAlert } from './success-alert.component'
import { routes } from "../routing/routes-paths"

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    margin: `${theme.spacing.unit * 2}px 0`
  }
})

export const TermRevision = pipe(
  withStyles(styles),
  withUser,
  withHelpModal(SuccessAlert),
  withApiData('/api/academic-record/201902'),
  withDataValidation({
    cumulativeGpa: {guard: ({cumulativeGpa}) => 0 <= cumulativeGpa && cumulativeGpa <= 4, msg: 'Required number between 0 - 4'},
    earnedCredits: {
      guard: ({earnedCredits, enrolledCredits}) => parseInt(earnedCredits, 10) <= parseInt(enrolledCredits, 10),
      msg: ({enrolledCredits}) => `Earned credits needs to be less than enrolled credits (${enrolledCredits}).`
    },
    incompleteCredits: {},
    failedCredits: {},
    withdrawnCredits: {},
    moreInfoRequired: {
      guard: ({earnedCredits, enrolledCredits, incompleteCredits, failedCredits, withdrawnCredits}) =>
        enrolledCredits === earnedCredits + incompleteCredits + failedCredits + withdrawnCredits,
      msg: ({earnedCredits, enrolledCredits, incompleteCredits, failedCredits, withdrawnCredits}) =>
        `You're still ${enrolledCredits - earnedCredits - incompleteCredits - failedCredits - withdrawnCredits} credits short.
         Please update the credits for any courses you dropped, failed or received an incomplete for.`
    }
  }),
)(class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cumulativeGpa: '', earnedCredits: '', enrolledCredits: this.props.data.creditsEnrolled,
      incompleteCredits: 0, failedCredits: 0, withdrawnCredits: 0,
      moreInfoRequired: true, term: this.props.user.incompleteMilestone.term
    }
  }

  handleNextClick = () => {
    this.props.validate(this.state) && postData(
      '/api/term-revision',
      omit(['moreInfoRequired', 'enrolledCredits'], this.state),
      () => {
        this.props.openModal()
        setTimeout(
          () => this.props.user.refreshUserData(
            () => this.props.history.push(routes.ROOT)
          ),
          2000
        )
      }
    )
  }

  handleInputChange = key => event => this.setState({[key]: parseFloat(event.target.value)})

  render() {
    const {classes, errors} = this.props
    return (
      <div className={classes.root}>
        {
          errors.cumulativeGpa  || errors.earnedCredits || !errors.moreInfoRequired
            ? <AcademicRecordConfirmation
              handleInputChange={this.handleInputChange}
              cumulativeGpa={this.state.cumulativeGpa}
              earnedCredits={this.state.earnedCredits}
              errors={errors}
            />
            : <FailedCreditsForm
              handleInputChange={this.handleInputChange}
              earnedCredits={this.state.earnedCredits}
              enrolledCredits={this.state.enrolledCredits}
              incompleteCredits={this.state.incompleteCredits}
              failedCredits={this.state.failedCredits}
              withdrawnCredits={this.state.withdrawnCredits}
              errors={errors}
            />
        }
        <NavigationButtons handleNextClick={this.handleNextClick} nextLabel='Update my record'/>
      </div>
    )
  }
})

const AcademicRecordConfirmation = pipe(
  withStyles(styles),
  withUser
)(({classes, user, handleInputChange, cumulativeGpa, earnedCredits, errors}) =>
  <div>
    <Typography variant="h3" color="inherit" gutterBottom>
      {`Congratulations${user.name ? `, ${user.name.split(' ')[0]}` : ''}!`}
    </Typography>
    <Typography variant="body1" color="inherit" gutterBottom>
      You are one semester closer to graduation!
    </Typography>
    <Typography variant="body2" color="inherit" gutterBottom>
      Now the finals are over, let's check on your financial aid eligibility by updating your academic record.
    </Typography>
    <div className={classes.body}>
      <Section header="What's your current cumulative GPA?">
        <Input
          onChange={handleInputChange('cumulativeGpa')}
          placeholder='Ex 2.0'
          value={cumulativeGpa}
          error={errors.cumulativeGpa}
        />
      </Section>
      <Section header="How many credits have you earned in previous semester?">
        <Input
          onChange={handleInputChange('earnedCredits')}
          placeholder='Ex 15'
          value={earnedCredits}
          error={errors.earnedCredits}
        />
      </Section>
    </div>
  </div>
)

const FailedCreditsForm = pipe(
  withStyles(styles)
)(({classes, handleInputChange, enrolledCredits, earnedCredits, incompleteCredits, failedCredits, withdrawnCredits, errors}) =>
  <div>
    <Typography variant="h3" color="inherit" gutterBottom>
      Update your record
    </Typography>
    <Typography variant="body1" color="inherit" gutterBottom>
      It looks like you attempted {enrolledCredits} credits but earned {earnedCredits} this semester.
    </Typography>
    <div className={classes.body}>
      <Section header="For how many credits did you receive an Incomplete (INC?)">
        <Input
          onChange={handleInputChange('incompleteCredits')}
          placeholder='Ex 3'
          value={incompleteCredits}
          error={errors.incompleteCredits}
        />
      </Section>
      <Section header="For how many credits did you fail a class (F)">
        <Input
          onChange={handleInputChange('failedCredits')}
          placeholder='Ex 3'
          value={failedCredits}
          error={errors.failedCredits}
        />
      </Section>
      <Section header="For how many credits did you drop a class (W)">
        <Input
          onChange={handleInputChange('withdrawnCredits')}
          placeholder='Ex 3'
          value={withdrawnCredits}
          error={errors.withdrawnCredits}
        />
      </Section>
      {
        errors.moreInfoRequired &&
        <Typography variant="body2" color="error">
          {errors.moreInfoRequired}
        </Typography>
      }
    </div>
  </div>
)