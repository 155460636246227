import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { ExcelsiorDisclaimer } from '../common/disclaimer.component'
import { Button } from '../common/button.component'
import { Section } from '../common/section.component'
import { SimpleGoogleSignIn } from '../signin/google-signin.component'
import { withUser } from '../../providers/user.provider'
import { withStyles } from '@material-ui/core'
import excelsiorImage from '../../assets/images/excelsior_landing.jpg'
import { routes } from "../routing/routes-paths"

const styles = theme => ({
  backgroundImage: {
    background: `url(${excelsiorImage}) center/cover no-repeat`,
    minHeight: '50vh',
    margin: '-20px -20px 0 -20px',
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    marginBottom: theme.spacing.unit * 4
  },
  imageOpcacity: {
    minHeight: '50vh',
    minWidth: '100%',
    opacity: '0.55',
    backgroundImage: 'linear-gradient(to top, #bfcfe0, #2164cf)',
  },
  mainHeader: {
    color: 'white',
    position: 'absolute',
    top: 0,
    padding: 8,
    width: '100%'
  },
  actionButtons: {
    position: 'absolute',
    top: `calc(50vh - 100px)`,
    padding: 8,
    width: '100%',
    color: 'white'
  },
  link: {
    // marginTop: 16,
    textDecoration: 'none',
  },
  secondaryNavigation: {
    marginTop: theme.spacing.unit * 2
  }
})

export const ExcelsiorLanding = pipe(
  withUser,
  withStyles(styles)
)(({user, classes}) =>
  user.authenticated
    ? (user.role === 'EXCELSIOR_STUDENT'
    ? <Redirect to={routes.EXCELSIOR_ELIGIBILITY}/>
    : <Redirect to={routes.ROOT}/>)
    : localStorage.getItem('excelsiorUserEmail') && localStorage.getItem('excelsiorRequirements')
    ? <Redirect to={routes.EXCELSIOR_ELIGIBILITY}/>
    : <div>
      <div className={classes.backgroundImage}>
        <div className={classes.imageOpcacity}>
        </div>
        <div className={classes.mainHeader}>
          <div style={{marginBottom: 24}}>
            <Typography variant='h3' color='inherit'>
              Do you meet all requirements for the Excelsior Scholarship?
            </Typography>
            <Typography variant='body1' color='inherit' className='bold'>
              Don’t lose up to $5500 in scholarship dollars
            </Typography>
          </div>
          <div className={classes.actionButtons}>
            <Link to={routes.EXCELSIOR_REGISTRATION} className={classes.link}>
              <Button highlight color='primary' text='Check my eligibility'/>
            </Link>
            <div className={classes.secondaryNavigation}>
              <SimpleGoogleSignIn color='inherit'/>
            </div>
          </div>
        </div>
      </div>
      <Section>
        <Typography variant='h4' gutterBottom>
          What is the Excelsior Scholarship?
        </Typography>
        <Typography variant='body1' style={{lineHeight: 1.3}}>
          The Excelsior Scholarship, in combination with other student financial aid programs, allows students to attend
          a SUNY or CUNY college tuition-free.
        </Typography>
      </Section>
      <Section>
        <ExcelsiorDisclaimer/>
      </Section>
    </div>
)