import React from 'react'
import { Header, MailTo, Paragraph, Subtitle, Title } from './common.component'

export const PrivacyPolicy = () =>
  <>
    <Title>
      Privacy Policy
    </Title>
    <Subtitle>
      Last Updated: January 8, 2019
    </Subtitle>

    <Paragraph>
      Welcome to Yenko, Inc.’s website located at www.future-grad.com (the <strong>“Site”</strong>). This privacy policy
      (“Privacy
      Policy”) describes how we collect, use and disclose information we receive through our Site and any of our other
      products or services that link to this Privacy Policy that FutureGrad may provide now or in the future
      (collectively, “FutureGrad Platform” or the “Service”). This privacy policy ("Privacy Policy") is incorporated by
      reference into the FutureGrad Terms of Use.
    </Paragraph>

    <Header>
      REVISIONS TO THIS PRIVACY POLICY
    </Header>

    <Paragraph>
      Any information collected by the Services is covered by the Privacy Policy in effect at the time such information
      is collected. We may revise this Privacy Policy from time to time. If we make any material changes to this Privacy
      Policy, we'll notify you of those changes by posting them on the Services or by sending you other notification,
      and we'll update the "Last Updated" date above to indicate when those changes will become effective.
    </Paragraph>

    <Header>
      INFORMATION YOU PROVIDE TO US
    </Header>

    <Paragraph>
      <u>Account Information.</u> We receive and store any information you knowingly provide to us. You must create an account
      in order to use the Services (the “Account”). Through the registration process and/or through your Account
      settings, we may collect personal information including your name, email address, phone number), account
      information and password, demographic or other information (such as your school, gender, age or birthday, and
      other information about your interests and preferences), and verified third-party account credentials
      (collectively “Personal Information”). Any other information combined with your Personal Information will be
      treated together as Personal Information. You will have the opportunity to create a profile, which may include
      Personal Information, information about your academic, financial aid and work history, proof of financial need,
      family income documentation, our interests and activities, and your use of FutureGrad's Services and other
      information.
    </Paragraph>

    <Paragraph>
      All information entered by you is voluntary and at your own discretion, though certain information is required in
      order to create and Account or to take use the Services. If you provide such information, you consent to the use
      of that information in accordance with the policies and practices described in this Privacy Policy. FutureGrad
      may, send you notifications, information, materials, or other offers through email, text, or other type of
      notification. Also, we may receive a confirmation when you open an email from us. This confirmation helps us make
      our communications with you more interesting and improve our Services. If you do not want to receive
      communications from us, please indicate your preference by contacting us at <MailTo email="support@future-grad.com" /> or by
      unsubscribing from our emails.
    </Paragraph>

    <Paragraph>
      <u>Information Collected Through Your University or College Preparation Program.</u> We will add information to
      your Account provided by your University or College or College Preparation Program.
      This will include information such as the financial aid, coursework, grades, GPA, course grades in progress, and
      any other information that the University may provide to us in connection with our Services. FutureGrad
      understands that it is important that our partner Universities and Colleges comply with the Family Education
      Rights and Privacy Act (“FERPA”) and related regulations. Certain information collected through your University or
      College that may be provided to FutureGrad that is directly related to a student and maintained by your University
      or College, may be considered an education record (“education record”) under FERPA.
    </Paragraph>

    <Header>
      INFORMATION COLLECTED AUTOMATICALLY
    </Header>
    <Paragraph>
      Like many website owners and operators, we use automated data collection tools such as Cookies and Web Beacons to
      collect certain information. This information is necessary for the adequate performance of the contract between
      you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to
      provide and improve the FutureGrad Platform.
    </Paragraph>

    <Paragraph>
      The technologies and information we automatically collect include:
    </Paragraph>
    <Paragraph>
      <strong>"Cookies"</strong> are small text files that are placed on your hard drive by a Web server when you access
      our Services. We
      may use Cookies to tell us how and when you interact with our Services , to monitor aggregate usage and web
      traffic routing on our Services, and to customize and improve our Services. Although most browsers automatically
      accept Cookies, you can change your browser options to stop automatically accepting Cookies or to prompt you
      before accepting Cookies. However, if you don't accept Cookies, you may not be able to access all portions or
      features of the Services. Some third-party services providers that we engage (including third-party advertisers)
      may also place their own Cookies on your hard drive. Note that this Privacy Policy covers only our use of Cookies
      and does not include use of Cookies by such third parties.
    </Paragraph>
    <Paragraph>
      <strong>"Web Beacons"</strong> (also known as Web bugs, pixel tags or clear GIFs) are tiny graphics with a unique
      identifier that
      may be included on the FutureGrad Platform and Site for several purposes, including to deliver or communicate with
      Cookies, to track and measure the performance of the FutureGrad Platform, to monitor how many visitors view the
      FutureGrad Platform, and to monitor the effectiveness of our advertising.
    </Paragraph>
    <Paragraph>
      <u>Information Related to Use of the Services.</u> Our servers may automatically record certain information about
      how a
      person uses the FutureGrad Platform or Site (we refer to this information as <strong>"Log Data"</strong>). Log Data may include
      information such as a user's Internet Protocol (IP) address, browser type, operating system, the web page that a
      user was visiting before accessing our Site or the FutureGrad Platform, the pages or features of the FutureGrad
      Platform to which a user browsed and the time spent on those pages or features, search terms, the links on the
      FutureGrad Site or Platform that a user clicked on and other statistics. We use Log Data to administer the
      FutureGrad Platform and we analyze (and may engage third parties to analyze) Log Data to improve, customize and
      enhance our the FutureGrad Platform by expanding their features and functionality and tailoring them to our user's
      needs and preferences. We may use a person's IP address to generate aggregate, non-identifying information about
      how the FutureGrad Platform is used.
    </Paragraph>
    <Paragraph>
      <u>Information Sent by Your Device.</u> We collect certain information that your device from which you are using the
      FutureGrad Platform and Site (such as, mobile phone, iPad, computer, etc.) sends when you use the FutureGrad
      Platform and Site, like a device identifier, user settings and the operating system of your device, as well as
      information about your use of the FutureGrad Platform and Site.
    </Paragraph>
    <Paragraph>
      <u>Location Information.</u> When you use our App, we may collect and store information about your location by converting
      your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location
      if you enable location services on your device. We may use location information to improve and personalize our the
      FutureGrad Platform for you.
    </Paragraph>
    <Header>
      HOW WE USE YOUR INFORMATION
    </Header>
    <Paragraph>
      We use information about you in the following ways:
    </Paragraph>
    <Paragraph>
      <ul>
        <li>
          <strong>Information You Give To Us and Information Provided By Your University or College.</strong> We will use this
          information:
          <ul>
            <li>
              to provide you with the information and services that you request from us;
            </li>
            <li>
              to provide you with information about goods or services we feel may interest you. We will only contact you
              by
            </li>
            <li>
              electronic means (e-mail or SMS) where you have consented to this, or where we can otherwise lawfully do
              so.
            </li>
            <li>
              to notify you about changes to the FutureGrad Platform;
            </li>
            <li>
              to ensure that content from FutureGrad is presented in the most effective manner for you and for your
              computer.
            </li>
          </ul>
        </li>
        <li>
          <strong>Information Automatically Collected.</strong> We will use this information:
          <ul>
            <li>
              to administer FutureGrad and for internal operations, including troubleshooting, site traffic, data
              analysis,
              testing, research, statistical and survey purposes;
            </li>
            <li>
              to improve FutureGrad to ensure that content is presented in the most effective manner;
            </li>
            <li>
              as part of our efforts to keep FutureGrad safe and secure;
            </li>
            <li>
              to make suggestions and recommendations to you and other users of FutureGrad about goods or services that
              may
              interest you or them.
            </li>
          </ul>
        </li>
      </ul>
    </Paragraph>

    <Paragraph>
      Our primary goals in collecting information are to provide and improve our Services, to administer your use of the
      FutureGrad Platform (including your Account, if you are an Account holder), and to enable you to enjoy and easily
      navigate the FutureGrad Platform. We will use your Personal Information to:
      <ul>
        <li>
          administer the FutureGrad Platform, communicate with you and provide customer support in relation to the
          FutureGrad Platform.
        </li>
        <li>
          resolve disputes, collect fees (if any) and troubleshoot problems.
        </li>
        <li>
          customize your experience and otherwise measure and improve the FutureGrad Platform.
        </li>
        <li>
          send you relevant emails and communications (including keeping you informed about our products, offerings and
          any promotional offers) that might be of interest to you (If we use your personal information to market to
          you, you will be able to opt-out of such uses).
        </li>
        <li>
          enforce our agreements, terms, conditions, and policies, and send you notices and alerts.
        </li>
      </ul>
      Additionally, we may offer you products and services that may be of your interest. You can opt-out of receiving
      marketing communications from us by following the unsubscribe instructions included in our marketing
      communications or changing your notification settings within your FutureGrad account.
    </Paragraph>

    <Header>
      INFORMATION THAT WE SHARE WITH THIRD PARTIES
    </Header>
    <Paragraph>
      First and foremost, you should know that FutureGrad does not sell or rent your Personal Information to any
      third-party for any purpose - including for advertising or marketing purposes. Third-party advertising is not
      permitted on areas where Enrollees are required to log in to the FutureGrad Platform and Personal Information is
      never used for behaviorally-targeted advertising (by us or third-parties). Furthermore, we do not share your
      Personal Information with any third-parties except in the limited circumstances described in this Privacy Policy
      and as set forth below:
    </Paragraph>
    <Paragraph>
      <u>Information Shared with Authorized Viewers.</u> We will share your information, including your Personal Information,
      with individuals that you have explicitly authorized us to share your information (“Authorized Viewers”).
    </Paragraph>
    <Paragraph>
      <u>Information Shared with Our Service Providers.</u> We may engage third-party services providers to work with us to
      administer and provide the Services (e.g. companies that provide us services such as hosting and maintenance of
      our Site, data storage and management, etc.). These third-party services providers have access to your Personal
      Information only to the extent needed to perform services on our behalf.
    </Paragraph>
    <Paragraph>
      <u>Information Shared with Third Parties.</u> From time to time in the course of our business or operations, we may
      collaborate with High Schools, College Preparation Programs, Colleges, Universities or other educational partners
      to collect, analyze and process data relating to our student users. Such collaboration enables us to grow and
      improve our Services. Generally, any such data is collected in an aggregated and anonymized format, which means
      that Personal Information will not be included in any such collected data. Your use of the platform constitutes
      your consent to the collection and sharing of aggregated, anonymized data from such third parties in the manner
      described in this paragraph. In the event that any data is collected from third parties in a manner that contains
      Personal Information, we will take reasonable efforts to ensure that our users have consented to the collection
      and sharing of such Personal Information.
    </Paragraph>
    <Paragraph>
      <u>Analytics Services.</u> We use analytics services, including mobile analytics software, to help us understand and
      improve how the FutureGrad Platform is being used. These services may collect, store and use information in order
      to help us understand things like how often you use the FutureGrad Platform, the events that occur within the
      application, usage, performance data, and from where the application was downloaded.
    </Paragraph>
    <Paragraph>
      <u>Aggregated Information and Non-identifying Information.</u> We may share aggregated information (information about our
      users that we combine together so that it no longer identifies or references an individual user) and other
      non-personally identifiable information, including with users, partners or the press in order to, for example,
      demonstrate how the FutureGrad Platform is used, spot industry trends, or to provide marketing materials for
      FutureGrad. Any aggregated information and non-personalized information shared this way will not contain any
      personal information.
    </Paragraph>
    <Paragraph>
      <u>Legal Requirements.</u> We may disclose personal information if we have a good faith belief that doing so is necessary
      to comply with the law, such as complying with a subpoena or other legal process. We may need to disclose personal
      information where, in good faith, we think it is necessary to protect the rights, property, or safety of
      FutureGrad, our employees, our community, or others, or to prevent violations of our Terms of Use or other
      agreements. This includes, without limitation, exchanging information with other companies and organizations for
      fraud protection or responding to law enforcement and government requests. Where appropriate, we may notify users
      about the legal requests, unless (i) providing notice is prohibited by the legal process itself, by court order we
      receive, or by applicable law; (ii) we believe that providing notice would be futile, ineffective, create a risk
      of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon FutureGrad, or its
      users. In instances where we comply with legal requests without notice for these reasons, we will attempt to
      notify that user about the request after the fact where appropriate and where we determine in good faith that we
      are no longer prevented from doing so.
    </Paragraph>
    <Paragraph>
      <u>Change of Control.</u> In the event that all or a portion of FutureGrad or its assets are acquired by or merged with a
      third-party, personal information that we have collected from users would be one of the assets transferred to or
      acquired by that third-party. This Privacy Policy will continue to apply to your information, and any acquirer
      would only be able to handle your personal information as per this policy (unless you give consent to a new
      policy). We will provide you with notice of an acquisition within thirty (30) days following the completion of
      such a transaction, by posting on our homepage, or by email to your email address that you provided to us. If you
      do not consent to the use of your personal information by such a successor company, you may request its deletion
      from the company.
    </Paragraph>

    <Header>
      YOUR CHOICES
    </Header>

    <Paragraph>
      We offer you choices regarding the collection, use and sharing of your Personal Information and we'll respect the
      choices you make. Please note that if you decide not to provide us with the Personal Information that we request,
      you may not be able to access all of the features of the Services.
    </Paragraph>
    <Paragraph>
      <u>Opt-Out.</u> We may periodically send you free newsletters, SMS messages and e-mails that directly promote the
      FutureGrad Site and Platform. When you receive such communications from us, you will have the opportunity to
      "opt-out" (either through your Account or by following the unsubscribe instructions provided in the e-mail you
      receive). We do need to send you certain communications regarding use of the FutureGrad Platform and you will not
      be able to opt out of those communications – e.g., communications regarding updates to our Terms of Use or this
      Privacy Policy or communication in connection with your Account.
    </Paragraph>
    <Paragraph>
      <u>Modifying Your Information.</u> You can access and modify the Personal Information associated with your Account by
      sending us an email at <MailTo email="support@future-grad.com" />. If you want us to delete your Personal Information and your Account,
      please email us at <MailTo email="support@future-grad.com" /> with your request. We'll take steps to delete your information as soon we
      can, but some information may remain in archived/backup copies for our records or as otherwise required by law.
    </Paragraph>

    <Header>
      HOW WE PROTECT YOUR INFORMATION
    </Header>

    <Paragraph>
      FutureGrad uses commercially reasonable information security safeguards to protect its databases and servers
      against risks of loss, unauthorized access, destruction, misuse, modification, or inadvertent or improper
      disclosure of data. FutureGrad segregates certain personally identifiable and other information about your use of
      FutureGrad's services into separate databases, some of which can be accessed only by a limited number of
      employees. Your FutureGrad account is protected by a password. You can help us protect against unauthorized access
      to your account by keeping your password secret at all times.
    </Paragraph>

    <Paragraph>
      We restrict access to personal information to authorized FutureGrad administrators, agents or independent
      contractors who need to know that information in order to process it for us, and who are subject to strict
      confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
    </Paragraph>

    <Header>
      LINKS TO OTHER SITES
    </Header>

    <Paragraph>
      The FutureGrad Site and Platform may contain links to websites and services that are owned or operated by third
      parties (each, a "Third-party Service"). Any information that you provide on or to a Third-party Service or that
      is collected by a Third-party Service is provided directly to the owner or operator of the Third-party Service and
      is subject to the owner's or operator's privacy policy. FutureGrad is not responsible for the content, privacy or
      security practices and policies of any Third-party Service.
    </Paragraph>

    <Header>
      INTERNATIONAL TRANSFER
    </Header>

    <Paragraph>
      FutureGrad is operated in the United States. If you are located outside of the United States, please be aware that
      the information we collect about you, including personal information, will be transferred to the United States. By
      using FutureGrad, you consent to the transfer, processing and storage of your information, including personal
      information, in the United States in accordance with this Privacy Policy.
    </Paragraph>

    <Header>
      OUR POLICY TOWARD CHILDREN
    </Header>

    <Paragraph>
      The FutureGrad Site and Platform are not intended for use by children under the age of 13 and does not knowingly
      collect personal information from children under 13 years of age. If you believe that your child may have provided
      us with personal information without your consent, you may contact us at <MailTo email="support@future-grad.com" /> If we learn that we
      have collected Personal Information of a child under 13 we will take steps to delete such information from our
      files as soon as possible.
    </Paragraph>

    <Header>
      RESPONDING TO DO NOT TRACK SIGNALS
    </Header>

    <Paragraph>
      Our Site and Platform does not have the capability to respond to "Do Not Track" signals received from various web
      browsers.
    </Paragraph>

    <Header>
      CALIFORNIA PRIVACY RIGHTS
    </Header>

    <Paragraph>
      California residents may request and obtain from us, once a year, free of charge, a list of third parties, if any,
      to which we disclosed their Personal Information for direct marketing purposes during the preceding calendar year
      and the categories of Personal Information shared with those third parties. If you are a California resident and
      wish to obtain that information, please submit your request by sending us an email at <MailTo email="support@future-grad.com" /> with
      "California Privacy Rights" in the subject line.
    </Paragraph>

    <Header>
      QUESTIONS?
    </Header>

    <Paragraph>
      Please contact us at <MailTo email="support@future-grad.com" /> if you have any questions about our Privacy Policy.
    </Paragraph>
  </>
