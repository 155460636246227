import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe, times } from 'ramda'
import React from 'react'
import { ExcelsiorDisclaimer } from '../common/disclaimer.component'
import { NavigationButtons } from '../registration/common/navigation-buttons.component'
import classnames from 'classnames'
import { Section } from '../common/section.component'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  stepIndicator: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    margin: 4,
  },
  pastStep: {
    backgroundColor: theme.palette.primary.main
  },
  futureStep: {
    backgroundColor: 'lightgrey'
  },
})

const ExcelsiorRegistrationStep = pipe(
  withStyles(styles)
)(({classes, children, handleBackClick, handleSubmit, handleNextClick, currentStep, totalSteps, category, data, input}) =>
  <div className={classes.root}>
    <Typography variant="h3" color="inherit">Check Eligibility for the Excelsior Scholarship</Typography>
    <Typography variant="body1">{category}</Typography>
    {currentStep > 1 && <div style={{display: 'flex'}}>
      {times(
        idx => <div key={`past-${idx}`} className={classnames(classes.stepIndicator, classes.pastStep)}/>,
        currentStep - 1
      )}
      {times(
        idx => <div key={`future-${idx}`} className={classnames(classes.stepIndicator, classes.futureStep)}/>,
        totalSteps - currentStep
      )}
    </div> }
    <div className={classes.body}>
      {children}
    </div>
    <Section>
      { currentStep > 1 &&
        <NavigationButtons
          handleBackClick={currentStep !== 1 && handleBackClick}
          handleNextClick={currentStep === totalSteps ? handleSubmit : handleNextClick}
          nextLabel={currentStep === totalSteps && 'Calculate my Eligibility'}
          disabled={data[input] === undefined && currentStep < totalSteps}
        />
      }
    </Section>
    <Section>
      <ExcelsiorDisclaimer/>
    </Section>
  </div>
)

export const excelsiorRegistrationStep = (input, category) => WrapperComponent => props =>
  <ExcelsiorRegistrationStep
    handleNextClick={props.handleNextClick(input)}
    handleSubmit={props.handleSubmit(input)}
    handleBackClick={props.handleBackClick}
    data={props.data}
    currentStep={props.currentStep}
    totalSteps={props.totalSteps}
    category={category}
    input={input}
  >
    <WrapperComponent {...props} />
  </ExcelsiorRegistrationStep>