import React from 'react'

export const Emoji = props =>
  <span
    className="emoji"
    role="img"
    aria-label=''
    aria-hidden='true'
  >
    {props.symbol}
  </span>
