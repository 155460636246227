import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { Input } from '../../registration/common/input.component'

export const CumulativeGpa = pipe(
  excelsiorRegistrationStep('cumulativeGpa', 'About your academic record'),
  withExcelsiorRegistrationData
)(({handleChange, data: {cumulativeGpa}, errors: {cumulativeGpa: error}}) =>
  <StepSection header='What is your cumulative GPA?'>
    <Input
      onChange={event => handleChange({cumulativeGpa: event.target.value})}
      placeholder='Ex 2.8'
      value={cumulativeGpa}
      error={error}
    />
  </StepSection>
)