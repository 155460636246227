import { useEffect } from 'react'
import initHelpHero from "helphero"
import { helpheroId } from "../../config"
import { withUser } from "../../providers/user.provider"


const Helphero = ({user}) => {
  useEffect(() => {
    const {id: userId, name, email, institution, role} = user

    if (helpheroId !== "") {
      const helphero = initHelpHero(helpheroId)
      if (userId == null ) {
        helphero.anonymous()
      } else {
        helphero.identify(userId, {name, email, role, institution})
      }
    }
  }, [user])

  return null
}

export default withUser(Helphero)