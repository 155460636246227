import { IntercomAPI } from "react-intercom"

export const trackEvent = (action, metadata) => {
  if (metadata != null) {
    IntercomAPI('trackEvent', action, metadata)
  } else {
    IntercomAPI('trackEvent', action)
  }
}

