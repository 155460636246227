import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const styles = theme => ({
  withdrawalPoint: {
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing.unit * 0.5,
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  navigateIcon: {
    fontSize: 34,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    display: 'flex'
  }
})

export const WithdrawalPoint = pipe(
  withStyles(styles)
)(({classes, getWithdrawalPoint, handleWithdrawalPointChange}) =>
  <SubSection>
    <Typography variant="h5">
      Select the date you plan to withdraw
    </Typography>
    <Typography variant="body2">
      The date you plan to withdraw can have different outcomes on your financial aid
    </Typography>
    <Row>
      <div className={classes.navigateIcon} onClick={handleWithdrawalPointChange(-1)}>
        <NavigateBeforeIcon fontSize='inherit' color='inherit'/>
      </div>
      <div className={classes.withdrawalPoint}>
        <Typography variant='body2' color='inherit'>{getWithdrawalPoint().label}</Typography>
      </div>
      <div className={classes.navigateIcon} onClick={handleWithdrawalPointChange(1)}>
        <NavigateNextIcon fontSize='inherit' color='inherit'/>
      </div>
    </Row>
  </SubSection>
)