import { pipe } from 'ramda'
import React from 'react'
import * as qs from 'query-string';
import { withUser } from '../../providers/user.provider'
import { postDataWithHandler } from '../../utils/api-utils'
import { withHelpModal } from '../help/with-help-modal'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"

export const CunyVerification = pipe(
  withUser,
  withHelpModal(SimpleHelpModal)
)(class extends React.Component {
  displayName = "CunyVerification"

  componentDidMount() {
    postDataWithHandler(
      '/api/user/verify',
      {
        token: qs.parse(this.props.location.search).token || 'invalid-token'
      },
      (status, {data}) => {
        switch (status) {
          case 200: {
            this.props.user.refreshUserData(this.props.history.push(routes.ROOT))
            break
          }
          default: {
            this.props.openModal(...getErrorMessage(data.reason))
            break
          }
        }
      }
    )
  }

  render() {
    return (
      null
    )
  }
})