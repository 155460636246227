import React from 'react'
import { ErrorBoundary } from '../error/error-boundary.component'
import { MainRoutes, TopBarRoutes } from '../routing/routes.component'
import { Footer } from './footer.component'
import ScrollToTop from '../scroll-to-top.component'

export const Layout = () =>
  <>
    <ScrollToTop/>
    <TopBarRoutes/>
    <div className="App-container">
      <ErrorBoundary>
        <MainRoutes/>
      </ErrorBoundary>
    </div>
    <Footer/>
  </>