import { getFinancialAidByType } from "../registration/financial-aid/financial-aid-types"
import { RiskIcon } from "../common/risk-icon.component"
import React from "react"

export const gpaRequirements = (financialAid) => {
  const title = "GPA"
  const headers = ['Aid type', 'Min. Required', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  const description = "Minimum GPA is required to keep aid; For good academic standing, set a goal at 2.0 GPA or higher."

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type === 'ANNUAL_EARNED_CREDITS' || requirement.type === 'CUMULATIVE_EARNED_CREDITS')[0]
      = aid.requirements.filter(requirement => requirement.type === 'GPA')[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup === 'FEDERAL' ? 'PELL' : aid.aidGroup).name

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "LOW"
      }

      return (
        [
          name,
          requirement.requiredValueFormatted,
          // requirement.targetValueFormatted,
          <RiskIcon risk={requirement.rating} small/>
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}

export const earnedCreditsRequirements = (financialAid) => {
  const title = "Earned Credits"
  const headers = ['Aid type', 'Min. Required', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  const description = "Minimum is required to keep aid; In addition, pass all enrolled credits to stay on track for graduation"

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type === 'ANNUAL_EARNED_CREDITS' || requirement.type === 'CUMULATIVE_EARNED_CREDITS')[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup === 'FEDERAL' ? 'PELL' : aid.aidGroup).name

      if (requirement.type.includes("EARNED_CREDITS") && aid.aidGroup === "EXCELSIOR") {
        name = `${name} *`
      }

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "DEFERRED_HIGH"
      }

      return (
        [
          name,
          requirement.requiredValueFormatted,
          // requirement.targetValueFormatted,
          <RiskIcon risk={requirement.rating} small/>
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}

export const completedCreditsRequirements = (financialAid) => {
  const title = "Completed Credits"
  const headers = ['Aid type', 'Min. Required', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  const description = "Completed credits are the credits you receive a grade (A - F) for. W's (for dropping a class) reduce completed credits."

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type === 'COMPLETED_CREDITS')[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup).name

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "DEFERRED_HIGH"
      }

      return (
        [
          name,
          requirement.requiredValueFormatted,
          // requirement.targetValueFormatted,
          <RiskIcon risk={requirement.rating} small />
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}

export const paymentsLeftRequirement = (financialAid, studentData) => {
  const title = "Semesters of Financial Aid"
  const headers = ['Aid type', 'Used', 'Remaining', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  const description = '"Remaining" shows the number of semesters of financial aid you are eligible to receive.'

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type.includes('_PAYMENTS_LEFT'))[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup === 'FEDERAL' ? 'PELL' : aid.aidGroup).name

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "DEFERRED_HIGH"
      }

      let used;
      if (name === 'Pell') {
        used = studentData.noSemestersPellReceived
      }
      if (name === 'TAP') {
        used = studentData.noSemestersTapReceived
      }

      const remaining = requirement.requiredValue < 0 ? 0 : requirement.requiredValue

      return (
        [
          name,
          used,
          remaining,
          <RiskIcon risk={requirement.rating} small />
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}

export const maximumTimeframeRequirement = (financialAid) => {
  const title = "Maximum Timeframe (Attempted Credits)"
  const headers = ['Aid type', 'Attempted', 'Remaining', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  let description = ""

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type === 'MAXIMUM_TIMEFRAME_LIMIT')[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup).name

      if (aid.aidGroup === "FEDERAL") {
        description = `You are ineligible for Federal Financial aid after ${requirement.requiredValue} credits attempted.`
      }

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "DEFERRED_HIGH"
      }

      const remaining = requirement.requiredValue - requirement.targetValue;

      return (
        [
          name,
          requirement.targetValue,
          remaining > 0 ? remaining : 0,
          <RiskIcon risk={requirement.rating} small />
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}

export const degreeRequirement = (financialAid) => {
  const title = "Degree Requirement"
  const headers = ['Aid type', 'Major', 'Risk level']
  const descriptionHeader = "How could this affect my financial aid?"
  let description = ""

  const rows = financialAid.map(aid => {
    const requirement = aid.requirements.filter(requirement => requirement.type === 'DEGREE_REQUIREMENT')[0]

    if (requirement) {
      let name = getFinancialAidByType(aid.aidGroup).name

      if (aid.aidGroup === "TAP") {
        description = requirement.info.explanation
      }

      if (requirement.rating === "MEDIUM") {
        requirement.rating = "DEFERRED_HIGH"
      }

      return (
        [
          name,
          requirement.targetValueFormatted,
          <RiskIcon risk={requirement.rating} small />
        ]
      )
    }

    return undefined;
  }).filter(data => data !== undefined)

  if (rows.length === 0) {
    return
  }
  return { title, rows, headers, descriptionHeader, description }
}