import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { RiskIcon } from '../common/risk-icon.component'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import { Section } from '../common/section.component'
import riskHeaderImage from '../../assets/images/decorative_header.svg'

const styles = theme => ({
  infoContainer: {
    borderTop: '1px solid #daddd8',
    margin: '0 -20px',
    padding: '0 20px',
  },
  infoSection: {
    padding: '8px 0'
  },
  headerContainer: {
    position: 'relative',
    left: '-20px',
    color: 'white',
    marginBottom: theme.spacing.unit
  },
  riskHeader: {
    position: 'absolute',
    left: '20px',
    top: '4px'
  }
})

export const AidDetailsPage = ({aidName, requirements}) =>
  <Section>
    <Typography variant="h4">
      {aidName} Eligibility Requirements
    </Typography>
    {requirements.map((requirement, idx) =>
      <RequirementSection key={idx} {...requirement} />
    )}
  </Section>

const RequirementSection = pipe(
  withStyles(styles)
)(({classes, targetValueFormatted, targetValueHeading, requiredValueFormatted, requiredValueHeading, rating, info}) =>
  <SubSection secondary marginBottom>
    <div className={classes.headerContainer}>
      <img src={riskHeaderImage} alt="" />
      <Typography variant='h5' color="inherit" className={classes.riskHeader}>
        {info.title}
      </Typography>
    </div>
    <Row>
      <TableCell heading>{targetValueHeading}</TableCell>
      <TableCell heading>{requiredValueHeading}</TableCell>
      <TableCell />
    </Row>
    <Row>
      <TableCell>{targetValueFormatted}</TableCell>
      <TableCell>{requiredValueFormatted} </TableCell>
      <TableCell center><RiskIcon risk={rating} large /></TableCell>
    </Row>
    <RequirementInfo info={info}/>
  </SubSection>
)

const RequirementInfo = pipe(
  withStyles(styles)
)(({classes, info: {explanation, impact, nextSteps}}) =>
  <div className={classes.infoContainer}>
    <div className={classes.infoSection}>
      <Typography variant="subtitle2" gutterBottom>
        WHAT DOES THIS MEAN?
      </Typography>
      <Typography variant="body2">
        {explanation}
      </Typography>
    </div>
    <div className={classes.infoSection}>
      <Typography variant="subtitle2" gutterBottom>
        HOW DOES IT AFFECT MY FINANCIAL AID?
      </Typography>
      <Typography variant="body2">
        {impact}
      </Typography>
    </div>
    <div className={classes.infoSection}>
      <Typography variant="subtitle2" gutterBottom>
        NEXT STEPS
      </Typography>
      <Typography variant="body2">
        {nextSteps}
      </Typography>
    </div>
  </div>
)