import React from 'react'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../config'
import { timeAgo } from '../../utils/timeAgo'
import { withStyles } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import Card from '@material-ui/core/Paper'
import green from '@material-ui/core/colors/green'
import Avatar from '@material-ui/core/Avatar'

const styles = theme => ({
  link: {
    textDecoration: 'none'
  },
  greenAvatar: {
    backgroundColor: green[500],
  }
})

const InstitutionAvatar = withStyles(styles)(({classes, configured}) => (
  <Avatar className={configured ? classes.greenAvatar : null}>
    {configured ? <Check /> : <Close /> }
  </Avatar>
))

const secondaryLine = rules =>
  rules.configured
    ? `Updated ${timeAgo(new Date(rules.updatedAt))} by ${rules.updatedBy.name}`
    : 'Not configured yet'

const InstitutionItem = withStyles(styles)(({classes, id, name, rules})=> (
  <Link to={`/admin/rules/${id}`} className={classes.link}>
    <ListItem button disableRipple disableTouchRipple>
      <InstitutionAvatar configured={rules.configured}/>
      <ListItemText primary={name} secondary={secondaryLine(rules)}/>
    </ListItem>
  </Link>
))

export class InstitutionsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {institutions: []}
  }

  componentDidMount() {
    fetch(apiUrl(`/api/admin/institutions`))
      .then(response => response.json())
      .then(({data}) => this.setState({institutions: data}))
      .catch(error => alert("Error occurred!"))
  }

  render() {
    return (
      <div>
        <Typography variant="h3">
          Select your Institution
        </Typography>
        <Card>
          <List component="nav" dense>
            {this.state.institutions.map(({name, id, rules}) =>
              <InstitutionItem key={id} id={id} name={name} rules={rules} />
            )}
          </List>
        </Card>
      </div>
    )
  }
}
