import { pipe } from 'ramda'
import * as React from 'react'
import { withApiOrLocalStorageData } from './withApiOrLocalStorageData'
import { withUser } from '../../providers/user.provider'
import { postData } from '../../utils/api-utils'
import { currentStep, next, prev, totalSteps } from './excelsior-routing'
import { withDataValidation } from '../registration/common/with-data-validation'

const ExcelsiorRegistrationDataContext = React.createContext({})

export const ExcelsiorRegistrationDataConsumer = ExcelsiorRegistrationDataContext.Consumer

const ExcelsiorRegistrationDataProvider = pipe(
  withApiOrLocalStorageData('/api/excelsior-microsite/profile', 'excelsiorRegistration'),
  withUser,
  withDataValidation({
    relation: {},
    enrolledCredits: {guard: ({enrolledCredits}) => parseInt(enrolledCredits, 10) <= 21, msg: 'Should be no more than 21'},
    earnedCredits: {},
    previousSemesterEarnedCredits: {guard: ({previousSemesterEarnedCredits}) => parseInt(previousSemesterEarnedCredits, 10) <= 21, msg: 'Should be no more than 21'},
    cumulativeGpa: {guard: ({cumulativeGpa}) => 0 <= parseFloat(cumulativeGpa) && parseFloat(cumulativeGpa) <= 4, msg: 'Required number between 0 - 4'},
    majorGpaRequirement: {}, degreeType: {}, institutionId: {}, excelsiorFirstReceived: {}, firstEnrolled: {}
  })
)(class extends React.Component {
  defaultState = {
    relation: undefined,
    enrolledCredits: undefined,
    earnedCredits: undefined,
    previousSemesterEarnedCredits: undefined,
    cumulativeGpa: undefined,
    majorGpaRequirement: undefined,
    degreeType: undefined,
    inOpportunityProgram: undefined,
    institutionId: undefined,
    excelsiorFirstReceived: undefined,
    firstEnrolled: undefined,
    checklist: {
      diplomaOrGed: false,
      nysResident: false,
      usCitizen: false,
      incomeLimit: false,
      loanDefaultStatus: false,
      workInNys: false,
      fafsaApplication: false,
      tapApplication: false,
    }
  }

  constructor(props) {
    super(props)
    const persistedState = {
      ...this.defaultState,
      ...this.props.data,
      email: localStorage.getItem('excelsiorUserEmail')
    }

    this.state = persistedState
  }

  handleNextClick = input => () => {
    if (this.props.validateField(this.state, input)) {
      this.persistStateToLocalStorage()
      this.props.history.push(next(this.props.location.pathname, this.state))
    }
  }
  handleBackClick = () => this.props.history.push(prev(this.props.location.pathname, this.state))

  handleSubmit = input => () => {
    if (this.props.validateField(this.state, input)) {
      this.persistStateToLocalStorage()
      postData(
        '/api/excelsior-microsite/registration',
        this.state,
        ({data}) => {
          if (!this.props.user.authenticated) {
            localStorage.setItem('excelsiorUserEmail', data.email)
          }
          localStorage.setItem('excelsiorRequirements', JSON.stringify(data))
          this.props.history.push('/excelsior-eligibility')
        }
      )
    }
  }

  persistStateToLocalStorage = () => localStorage.setItem('excelsiorRegistration', JSON.stringify(this.state))

  render() {
    return (
      <ExcelsiorRegistrationDataContext.Provider value={{
        data: this.state,
        errors: this.props.errors,
        handleChange: this.setState.bind(this),
        handleNextClick: this.handleNextClick,
        handleBackClick: this.handleBackClick,
        handleSubmit: this.handleSubmit,
        currentStep: currentStep(this.state, this.props.location.pathname),
        totalSteps: totalSteps(this.state)
      }}>
        {this.props.children}
      </ExcelsiorRegistrationDataContext.Provider>
    )
  }
})

export default ExcelsiorRegistrationDataProvider

export const withExcelsiorRegistrationData = WrappedComponent => props =>
  <ExcelsiorRegistrationDataConsumer>
    {value => <WrappedComponent {...value} {...props} />}
  </ExcelsiorRegistrationDataConsumer>
