import * as React from 'react'
import { apiUrl } from '../config'

const UserContext = React.createContext({})

export const UserConsumer = UserContext.Consumer

class UserProvider extends React.Component {
  constructor(props) {
    super(props)

    this.handleSuccessfulResponse = this.handleSuccessfulResponse.bind(this)
    this.handleSuccessfulResponseWithCallback = this.handleSuccessfulResponseWithCallback.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  state = {
    loaded: false,
    authenticated: false,
    id: null,
    name: null,
    email: null,
    avatarUrl: null,
    role: null,
    registrationStatus: null,
    affiliation: {},
    incompleteMilestone: {},
    semesterBreak: null,
    currentTerm: '202009',
    inactivityLogout: false,
    hasGoals: false,
    hasCourses: false,
    login: (email, password, onSuccess = () => {}, onFailure) => {
      fetch(apiUrl('/api/login'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'same-origin',
        body: `username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
      })
        .then(response => response.json().then(data => {
          if (response.status === 200) {
            this.handleSuccessfulResponseWithCallback(onSuccess)(data)
          } else {
            onFailure ? onFailure(data) : alert('Incorrect username or password')
          }
        }))
        .catch((err) => console.log(err) ||  onFailure ? onFailure() : alert('Incorrect username or password'))
    },
    googleLogin: (email, idToken) => {
      fetch(apiUrl('/api/google-login'), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + idToken
        },
        credentials: 'same-origin',
      })
        .then(response => response.json())
        .then(this.handleSuccessfulResponse)
        .catch(() => alert('Incorrect credentials'))
    },
    googleLoginForFakeEmail: (email, idToken) => {
      fetch(apiUrl('/api/excelsior-microsite/google-login'), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + idToken,
          'Fake-User-Email': localStorage.getItem('excelsiorUserEmail'),
        },
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then((body) => {
          this.handleSuccessfulResponse(body)
          localStorage.removeItem('excelsiorUserEmail')
        })
        .catch(() => alert('Account already exist. Please sign in to Future Grad'))
    },
    googleLoginClaimProfile: (email, idToken, studentId, program, onFailure) => {
      fetch(apiUrl('/api/cbo/google-login'), {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + idToken,
          'Cbo-Student-Id': studentId,
          'Program-Name': program,
        },
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then((body) => {
          if (body.success) {
            this.handleSuccessfulResponse(body)
          } else {
            onFailure(body)
          }
        })
        .catch(() => alert('Incorrect credentials.'))
    },
    logout: () => {
      fetch(apiUrl('/api/logout'), {credentials: 'same-origin'})
        .then(this.handleLogout)
        .catch(() => alert('Didn\'t work, sorry'))
    },
    idleLogout: () => {
      fetch(apiUrl('/api/logout'), {credentials: 'same-origin'})
        .then(() => this.handleLogout({inactivityLogout: true}))
        .catch(() => alert('Didn\'t work, sorry'))
    },
    setRegistrationStatus: (registrationStatus, callback) => this.setState({registrationStatus}, callback),
    updateUserData: (userData, callback) => this.setState(userData, callback),
    refreshUserData: this.refreshUserData.bind(this)
  }

  componentDidMount() {
    this.checkAuthenticationStatus()
  }

  handleSuccessfulResponseWithCallback(callback) {
    return body => this.setState(this.stateFromBody(body), callback)
  }

  handleSuccessfulResponse(body) {
    this.setState(this.stateFromBody(body))
  }

  stateFromBody(body) {
    return ({
      loaded: true,
      authenticated: true,
      id: body.data.id,
      name: body.data.name,
      email: body.data.email,
      avatarUrl: body.data.avatarUrl,
      role: body.data.role,
      registrationStatus: body.data.registrationStatus,
      affiliation: body.data.affiliation || {},
      incompleteMilestone: body.data.incompleteMilestone || {},
      currentTerm: body.data.currentTerm,
      semesterBreak: body.data.semesterBreak,
      hasGoals: body.data.hasGoals,
      hasCourses: body.data.hasCourses
    })
  }

  handleLogout(params) {
    this.setState({
      id: null,
      loaded: true,
      authenticated: false,
      name: null,
      email: null,
      avatarUrl: null,
      role: null,
      registrationStatus: null,
      affiliation: {},
      semesterBreak: null,
      hasGoals: null,
      hasCourses: null,
      ...params
    })
  }

  checkAuthenticationStatus() {
    fetch(apiUrl('/api/user/current'), {credentials: 'same-origin'})
      .then(response => response.json())
      .then(this.handleSuccessfulResponse)
      .catch(this.handleLogout)
  }

  refreshUserData(onSuccess) {
    fetch(apiUrl('/api/user/current'), {credentials: 'same-origin'})
      .then(response => response.json())
      .then(this.handleSuccessfulResponseWithCallback(onSuccess))
      .catch(this.handleLogout)
  }

  render() {
    return (
      <UserContext.Provider value={{...this.state}}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export default UserProvider

export const withUser = WrappedComponent => props =>
  <UserConsumer>
    {(user) =>
      <WrappedComponent {...props} user={user} />
    }
  </UserConsumer>