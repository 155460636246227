import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { RuleEditor } from './rule-editor.component'
import { RuleStep } from './rule-step.component'
import { Dropdown } from '../../registration/common/dropdown.component'
import {
  PACE_RULE_VALUE_TYPES, PERCENTAGE,
  GPA_RULE_TYPES,
  PACE_RULE_TYPES,
  SAP_EVALUATION_FREQUENCY_TYPES,
  DEGREE_TYPES,
  FULL_TIME_STATUSES,
} from '../rules'
import { InlineMenu } from './inline-menu.component';


const StudentType = ({degreeType, fullTimeStatus, onChange}) =>
  <RuleStep stepNumber={1} title="Who are these rules for?">
    <Typography variant="h6">
      Students pursuing&nbsp;
      <InlineMenu
        value={degreeType}
        onChange={(newValue) => onChange({degreeType: newValue})}
        options={DEGREE_TYPES}
      />
      &nbsp;degree with&nbsp;
      <InlineMenu
        value={fullTimeStatus}
        onChange={(newValue) => onChange({fullTimeStatus: newValue})}
        options={FULL_TIME_STATUSES}
      />
      &nbsp;status
    </Typography>
  </RuleStep>

const EvaluationFrequency = ({value, onChange}) =>
  <RuleStep stepNumber={2} title="How often is SAP evaluated?">
    <FormControl fullWidth>
      <Dropdown
        value={value}
        onChange={onChange}
        options={SAP_EVALUATION_FREQUENCY_TYPES}
      />
    </FormControl>
  </RuleStep>

const GpaRule = ({requirement, onChange}) =>
  <RuleEditor
    stepNumber={3}
    title="What is GPA requirement based on?"
    placeholder="Required Gpa"
    requirement={requirement}
    onChange={onChange}
    ruleTypes={GPA_RULE_TYPES}
    valueTypeComponent="Min GPA"
  />

const PaceRule = ({requirement, onChange}) =>
  <RuleEditor
    stepNumber={4}
    title="What is Pace requirement based on?"
    placeholder="Required Pace"
    requirement={requirement}
    onChange={onChange}
    ruleTypes={PACE_RULE_TYPES}
    valueTypes={PACE_RULE_VALUE_TYPES}
  />

class RulesEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      institutionId: undefined,
      data: this.props.data
    }
  }

  handleChangeStudentType = (changes) =>
    this.props.onChange({...this.props.data, ...changes})

  handleChangeSapEvaluationFrequency = event => 
    this.props.onChange({...this.props.data, evaluation: event.target.value})
  
  handleChangeGpaRequirement = gpa => 
    this.props.onChange({...this.props.data, gpa})

  handleChangePaceRequirement = pace =>
    this.props.onChange({...this.props.data, pace})

  render() {
    const {degreeType, fullTimeStatus, evaluation, gpa, pace} = this.props.data

    return (
      <div>
        <StudentType
          degreeType={degreeType}
          fullTimeStatus={fullTimeStatus} 
          onChange={this.handleChangeStudentType}
         />

        <EvaluationFrequency
          value={evaluation}
          onChange={this.handleChangeSapEvaluationFrequency}
        />
        <GpaRule
          requirement={gpa || {}}
          onChange={this.handleChangeGpaRequirement}
        />
        <PaceRule
          requirement={pace || {valueType: PERCENTAGE}}
          onChange={this.handleChangePaceRequirement}
        />
      </div>
    )
  }
}

export default RulesEditor
