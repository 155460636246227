import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import classnames from "classnames"

const styles = (theme) => ({
  riskIcon: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 29
  },
  largeSize: {
    fontSize: 34
  },
  smallSize: {
    fontSize: 22
  }
})

export const RiskIcon = pipe(
  withStyles(styles)
)(({classes, risk, large, small}) =>
  <div className={classnames(classes.riskIcon, {[classes.largeSize]: large, [classes.smallSize]: small})}>
    {getRiskIcon(risk)}
  </div>
)

const getRiskIcon = (risk) => {
  switch (risk) {
    case 'LOW': return <LowRiskIcon />
    case 'MEDIUM': return <LowRiskIcon />
    case 'DEFERRED_HIGH': return <MediumRiskIcon />
    case 'HIGH': return <HighRiskIcon />
    default: return <Typography variant='body1' component='div'>N/A</Typography>
  }
}

const LowRiskIcon = props =>
  <CheckCircle fontSize='inherit' nativeColor="#7cb8b6" {...props} />

const HighRiskIcon = props =>
  <CancelIcon fontSize='inherit' nativeColor="#fb7750" {...props} />

const MediumRiskIcon = props =>
  <ErrorIcon fontSize='inherit' nativeColor="#fab131" {...props} />