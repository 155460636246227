import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { Input } from '../../registration/common/input.component'

export const EarnedCredits = pipe(
  excelsiorRegistrationStep('earnedCredits', 'About your academic record'),
  withExcelsiorRegistrationData
)(({handleChange, data: {earnedCredits}, errors: {earnedCredits: error}}) =>
  <StepSection
    helpText="For most students to renew the Excelsior Scholarship, you need to be on track to graduate in your major within 2 years for an Associate’s program and 4 years for a Bachelor’s program. One of the factors they evaluate is if you have been earning at least 30 credits in your major each academic year. To calculate your progress in graduating on time, we need to know about your credits earned to date in your major."
    header="How many total credits have you earned in your major?"
  >
    <Input
      onChange={event => handleChange({earnedCredits: event.target.value})}
      placeholder='Ex 50'
      value={earnedCredits}
      error={error}
    />
  </StepSection>
)