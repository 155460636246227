import React from 'react'
import { pipe } from 'ramda'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  section: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 'auto -20px -20px -20px',
    padding: '20px 0',
    '@media (max-width: 600px)': {
      position: 'sticky',
      bottom: 0,
      '-webkit-box-shadow': '0px -2px 4px -4px rgba(0, 0, 0, 0.5)',
      '-moz-box-shadow': '0px -2px 4px -4px rgba(0, 0, 0, 0.5)',
      boxShadow: '0px -2px 4px -4px rgba(0, 0, 0, 0.5)'
    }
  },

})

export const ActionSection = pipe(
  withStyles(styles)
)(({classes, children}) =>
  <div className={classes.section}>
    {children}
  </div>
)