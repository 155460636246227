import React from "react"
import Typography from "@material-ui/core/Typography"
import { SubSection } from "../common/sub-section.component"
import { routes } from "../routing/routes-paths"
import { trackEvent } from "../../utils/intercom-analytics"
import { withRouter } from "react-router-dom"
import { Button } from "../common/button.component"
import { pipe } from "ramda"

export const ExploreCalculator = pipe(
  withRouter
)(class extends React.Component {
  handleClick = () => {
    trackEvent('updated-goal', {source: 'dashboard/use_eligibility_calculator'})
    this.props.history.push(routes.COURSES_GRADE_PICKER)
  }

  render () {
    return (
      <div>
        <SubSection secondary noPaddingBottom>
          <Typography variant='h5'>
            Explore eligibility calculator
          </Typography>
          <Typography variant="body2">
            See what grades you need to keep your financial aid and explore how withdrawals can affect your aid
          </Typography>
        </SubSection>

        <SubSection secondary marginBottom>
          <Button
            marginBottom
            onClick={this.handleClick}
            text="Use eligibility calculator"
          />
        </SubSection>
      </div>
    )
  }
})