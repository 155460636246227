import { pipe } from 'ramda'
import React from 'react'
import { withUser } from '../../providers/user.provider'
import { postDataWithHandler } from '../../utils/api-utils'
import { TextInput } from '../common/text-input.component'
import { withHelpModal } from '../help/with-help-modal'
import { withDataValidation } from '../registration/common/with-data-validation'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import { Header } from '../common/header.component'
import {ActionSection} from "../common/action-section.component"
import {Button} from "../common/button.component"
import {TextLink} from "../common/text-link.component"
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"
import { Section } from "../common/section.component"

export const CunyForgotPassword = pipe(
  withDataValidation({
    email: {
      guard: ({email}) => Boolean(email) === false || /.+@.+\.[A-Za-z]+$/.test(email),
      msg: "Please enter your valid email"
    }
  }),
  withUser,
  withHelpModal(SimpleHelpModal)
)(class extends React.Component {
  displayName = 'CunyForgotPassword'

  state = {email: ''}

  handleInputChange = key => event => this.setState({[key]: event.target.value})

  handleForgotPasswordClick = () => this.props.validate(this.state) && postDataWithHandler(
    '/api/user/forgot-password',
    {email: this.state.email},
    (status, {data}) => {
      switch (status) {
        case 200: {
          this.props.openModal("Reset password link sent!", "Check your email to reset your password.", "Got it")
          break
        }
        default: {
          this.props.openModal(...getErrorMessage(data.reason))
          break
        }
      }
    }
  )

  propsForTextInput = key => ({
    value: this.state[key],
    onChange: this.handleInputChange(key),
    error: this.props.errors[key]
  })

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <Header
          title="Forgot your password?"
          subtitle="Don't worry, we got you. Just give us your email."
        />

        <TextInput  label="Email" {...this.propsForTextInput('email')}/>

        <ActionSection>
          <Button text="Send new password" onClick={this.handleForgotPasswordClick} />
          <TextLink label="Log In" to={routes.CUNY_LOGIN} />
        </ActionSection>
      </Section>
    )
  }
})