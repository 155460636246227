import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Box } from "./custom-box.component"
import { RiskIcon } from "../common/risk-icon.component"
import listBullet from '../../assets/images/futuregrad_logomark_white.svg'
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"

const styles = theme => ({
  noBold: {
    fontWeight: 'normal'
  },
  marginRight: {
    marginRight: theme.spacing.unit
  },
  marginTop: {
    marginTop: theme.spacing.unit * 2
  },
  marginBottom: {
    marginBottom: theme.spacing.unit * 2
  },
  bullet: {
    width: 16,
    filter: 'brightness(0.15) sepia(1) hue-rotate(180deg) saturate(5)',
    marginRight: 4
  }
})

export const WhyUse = pipe(
  withStyles(styles)
)(({classes}) => (
  <Box color="grey" paddingTop paddingBottom>
    <Typography variant="subtitle1" gutterBottom>
      <i><b>Why use the financial aid snapshot?</b></i>
    </Typography>

    <Grid container spacing={8}>
      <Grid item><img src={listBullet} alt='' className={classes.bullet} /></Grid>
      <Grid item xs>
        <Typography variant="subtitle2" className={classes.noBold}>
          Know the academic renewal requirements for TAP, Pell and the Excelsior Scholarship
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item><img src={listBullet} alt='' className={classes.bullet} /></Grid>
      <Grid item xs>
        <Typography variant="subtitle2" className={classes.noBold}>
          Know which financial aid you may be at risk of losing
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={8}>
      <Grid item><img src={listBullet} alt='' className={classes.bullet} /></Grid>
      <Grid item xs>
        <Typography variant="subtitle2" className={classes.noBold}>
          Get a targeted action plan to ensure that you keep financial aid
        </Typography>
      </Grid>
    </Grid>

    <Typography variant="subtitle1" gutterBottom className={classes.marginTop}>
      <i><b>Risk Level</b></i>
    </Typography>

    <Grid container spacing={8} alignItems="center">
      <Grid item>
        <RiskIcon risk="LOW" small />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle2" style={{color: "#7cb8b6"}}><b>YOU ARE ON TRACK</b></Typography>
      </Grid>
    </Grid>
    <Grid container spacing={8} alignItems="center">
      <Grid item>
        <RiskIcon risk="DEFERRED_HIGH" small />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle2" style={{color: "#fab131"}}><b>AT RISK</b></Typography>
      </Grid>
    </Grid>
    <Grid container spacing={8} alignItems="center">
      <Grid item>
        <RiskIcon risk="HIGH" small />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle2" style={{color: "#fb7750"}}><b>WILL LOSE AID</b></Typography>
      </Grid>
    </Grid>
  </Box>
))