import React from 'react'
import { withApiData } from '../common/with-api-data'

export const withApiOrLocalStorageData = (path, localStorageKey) => WrappedComponent => {
  const ComponentWithApiData = withApiData(path)(WrappedComponent)
  return (props) => {
    if (props.user.authenticated) {
      return <ComponentWithApiData {...props}/>
    } else {
      return <WrappedComponent {...props} data={JSON.parse(localStorage.getItem(localStorageKey))}/>
    }
  }
}
