import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MaterialTable from 'material-table'
import { pipe } from 'ramda'
import React from 'react'
import { withApiData } from '../common/with-api-data'

const styles = theme => ({
  paper: {
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})

export const Students = pipe(
  withApiData('/api/admin/cuny/students'),
  withStyles(styles)
)(({classes, data}) =>
  <div>
    <Paper className={classes.paper}>
      <Typography variant='body1'>
        Total accounts: {data.totalAccounts}
      </Typography>
      <Typography variant='body1'>
        Registered accounts: {data.registeredAccounts}
      </Typography>
    </Paper>
    <MaterialTable
      title='Students'
      columns={[
       {title: 'First Name', field: 'firstName'},
       {title: 'Last Name', field: 'lastName'},
       {title: 'Email', field: 'email'},
       {title: 'Account Status', field: 'accountStatus'},
       {title: 'Last Seen', field: 'lastSeen'},
      ]}
      data={data.users}
      options={{
       filtering: true,
       pageSize: 10
      }}
    />
  </div>
)