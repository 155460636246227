import React from 'react'
import { HelpModal } from '../help/help-modal.component'

export const WithdrawalPastDeadlineAlert = ({modalRef}) =>
  <HelpModal
    innerRef={modalRef}
    actionLabel="Got it!"
    title='Withdrawal deadline passed'
  >
    Cannot withdraw after November 5
  </HelpModal>
