import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import { withStyles } from "@material-ui/core"

const styles = theme => ({
  header: {
    marginBottom: 18
  }
})

export const AcademicPrediction = pipe(
  withStyles(styles)
)(({classes, academicPrediction: {semesterGpa, cumulativeGpa, semesterEarnedCredits, cumulativeEarnedCredits}}) =>
  <SubSection dataTour="academic_prediction" secondary marginBottom>
    <Typography variant='h5' className={classes.header}>
      By end of fall'21 semester
    </Typography>
    <Row>
      <TableCell heading/>
      <TableCell heading center>SEMESTER</TableCell>
      <TableCell heading center>CUMULATIVE</TableCell>
    </Row>
    <AcademicPredictionRow
      title="GPA"
      semesterData={semesterGpa}
      cumulativeData={cumulativeGpa}
    />
    <AcademicPredictionRow
      title="Earned Credits"
      semesterData={semesterEarnedCredits}
      cumulativeData={cumulativeEarnedCredits}
    />
  </SubSection>
)

const AcademicPredictionRow = ({classes, title, semesterData, cumulativeData}) =>
  <Row>
    <TableCell>{title}</TableCell>
    <TableCell center>{semesterData}</TableCell>
    <TableCell center>{cumulativeData}</TableCell>
  </Row>
