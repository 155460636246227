import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { EXCELSIOR_PAGES } from './excelsior-routing'
import ExcelsiorRegistrationDataProvider from './excelsior-registration-data.provider'
import { routes } from "../routing/routes-paths"

export class ExcelsiorRegistration extends React.Component {

  constructor(props) {
    super(props)

    const userEmail = localStorage.getItem('excelsiorUserEmail')
    const pathname = this.props.location.pathname

    if (pathname === '/excelsior-registration') {
      const redirectPath = userEmail ? routes.EXCELSIOR_ELIGIBILITY : '/excelsior-registration/relation'
      this.props.history.push(redirectPath)
    }
  }

  render() {
    const {history, location} = this.props
    return (
      <ExcelsiorRegistrationDataProvider history={history} location={location}>
        <Switch>
          {EXCELSIOR_PAGES.map(({path, component}) =>
            <Route key={path} path={path} component={component}/>
          )}
        </Switch>
      </ExcelsiorRegistrationDataProvider>
    )
  }
}
