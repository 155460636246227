import React from 'react'
import { Spinner } from './spinner.component'
import { fetchData } from '../../utils/api-utils'
import { isFunction } from 'ramda-adjunct'
import URLSearchParams from '@ungap/url-search-params'

export const withApiData = (path, params, key) => (WrappedComponent) => {
  class ComponentWithApiData extends React.Component {
    state = {data: null, error: null}

    componentDidMount() {
      this.getData()
    }

    getData = () => {
      const apiPath = isFunction(path) ? path(this.props) : path
      const apiParams = isFunction(params) ? params(this.props) : params
      fetchData(apiPath + '?' + new URLSearchParams(apiParams).toString(),
        ({data}) => this.setState({data}),
        error => this.setState({error})
      )
    }

    render() {
      if (this.state.error) throw this.state.error
      if (this.state.data === null) return <Spinner/>

      const data = {[key || 'data']: this.state.data}

      return <WrappedComponent {...data} {...this.props} refreshData={this.getData} />
    }
  }

  return ComponentWithApiData
}