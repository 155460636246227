import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { withInstitution } from '../institution.provider'
import { withTracking } from '../../common/trackClicks'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:focus': {
      textDecoration: 'none',
      outlineColor: theme.palette.primary.main
    }
  }
})

export const SisLink = pipe(
  withInstitution,
  withStyles(styles),
  withTracking({category: CATEGORIES.onboarding, action: ACTIONS.openExternal, label: 'SIS'})
)(({classes, text, institution: {sisUrl, sisName}, track}) =>
  <a href={sisUrl} className={classes.link} target="_blank" onClick={track()} rel="noopener noreferrer">
    {text ? `${text} ${sisName}` : sisName}
  </a>
)