import { indexOf, sortBy, pipe, prop } from 'ramda'
import { AnnualEarnedCreditsHelp } from '../semester-break/help/annual-earned-credits-help.component'
import { CompletedCreditsHelp } from '../semester-break/help/completed-credits-help.component'
import { CumulativeEarnedCreditsHelp } from '../semester-break/help/cumulative-earned-credits-help.component'

export const REQUIREMENT_ORDER = [
  "ENROLLED_CREDITS", "GPA", "CUMULATIVE_EARNED_CREDITS", "ANNUAL_EARNED_CREDITS", "AVERAGE_EARNED_CREDITS", "COMPLETED_CREDITS", "DEGREE_REQUIREMENT", "PAYMENTS_LEFT", "PELL_PAYMENTS_LEFT", "STAFFORD_PAYMENTS_LEFT", "MAXIMUM_TIMEFRAME_LIMIT"
]

export const sortByRequirementType = sortBy(({type}) => indexOf(type, REQUIREMENT_ORDER))

const findFinancialAidByType = aidType => aidList =>
  aidList.find(({aidGroup}) => aidGroup.toLowerCase() === aidType.toLowerCase())

export const sortedRequirementsForAidType = (aidType, aidList) => pipe(
  findFinancialAidByType(aidType),
  prop('requirements'),
  sortByRequirementType
)(aidList)


export const EXCELSIOR_REQUIREMENT_LABELS = {
  GPA: 'Minimum GPA for degree program',
  AVERAGE_EARNED_CREDITS: 'On-time completion',
  ANNUAL_EARNED_CREDITS: 'On-time completion',
}

export const REQUIREMENT_LABELS = {
  GPA: 'Minimum GPA',
  CUMULATIVE_EARNED_CREDITS: 'Earned credits',
  COMPLETED_CREDITS: 'Completed credits',
  ANNUAL_EARNED_CREDITS: 'Annual earned credits',
  PAYMENTS_LEFT: 'Payments remaining',
  PELL_PAYMENTS_LEFT: 'Pell payments remaining',
  STAFFORD_PAYMENTS_LEFT: 'Stafford Payments remaining',
  DEGREE_REQUIREMENT: 'Have you declared a major?',
  MAXIMUM_TIMEFRAME_LIMIT: 'Approaching maximum timeframe limit',
  AVERAGE_EARNED_CREDITS: 'On track to graduate'
}

export const EXCELSIOR_EDIT_LINK = {
  GPA: 'excelsior-registration/major-gpa-requirement',
  ANNUAL_EARNED_CREDITS: '/excelsior-registration/enrolled-credits',
  AVERAGE_EARNED_CREDITS: '/excelsior-registration/earned-credits'
}

export const requirementLabel = (type, withAsterisk) => `${REQUIREMENT_LABELS[type]}${withAsterisk ? '*' : ''}`

export const termLabel = (termCode) => {
  switch (termCode.slice(-2)) {
    case '01': return 'Winter'
    case '02': return 'Spring'
    case '06':
    case '07':
    case '08': return 'Summer'
    case '09': return 'Fall'
    default: return null
  }
}

export const REQUIREMENT_HELP = {
  GPA: undefined,
  CUMULATIVE_EARNED_CREDITS: CumulativeEarnedCreditsHelp,
  COMPLETED_CREDITS: CompletedCreditsHelp,
  ANNUAL_EARNED_CREDITS: AnnualEarnedCreditsHelp,
  PAYMENTS_LEFT: undefined,
  DEGREE_REQUIREMENT: undefined
}
