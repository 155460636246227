import React from "react"
import { pipe } from "ramda"
import { Typography, withStyles } from "@material-ui/core"
import { CustomTable } from "./custom-table.component"
import {
  completedCreditsRequirements,
  degreeRequirement,
  earnedCreditsRequirements,
  gpaRequirements,
  maximumTimeframeRequirement,
  paymentsLeftRequirement
} from "./eligibility-data"


const styles = theme => ({
  noBold: {
    fontWeight: 'normal'
  },
  explanation: {
    fontWeight: 'normal',
    fontSize: 10
  },
})

export const EligibilityResults = pipe(
  withStyles(styles)
)(({classes, data, studentData}) => {
  const gpa = gpaRequirements(data.financialAid)
  const earnedCredits = earnedCreditsRequirements(data.financialAid)
  const completedCredits = completedCreditsRequirements(data.financialAid)
  const paymentsLeft = paymentsLeftRequirement(data.financialAid, studentData)
  const maximumTimeframe = maximumTimeframeRequirement(data.financialAid)
  const degree = degreeRequirement(data.financialAid)

  const hasExcelsior = data.financialAid.filter(aid => aid.aidGroup === "EXCELSIOR").length > 0

  return (
    <>
      {gpa && (
        <CustomTable {...gpa} />
      )}
      {earnedCredits && (
        <CustomTable {...earnedCredits}>
          {hasExcelsior && (
            <Typography variant="subtitle2" className={classes.explanation}>
              * If Fall = first term, need to meet requirement by end of Summer; if Spring = first term, end of Fall/Winter term
            </Typography>
          )}
        </CustomTable>
      )}
      {completedCredits && (
        <CustomTable {...completedCredits} />
      )}
      {paymentsLeft && (
        <CustomTable {...paymentsLeft} />
      )}
      {maximumTimeframe && (
        <CustomTable {...maximumTimeframe} />
      )}
      {degree && (
        <CustomTable {...degree} />
      )}
    </>
  )
})