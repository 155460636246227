import { pipe } from 'ramda'
import React from 'react'
import { withUser } from '../../providers/user.provider'
import ReactIdleTimer from 'react-idle-timer'

export const IdleTimer = pipe(
  withUser
)(class extends React.Component {
  displayName = 'IdleTimer'

  onIdle = () => this.props.user.idleLogout()

  render() {
    if (!this.props.user.authenticated) return null

    return (
      <ReactIdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onIdle={this.onIdle}
        timeout={1000 * 60 * 60 * 2}/>
    )
  }
})