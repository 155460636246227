import { Typography, withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { withTracking } from '../../common/trackClicks'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'

const styles = theme => ({
  marginBottom: {
    marginBottom: `${theme.spacing.unit * 3}px`
  }
})

export const StepSection = pipe(
  withStyles(styles),
  withTracking(props => ({category: CATEGORIES.onboarding, action: ACTIONS.openHelp, label: props.trackingLabel}))
)(
  ({classes, header, helpText, children}) =>
    <div className={classes.marginBottom}>
      {helpText && <Typography variant="body2" gutterBottom className={classes.marginBottom}>
        {helpText}
      </Typography>}
      <Typography variant="body2" gutterBottom className="bold">
        {header}
      </Typography>
      {children}
    </div>
)
