import React from 'react'
import { HelpModal } from '../../help/help-modal.component'

export const TotalAmountLoanHelp = ({modalRef}) =>
  <HelpModal innerRef={modalRef} title='Federal aid limits the total amount you can borrow'>
    By providing this information, we'll let you know how many payments you have left. Here's where to find it in the MyStudentData document
    <br/><br/>
    1. Find the total unsubsidized loan amount under "Total Direct Stafford Unsubsidized Outstanding Principal"
    <br/><br/>
    2. Find the total subsidized loan amount under "Total Direct Stafford Subsidized Outstanding Principal"
  </HelpModal>