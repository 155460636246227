import { Typography, withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import classnames from 'classnames'
import React from 'react'
import { withOptionalHelpModal } from '../help/with-help-modal'
import { HelpButton } from '../help/help-button.component'
import { HelpLinkSmall } from '../help/help-link-small.component'
import { withTracking } from './trackClicks'
import { ACTIONS, CATEGORIES } from '../../utils/google-analytics'

const styles = theme => ({
  section: {
    width: '100%'
  },
  marginBottom: {
    marginBottom: `${theme.spacing.unit * 3}px`
  },
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 'auto'
  }
})

export const Section = pipe(
  withStyles(styles),
  withOptionalHelpModal((props) => props.helpModal),
  withTracking(props => ({category: CATEGORIES.onboarding, action: ACTIONS.openHelp, label: props.trackingLabel}))
)(
  ({classes, openModal, header, helpText, helpButtonText, track, children, noMarginBottom, fullHeight}) =>
    <div className={classnames(classes.section, {[classes.marginBottom]: !noMarginBottom, [classes.fullHeight]: fullHeight})}>
      <Typography variant="body2" gutterBottom>
        {header} {helpText && <HelpLinkSmall text={helpText} onClick={track(openModal)}/>}
      </Typography>
      {helpButtonText && <HelpButton text={helpButtonText} onClick={track(openModal)}/>}
      {children}
    </div>
)
