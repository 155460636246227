import { includes } from 'ramda'
import React from 'react'
import { SwitchButtonGrid } from '../common/switch-button-grid'
import { SwitchButton } from '../common/switch-button.component'
import { getFinancialAidByType } from './financial-aid-types'

export const AidSelector = ({options, financialAid, handleAidSelection}) =>
  <SwitchButtonGrid>
    {options.map((aid) =>
      <SwitchButton
        key={aid}
        active={includes(aid, financialAid)}
        label={getFinancialAidByType(aid).name}
        onClick={handleAidSelection(aid)}/>
    )}
  </SwitchButtonGrid>
