import { pipe } from 'ramda'
import React from 'react'
import { ExcelsiorHelp } from '../help/excelsior-help.component'
import { Section } from '../../common/section.component'
import { withAidSelection } from './with-aid-selection'
import { RegistrationStep } from '../common/registration-step.component'
import { YesNoButtons } from '../common/yes-no-buttons.component'

export const Excelsior = pipe(
  withAidSelection
)(
  ({handleNextClick, handleBackClick, financialAid, handleAidSelection}) =>
    <RegistrationStep
      stepTitle="Financial Aid Type (3/5)"
      title="The Excelsior scholarship"
      subTitle="You could be eligible for the Excelsior scholarship if you have not applied previously"
      handleNextClick={handleNextClick}
      handleBackClick={handleBackClick}
    >
      <Section
        header="Are you planning to apply for the Excelsior scholarship?"
        helpText="What is this?"
        helpModal={ExcelsiorHelp}
        trackingLabel="What is Excelsior"
      >
        <YesNoButtons
          value={financialAid.length > 0}
          handleNoClick={handleAidSelection('EXCELSIOR')}
          handleYesClick={handleAidSelection('EXCELSIOR')}
        />
      </Section>
    </RegistrationStep>
)