import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  }
})

export const CheckboxWithLabel = pipe(
  withStyles(styles)
)(({classes, checked, onChange, label}) =>
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        onChange={onChange}
        color="secondary"
        className={classes.checkbox}
      />
    }
    label={label}
  />
)
