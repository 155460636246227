import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { pipe } from 'ramda'
import React from 'react'
import { withItemActionDialog } from './with-item-action-dialog'
import classNames from 'classnames'
import Slide from '@material-ui/core/Slide'
import SvgIcon from '@material-ui/core/SvgIcon'

const styles = theme => ({
  expansionPanel: {
    margin: '2px -20px',
    '&:before': {
      opacity: 0
    }
  },
  expansionPanelSummary: {
    padding: 0,
    backgroundColor: theme.palette.background.grey
  },
  next: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  disabled: {
    color: theme.palette.grey[500]
  },
  icon: {
    fontSize: 30
  }
})

export const Item = pipe(
  withStyles(styles),
  withItemActionDialog
)(
  class extends React.Component {
    state = {expanded: false}

    handlePanelChange = (event) => event.target.type !== 'checkbox' && this.setState({expanded: !this.state.expanded})
    handleCheckboxChange = () => this.props.openDialog()

    render() {
      const {classes, next, date, title, action, details, checked, active} = this.props
      const formattedDate = new Date(date).toLocaleDateString('en-US')
      return (
        <Slide in={true} direction='right'>
          <ExpansionPanel expanded={this.state.expanded} onChange={this.handlePanelChange}
                          className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon fontSize="large"/>}
                                   className={classNames({
                                     [classes.next]: next,
                                     [classes.expansionPanelSummary]: true
                                   })}>
              <Checkbox disabled={!active} checked={checked} onChange={this.handleCheckboxChange}
                        icon={<EmptyIcon fontSize="large" disabled={!active} border={!next} />}
                        checkedIcon={<CheckCircleIcon fontSize="large"/>}
                        />
              <div className={classNames({[classes.title]: true, [classes.disabled]: !active && !next})}>
                <Typography color="inherit" className="bolder">{formattedDate} - {title}</Typography>
                <Typography color="inherit" variant="subtitle1">{action}</Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant="body2">{details}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Slide>
      )
    }
  }
)

const checkboxStyles = theme => ({
  border: {
    stroke: theme.palette.grey[400],
    strokeWidth: 1,
    fill: 'none'
  },
  background: {
    fill: theme.palette.common.white
  },
  disabled: {
    fill: theme.palette.grey[300]
  }
})

export const EmptyIcon = withStyles(checkboxStyles)(({classes, disabled, border = true, ...props}) =>
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z"/>
    <g>
      <path className={classNames({
        [classes.background]: !disabled,
        [classes.disabled]: disabled,
      })} d="M 12 12 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0"/>
      {border && <path className={classes.border} d="M 12 12 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0"/>}
    </g>
  </SvgIcon>
)
