import React from 'react'
import { HelpModal } from '../../help/help-modal.component'
import myStudentDataLogo from '../../../assets/images/my-student-data.gif'
import { pipe } from 'ramda'
import { withTracking } from '../../common/trackClicks'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'

export const NationalStudentLoanDatabaseHelp = pipe(
  withTracking({category: CATEGORIES.onboarding, action: ACTIONS.openExternal, label: 'National Student Loan Data System'})
)(({modalRef, track}) =>
  <HelpModal innerRef={modalRef} title='How to download your financial aid record'>
    1. Go to the <a href="https://nslds.ed.gov/nslds/nslds_SA" target="_blank" rel="noopener noreferrer" onClick={track()}>Federal
    Financial Aid website</a>. Scroll to the bottom of the page and click on "MyStudentData Download"
    <img src={myStudentDataLogo} alt="My Student Data Logo"/>
    <br/><br/>
    2. Log in using your username (or verified email address) & password.
    <br/><br/>
    3. Click on "Confirm" to save/download the file to your computer
  </HelpModal>
)