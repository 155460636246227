import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import * as classnames from 'classnames'
import { pipe } from 'ramda'
import React from 'react'

const styles = (theme) => ({
  column: {
    width: '25%',
    '&:first-child': {
      width: '50%'
    },
    // height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  center: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  error: {
    color: theme.palette.error.main
  },
  heading: {
    textTransform: 'uppercase'
  }
})

export const TableCell = pipe(
  withStyles(styles)
)(({classes, center, heading, children, bold, error}) =>
  <Typography
    variant={heading ? "subtitle2" : 'body2'}
    component="div"
    color="inherit"
    className={classnames(classes.column, {
      [classes.center]: center,
      'bold': bold,
      [classes.error]: error,
      [classes.heading]: heading,
    })}
  >
    {children}
  </Typography>
)