import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { Input } from '../../registration/common/input.component'

export const PreviousSemesterEarnedCredits = pipe(
  excelsiorRegistrationStep('previousSemesterEarnedCredits', 'About your academic record'),
  withExcelsiorRegistrationData
)(({handleChange, data: {previousSemesterEarnedCredits}, errors: {previousSemesterEarnedCredits: error}}) =>
  <StepSection
    helpText="For most students to renew the Excelsior Scholarship, you need to be on track to graduate in your major within 2 years for an Associate’s program and 4 years for a Bachelor’s program. One of the factors they evaluate is if you are on track to earn at least 30 credits in your major each academic year. To calculate your status in earning 30 credits this academic year, we need to know about your credits you earned last semester in your major."
    header="How many total credits did you earn last semester in your major?"
  >
    <Input
      onChange={event => handleChange({previousSemesterEarnedCredits: event.target.value})}
      placeholder='Ex 15'
      value={previousSemesterEarnedCredits}
      error={error}
    />
  </StepSection>
)