import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { GoogleLogin } from 'react-google-login'
import { Redirect } from 'react-router-dom'
import { UserConsumer } from '../../providers/user.provider'
import { googleClientId } from '../../config'
import { routes } from "../routing/routes-paths"

const success = (response, callback) => {
  const email = response && response.profileObj && response.profileObj.email
  const idToken = response && response.tokenId

  callback(email, idToken)
}

const failure = (response) => {
  console.log(response)
}

const styles = theme => ({
  googleLogin: {
    marginTop: theme.spacing.unit * 5,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: 'white !important',
    fontFamily: 'inherit !important'
  }
})

const simpleGoogleSignInStyles = theme => ({
  link: {
    cursor: 'pointer'
  }
})

export const SimpleGoogleSignInForm = withStyles(simpleGoogleSignInStyles)(({classes, onLoginSuccess, color}) =>
  <GoogleLogin
    clientId={googleClientId}
    render={renderProps => (
      <Typography className={classes.link} variant="body2" onClick={renderProps.onClick} color={color || "primary"}>
        Have an account? Sign In with Google
      </Typography>
    )}
    onSuccess={response => success(response, onLoginSuccess)}
    onFailure={failure}
  />
)

const GoogleSignInForm = pipe(
  withStyles(styles)
)(({classes, onLoginSuccess}) =>
  <div>
    <Typography variant="h3">
      Now let's get you on track
    </Typography>

    <Typography variant="body1" gutterBottom>
      Sign in to review the requirements to renew your financial aid and upcoming deadlines
    </Typography>

    <GoogleLogin
      clientId={googleClientId}
      buttonText="Sign in with Google"
      className={classes.googleLogin}
      onSuccess={response => success(response, onLoginSuccess)}
      onFailure={failure}
    />
  </div>
)

const redirectTo = (locationState) => {
  const loginPath = routes.GOOGLE_SIGN_IN
  const defaultPath = routes.ROOT
  const fromPath = (locationState && locationState.from && locationState.from.pathname) || defaultPath

  return fromPath !== loginPath ? fromPath : defaultPath
}

export const GoogleSignIn = props => (
  <UserConsumer>
    {({googleLogin, authenticated}) => {
      const from = redirectTo(props.location.state)
      if (authenticated) {
        return <Redirect to={from}/>
      } else {
        return <GoogleSignInForm onLoginSuccess={googleLogin} {...props} />
      }
    }}
  </UserConsumer>
)

export const SimpleGoogleSignIn = props => (
  <UserConsumer>
    {({googleLogin, authenticated}) => {
      if (authenticated) {
        return <Redirect to={routes.ROOT} />
      } else {
        return <SimpleGoogleSignInForm onLoginSuccess={googleLogin} {...props} />
      }
    }}
  </UserConsumer>
)
