import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
    gridGap: `${theme.spacing.unit}px`,
  }
})
export const SwitchButtonGrid = pipe(
  withStyles(styles)
)(({classes, children}) =>
  <div className={classes.grid}>
    {children}
  </div>
)
