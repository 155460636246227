import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import * as zxcvbn from 'zxcvbn'
import classNames from 'classnames'

const styles = theme => ({
  passwordStrengthMeter: {
    alignItems: 'center',
  },
  progress: {
    '-webkit-appearance': 'none',
    appearance: 'none',
    height: theme.spacing.unit,
    width: '60%',
    '&::-webkit-progress-bar': {
      backgroundColor: '#eee',
      borderRadius: theme.spacing.unit * 0.5
    },
    '&::-webkit-progress-value': {
      borderRadius: theme.spacing.unit * 0.5,
      backgroundColor: theme.palette.primary.main
    }
  },
  weak: {
    '&::-webkit-progress-value': {
      backgroundColor: 'red'
    }
  }
})

const passwordStrengthLabel = (score) => {
  switch (score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      return 'Weak';
  }
}

export const PasswordStrengthMeter = pipe(
  withStyles(styles)
)(({classes, password}) => {
  if (!password) return null
  const passwordStrength = zxcvbn(password).score

  return (
    <div className={classes.passwordStrengthMeter}>
      <progress
        value={passwordStrength}
        max="4"
        className={classNames(classes.progress)}
      />
      <br />
      <label className="password-strength-meter-label">
        <Typography variant="subtitle1">Password strength: {passwordStrengthLabel(passwordStrength)}</Typography>
      </label>
    </div>
  )
})
