import React from 'react'
import { nonNegative } from '../common/validators'
import { withDataValidation } from '../common/with-data-validation'
import { NationalStudentLoanDatabaseHelp } from '../help/national-student-loan-database-help.component'
import { StaffordDetails } from './stafford-details.component'
import { PellDetails } from './pell-details.component'
import { withHelpModal } from '../../help/with-help-modal'
import { HelpLink } from '../../help/help-link.component'
import { RegistrationStep } from '../common/registration-step.component'
import { filter, pipe } from 'ramda'
import { withTracking } from '../../common/trackClicks'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'

export const Federal = pipe(
  withHelpModal(NationalStudentLoanDatabaseHelp),
  withDataValidation({
    lifetimeEligibilityUsed: {
      guard: ({lifetimeEligibilityUsed}) => (lifetimeEligibilityUsed === undefined) ||
        (0 <= parseInt(lifetimeEligibilityUsed, 10) && parseInt(lifetimeEligibilityUsed, 10) <= 600),
      msg: 'Should be between 0% and 600%'
    },
    financialDependencyStatus: {},
    subsidizedBorrowedTotal: nonNegative('subsidizedBorrowedTotal'),
    unsubsidizedBorrowedTotal: nonNegative('unsubsidizedBorrowedTotal'),
    borrowedThisSemester: nonNegative('borrowedThisSemester')
  }),
  withTracking({category: CATEGORIES.onboarding, action: ACTIONS.openHelp, label: 'National Student Load Data System'})
)(
  class extends React.Component {
    constructor(props) {
      super(props)

      const details = this.props.details
      if (details.STAFFORD && details.STAFFORD.financialDependencyStatus === null) {
        details.STAFFORD.financialDependencyStatus = 'DEPENDENT'
      }
      this.state = {...details}
    }

    handleInputChange = (aidType, inputType) => event =>
      this.setState({[aidType]: { ...this.state[aidType], [inputType]: event.target.value}})

    handleFinancialDependencyChange = value => () => this.setState({
      STAFFORD: {
        ...this.state.STAFFORD,
        financialDependencyStatus: value
      }
    })

    updatedDetails = () => filter(({details}) => details, [
      {type: 'PELL', details: this.state.PELL},
      {type: 'STAFFORD', details: this.state.STAFFORD}
    ])

    handleNextClick = () => this.props.validate({...this.state.PELL, ...this.state.STAFFORD}) && this.props.handleAidDetails(this.updatedDetails(), true)
    handleBackClick = () => this.props.handleAidDetails(this.updatedDetails(), false)

    render() {
      const {openModal, track} = this.props
      return (
        <RegistrationStep
          stepTitle="Financial Aid Details (4/5)"
          title="About your federal grants and loans"
          subTitle={<>This information can be found in the <HelpLink text="National Student Loan Database"
                                                                       onClick={track(openModal)}/></>}
          handleNextClick={this.handleNextClick}
          handleBackClick={this.handleBackClick}
        >
          {this.state.PELL && <PellDetails
            lifetimeEligibilityUsed={this.state.PELL.lifetimeEligibilityUsed}
            handleLifetimeEligibilityUsedChange={this.handleInputChange('PELL', 'lifetimeEligibilityUsed')}
            errors={this.props.errors}
          />}
          {this.state.STAFFORD && <StaffordDetails
            financialDependencyStatus={this.state.STAFFORD.financialDependencyStatus}
            handleFinancialDependencyChange={this.handleFinancialDependencyChange}
            subsidizedBorrowedTotal={this.state.STAFFORD.subsidizedBorrowedTotal}
            handleSubsidizedBorrowedTotalChange={this.handleInputChange('STAFFORD', 'subsidizedBorrowedTotal')}
            unsubsidizedBorrowedTotal={this.state.STAFFORD.unsubsidizedBorrowedTotal}
            handleUnsubsidizedBorrowedTotalChange={this.handleInputChange('STAFFORD', 'unsubsidizedBorrowedTotal')}
            borrowedThisSemester={this.state.STAFFORD.borrowedThisSemester}
            handleBorrowedThisSemesterChange={this.handleInputChange('STAFFORD', 'borrowedThisSemester')}
            errors={this.props.errors}
          />}
        </RegistrationStep>
      )
    }
  }
)