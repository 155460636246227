import React, { useEffect, useState } from "react"
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Section } from "../common/section.component"
import { EligibilityResults } from "./eligibility-results.component"
import { Alert } from "./alert.component"
import { NextSteps } from "./next-steps.component"
import futureGradLogotypeColor from '../../assets/images/futuregrad_logotype_color.svg'
import loadingAnimation from "../../assets/images/loading-animation.gif"
import { fetchData } from "../../utils/api-utils"
import { StudentDetails } from "./student-details.component"
import { routes } from "../routing/routes-paths"
import { Link } from "react-router-dom"
import { programNames } from "./utils"

const styles = theme => ({
  loading: {
    width: '70%',
    maxWidth: 280,
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto ${theme.spacing.unit * 5}px auto`
  },
  page: {
    maxWidth: 1080,
    padding: `0 ${theme.spacing.unit * 2}px`,
    "@media print": {
      padding: 0
    }
  },
  leftColumn: {
    "@media print": {
      flexGrow: 0,
      maxWidth: "28%",
      flexBasis: "28%"
    }
  },
  rightColumn: {
    "@media print": {
      flexGrow: 0,
      maxWidth: "72%",
      flexBasis: "72%"
    }
  },
  logotype: {
    height: 28,
    width: '100%'
  },
  title: {
    fontSize: 24,
    lineHeight: "24px"
  },
  noBold: {
    fontWeight: 'normal'
  },
  noMargin: {
    margin: 0
  },
  marginRight: {
    marginRight: theme.spacing.unit * 2
  },
  link: {
    color: "inherit"
  },
  fullHeight: {
    height: "100%"
  },
  bullet: {
    fontSize: 18,
    marginRight: 8
  },
  divider: {
    marginBlockStart: 4,
    marginBlockEnd: 4
  }
})

export const EligibilityPage = pipe(
  withStyles(styles)
)(({classes, match}) => {
  const [data, setData] = useState(undefined);
  const [studentData, setStudentData] = useState(undefined);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingStudentData, setLoadingStudentData] = useState(true);
  const { programId, cboStudentId } = match.params;

  useEffect(() => {
    setLoadingData(true);
    setLoadingStudentData(true);

    fetchData(
      `/api/cbo/eligibility/${programNames[programId] || "Unknown"}/${cboStudentId}`,
      ({data}) => {
        setData(data);
        setLoadingData(false)
      },
      (error) => {
        console.log(error)
        setLoadingData(false)
      }
    )

    fetchData(
      `/api/cbo/academic-record/${programNames[programId] || "Unknown"}/${cboStudentId}`,
      ({data}) => {
        setStudentData(data);
        setLoadingStudentData(false)
      },
      () => setLoadingStudentData(false)
    )
  }, [fetchData, cboStudentId]);

  const loading = loadingData || loadingStudentData

  return (
    <Section fullHeight noMarginBottom>
      <Grid container justify="center">
        <Grid container className={classes.page} spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item xs={12} md={3} className={classes.leftColumn}>
                <img src={futureGradLogotypeColor} className={classes.logotype} alt="FutureGrad logo" />
              </Grid>

              <Grid item xs={12} md={9} className={classes.rightColumn}>
                <Typography variant="h1" className={classes.title}>
                  <b> Requirements to earn Spring 2022 Financial</b>
                </Typography>
                <Typography variant="subtitle2" color="secondary">
                  <b>* Risk levels are estimates. See how they were calculated.</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {loading && (
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography variant="h5" align="center" className={classes.noMargin}>Hold tight, it will just take a second</Typography>
                  <Typography variant="body2" align="center">We're currently getting your course information</Typography>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.loading} src={loadingAnimation} alt=""/>
                </Grid>
              </Grid>
            </Grid>
          )}
          { !loading && (!data || !studentData) && (
            <Grid item xs={12}>
              <Typography align="center" gutterBottom>Sorry, there was an issue fetching your data:</Typography>
              <Typography variant="subtitle1" align="center">
                Make sure you're logged in as an <b>admin</b> user
                <Link to={routes.ROOT} className={classes.link} target="_blank">
                  <Typography variant="body2" color="inherit">Log In</Typography>
                </Link>
              </Typography>
            </Grid>
          )}
          { !loading && data && studentData && (
            <>
              <Grid item xs={12} md={3} className={classes.leftColumn}>
                <StudentDetails studentData={studentData} cboStudentId={cboStudentId} />
              </Grid>

              <Grid item xs={12} md={9} className={classes.rightColumn}>
                <Alert classes={classes} />
                <EligibilityResults data={data} studentData={studentData} />
                <NextSteps classes={classes} data={data} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Section>
  )
})