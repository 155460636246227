import Typography from '@material-ui/core/Typography'
import { includes, pipe, without } from 'ramda'
import React from 'react'
import { withUser } from '../../../providers/user.provider'
import { withDataValidation } from '../common/with-data-validation'
import { Input } from '../common/input.component'
import { YesNoButtons } from '../common/yes-no-buttons.component'
import { OpportunityProgramHelp } from '../help/opportunity-program-help.component'
import { SisLink } from '../common/sis-link.component'
import { AcademicRecordHelp } from '../help/academic-record-help.component'
import { EarnedCreditsHelp } from '../help/earned-credits-help.component'
import { AttemptedCreditsHelp } from '../help/attempted-credits-help.component'
import { EnrolledCreditsHelp } from '../help/enrolled-credits-help.component'
import { Section } from '../../common/section.component'
import { RegistrationStep } from '../common/registration-step.component'
import { SemestersAttended } from './semesters-attended.component'

export const AcademicRecord = pipe(
  withUser,
  withDataValidation({
    creditsEnrolled: {guard: ({creditsEnrolled}) => parseInt(creditsEnrolled, 10) <= 21, msg: 'Should be no more than 21'},
    creditsAttempted: {guard: ({creditsEnrolled, creditsAttempted}) => parseInt(creditsEnrolled, 10) <= parseInt(creditsAttempted, 10), msg: 'Should be no less than credits enrolled'},
    creditsEarned: {guard: ({creditsEarned, creditsAttempted}) => parseInt(creditsEarned, 10) <= parseInt(creditsAttempted, 10), msg: 'Should be no more than credits attempted'},
    cumulativeGpa: {guard: ({cumulativeGpa}) => 0 <= parseFloat(cumulativeGpa) && parseFloat(cumulativeGpa) <= 4, msg: 'Required number between 0 - 4'},
    semestersAttended: {guard: ({semestersAttended}) => semestersAttended.length > 0, msg: 'Pick at least one semester.'}
  })
)(class extends React.Component {
  constructor(props) {
    super(props)
    const data = this.props.academicRecord
    if (data.inOpportunityProgram === null) data.inOpportunityProgram = false
    if (data.creditsEnrolled === null && this.props.user.currentTerm === "201906") data.creditsEnrolled = 0

    this.state = {data, currentlyEnrolled: this.props.user.currentTerm !== "201906" || data.creditsEnrolled > 0}
  }

  handleInputChange = input => event => this.setState({data: {...this.state.data, [input]: event.target.value}})
  handleSemesterAdd = value => this.setState({data: {...this.state.data, semestersAttended: [...this.state.data.semestersAttended, value]}})
  handleSemesterDelete = value => this.setState({data: {...this.state.data, semestersAttended: without(value, this.state.data.semestersAttended)}})

  handleSemesterClick = semesterCode => () =>
    includes(semesterCode, this.state.data.semestersAttended)
      ? this.handleSemesterDelete(semesterCode)
      : this.handleSemesterAdd(semesterCode)

  handleInOpportunityProgramChange = value => () => this.setState({data: {...this.state.data, inOpportunityProgram: value}})
  handleEnrolledInSummerChange = value => () => this.setState({currentlyEnrolled: value, data: {...this.state.data, creditsEnrolled: value === false ? 0 : this.state.data.creditsEnrolled}})

  handleNextClick = () => this.props.validate(this.state.data)
    ? this.props.handleAcademicRecord(this.state.data, true)
    : window.scroll(0, 0)

  handleBackClick = () => this.props.handleAcademicRecord(this.state.data, false)

  render() {
    return (
      <RegistrationStep
        stepTitle="About Yourself (5/5)"
        title="Tell us about yourself"
        subTitle={<>This information can be found on your unofficial transcript in <SisLink/></>}
        subTitleHelpText="What is this information needed for?"
        helpModal={AcademicRecordHelp}
        handleNextClick={this.handleNextClick}
        handleBackClick={this.handleBackClick}
        nextLabel="Finish"
        trackingLabel="Academic record - why it's needed"
      >
        <Section header="Select all the semesters you have been enrolled in classes">
          {
            this.props.errors.semestersAttended &&
            <Typography variant="body2" color="error">{this.props.errors.semestersAttended}</Typography>
          }
          <SemestersAttended
            yearEnrolled={this.props.yearEnrolled}
            semestersAttended={this.state.data.semestersAttended}
            onSemesterClick={this.handleSemesterClick}
            error={Boolean(this.props.errors.semestersAttended)}
          />
        </Section>
        <Section
          header="Are you part of the College Discovery, SEEK or EOP program?"
          helpText="How does this impact eligibility?"
          helpModal={OpportunityProgramHelp}
          trackingLabel="About yourself - Opportunity program"
        >
          <YesNoButtons
            value={this.state.data.inOpportunityProgram}
            handleYesClick={this.handleInOpportunityProgramChange(true)}
            handleNoClick={this.handleInOpportunityProgramChange(false)}
          />
        </Section>
        {this.props.user.currentTerm === "201906" && <Section
            header="Are you enrolled in Summer term?"
          >
            <YesNoButtons
              value={this.state.currentlyEnrolled}
              handleYesClick={this.handleEnrolledInSummerChange(true)}
              handleNoClick={this.handleEnrolledInSummerChange(false)}
            />
          </Section>
        }
        {this.state.currentlyEnrolled && <Section
            header="How many credits are you enrolled in?"
            helpText="What are enrolled credits?"
            helpModal={EnrolledCreditsHelp}
            trackingLabel="About yourself - Enrolled credits"
          >
            <Input
              value={this.state.data.creditsEnrolled}
              onChange={this.handleInputChange('creditsEnrolled')}
              error={this.props.errors.creditsEnrolled}
            />
          </Section>
        }
        <Section
          header="How many credits have you attempted?"
          helpText="What are attempted credits?"
          helpModal={AttemptedCreditsHelp}
          trackingLabel="About yourself - Attempted credits"
        >
          <Input
            value={this.state.data.creditsAttempted}
            onChange={this.handleInputChange('creditsAttempted')}
            error={this.props.errors.creditsAttempted}
          />
        </Section>
        <Section
          header="How many credits have you earned?"
          helpText="What are earned credits?"
          helpModal={EarnedCreditsHelp}
          trackingLabel="About yourself - Earned credits"
        >
          <Input
            value={this.state.data.creditsEarned}
            onChange={this.handleInputChange('creditsEarned')}
            error={this.props.errors.creditsEarned}
          />
        </Section>
        <Section header="What's your overall GPA?">
          <Input
            value={this.state.data.cumulativeGpa}
            onChange={this.handleInputChange('cumulativeGpa')}
            error={this.props.errors.cumulativeGpa}
          />
        </Section>
      </RegistrationStep>
    )
  }
})