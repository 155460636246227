import React from 'react'
import { pipe } from 'ramda'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  image: {
    widht: 'inherit',
    height: 'inherit'
  }
})

export const Image = pipe(
  withStyles(styles)
)(({src, classes}) =>
  <img src={src} alt="" className={classes.image} />
)