import React from "react"
import { pipe } from "ramda"
import * as classnames from "classnames"
import { withStyles } from "@material-ui/core"

const styles = theme => ({
  box: {
    width: "100%",
    color: theme.palette.common.white,
    padding: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
    '&.secondary': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.grey': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.background.grey
    },
    '&.white': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white
    },
    '&.marginTop': {
      marginTop: theme.spacing.unit
    },
    '&.marginBottom': {
      marginBottom: theme.spacing.unit
    },
    '&.paddingTop': {
      paddingTop: theme.spacing.unit * 2
    },
    '&.paddingBottom': {
      paddingBottom: theme.spacing.unit * 2
    }
  }
})

export const Box = pipe(
  withStyles(styles)
)(({ children, classes, color, marginTop, marginBottom ,paddingTop, paddingBottom }) => (
  <div className={classnames(classes.box, { [color]: color, "marginTop": marginTop, "marginBottom": marginBottom, "paddingTop": paddingTop, "paddingBottom": paddingBottom })}>
    {children}
  </div>
))