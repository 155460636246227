import { pipe, without } from 'ramda'
import * as React from 'react'
import { Router, Switch } from 'react-router-dom'
import { MissingSchool } from './missing-school/missing-school.component'
import InstitutionProvider from './institution.provider'
import { withApiData } from '../common/with-api-data'
import { postData } from '../../utils/api-utils'
import { UserConsumer, withUser } from '../../providers/user.provider'
import { CounselingOrganization } from './counseling-organization/counseling-organization.component'
import { AcademicRecord } from './academic-record/academic-record.component'
import { FinancialAidDetails } from './financial-aid-details/financial-aid-details.component'
import { FinancialAid } from './financial-aid/financia-aid.component'
import { PrivateRoute } from '../routing/private-route.component'
import { Basics } from './basics/basics.component'
import { next, prev, getRegistrationStep } from './registration-routing'
import { routes } from "../routing/routes-paths"

const RegistrationComponent = pipe(
  withApiData(`/api/registration`),
  withUser
)(
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = this.props.data
    }

    componentDidMount() { this.registrationStartPageResolver() }
    componentDidUpdate() { this.registrationStartPageResolver() }

    registrationStartPageResolver = () =>
      this.props.history.location.pathname === routes.REGISTRATION &&
      this.props.history.push(getRegistrationStep(this.state.currentStep, this.state))

    handleBasicsSubmit = (data, navigate) => this.request('/api/registration/basics', data, navigate)
    handleAidDetails = (data, navigate) => this.request('/api/registration/financial-aid-details', data, navigate)
    handleAcademicRecord = (data, navigate) => this.request('/api/registration/academic-record', data, navigate)
    handleCounselingOrganizations = (data, navigate) => this.request(`/api/registration/counseling-organization`, data, navigate)

    handleAidSelectionSubmit = type => (data, navigate) => {
      return this.request(
        '/api/registration/financial-aid',
        {
          receiving: without(data, this.state.financialAid.receiving),
          lost: without(data, this.state.financialAid.lost),
          interestedIn: without(data, this.state.financialAid.interestedIn),
          [type]: data
        },
        navigate
      )
    }

    request = (path, body, nextStep) => {
      if (!nextStep) {
        this.props.history.push(prev(this.props.history.location.pathname, this.state))
        this.forceUpdate()
      } else {
        postData(path, body,
          ({data}) =>
            this.setState(data, () =>
              this.props.setRegistrationStatus(
                data.currentStep,
                this.props.user.refreshUserData(
                  () => this.props.history.push(next(this.props.history.location.pathname, this.state))
                )
              )
            ),
          (error) => this.setState({error})
        )
      }
    }

    render() {
      if (this.state.error) throw this.state.error
      return (
        <InstitutionProvider institution={this.state.institution}>
          <Router history={this.props.history}>
            <Switch>
              <PrivateRoute
                path="/registration/basics"
                component={Basics}
                onSubmit={this.handleBasicsSubmit}
                data={this.state.basics}
              />
              <PrivateRoute
                path="/registration/missing-school"
                component={MissingSchool}
                logout={this.props.logout}
              />
              <PrivateRoute
                path="/registration/financial-aid"
                component={FinancialAid}
                onSubmit={this.handleAidSelectionSubmit}
                financialAid={this.state.financialAid}
              />
              <PrivateRoute
                path="/registration/financial-aid-details"
                component={FinancialAidDetails}
                handleAidDetails={this.handleAidDetails}
                financialAidDetails={this.state.financialAidDetails}
                financialAid={this.state.financialAid}
              />
              <PrivateRoute
                path="/registration/academic-record"
                component={AcademicRecord}
                academicRecord={this.state.academicRecord}
                handleAcademicRecord={this.handleAcademicRecord}
                yearEnrolled={this.state.basics.yearEnrolled}
              />
              <PrivateRoute
                path="/registration/counseling-organization"
                component={CounselingOrganization}
                counselingOrganizations={this.state.counselingOrganizations}
                handleCounselingOrganization={this.handleCounselingOrganizations}
              />
            </Switch>
          </Router>
        </InstitutionProvider>
      )
    }
  }
)

export const Registration = (props) =>
  <UserConsumer>
    {({setRegistrationStatus, logout}) =>
      <RegistrationComponent
        {...props}
        setRegistrationStatus={setRegistrationStatus}
        logout={logout}
      />
    }
  </UserConsumer>