import React from 'react'
import { Paragraph, Title } from './common.component'

export const About = () =>
  <>
    <Title>
      About
    </Title>
    <Paragraph>
      FutureGrad is a wholly owned by Yenko, Inc, whose mission is to eliminate financial barriers to college
      completion.
    </Paragraph>
    <Paragraph>
      By using FutureGrad, college students can determine the critical academic and other requirements for
      maintaining or regaining financial aid eligibility.
    </Paragraph>
  </>