import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { GoogleLogin } from 'react-google-login'
import { withUser } from '../../providers/user.provider'
import { googleClientId } from '../../config'
import { UserConsumer } from '../../providers/user.provider'
import { ACTIONS, CATEGORIES } from '../../utils/google-analytics'
import { postData } from '../../utils/api-utils'
import { ExcelsiorDisclaimer } from '../common/disclaimer.component'
import { withTracking } from '../common/trackClicks'
import { Button } from '../common/button.component'
import { withHelpModal } from '../help/with-help-modal'
import { ExcelsiorRequirements } from './excelsior-requirements.component'
import { DashboardSection } from '../dashboard/dashboard-section.component'
import { FailedFutureGradUpgradeAlert } from './failed-future-grad-upgrade-alert.component'
import { routes } from "../routing/routes-paths"

const applyLink = 'https://webapps.hesc.ny.gov/questionnaire/page.hesc?questionnaireId=58&versionNumber=3'

const styles = theme => ({
  actionButtons: {
    textAlign: 'center',
    '& a': {
      marginBottom: theme.spacing.unit
    },
    '& button': {
      marginBottom: theme.spacing.unit
    }
  }
})

export const ExcelsiorEligibility = pipe(
  withStyles(styles),
  withUser,
  withHelpModal(FailedFutureGradUpgradeAlert)
)(class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      requirements: JSON.parse(localStorage.getItem('excelsiorRequirements')) || {},
      registrationData: JSON.parse(localStorage.getItem('excelsiorRegistration')) || {checklist: [], relation: 'NA'},
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (!nextProps.user.authenticated && (this.props.user.authenticated || !localStorage.getItem('excelsiorUserEmail'))) {
      nextProps.history.push('/excelsior')
    }
  }

  trackApplyToExcelsiorClick = () => postData(
    '/api/excelsior-microsite/tracking/apply-to-excelsior',
    {email: localStorage.getItem('excelsiorUserEmail')}
  )

  handleUpgradeToFutureGradClick = () => postData(
    '/api/excelsior-microsite/upgrade-to-futuregrad',
    {},
    ({data: {upgradedToFutureGrad}}) =>
      upgradedToFutureGrad
        ? this.props.user.updateUserData({role: 'STUDENT', registrationStatus: 'BASICS'}, () => this.props.history.push(routes.ROOT))
        : this.props.openModal()
  )

  render() {
    if (!this.props.user.loaded) return null;
    return (
      <div>
        <DashboardSection header='Eligibility'>
          <Typography variant="body1">Let's see how you're doing</Typography>
            <ExcelsiorRequirements />
        </DashboardSection>
        <DashboardSection>
          <ActionButtons
            trackApplyToExcelsiorClick={this.trackApplyToExcelsiorClick}
            handleUpgradeToFutureGradClick={this.handleUpgradeToFutureGradClick}
          />
        </DashboardSection>
        <DashboardSection>
          <ExcelsiorDisclaimer/>
        </DashboardSection>
      </div>
    )
  }
})

const ActionButtons = pipe(
  withUser,
  withStyles(styles),
  withTracking(({category: CATEGORIES.excelsior, action: ACTIONS.openExternal, label: 'Apply to Excelsior'}))
)(({user: {loaded, authenticated}, classes, track, trackApplyToExcelsiorClick, handleUpgradeToFutureGradClick}) =>
  loaded && <div className={classes.actionButtons}>
    {!authenticated && <GoogleSignIn/>}
    <div style={{marginBottom: 8}}>
      <a onClick={track(trackApplyToExcelsiorClick)} href={applyLink} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none'}}>
        {
          authenticated
            ? <Button variant='contained' text="Apply to Excelsior"/>
            : <Typography variant='body2' color='inherit'>Apply to Excelsior</Typography>
        }
      </a>
    </div>
    {
      authenticated &&
      <Button
        onClick={handleUpgradeToFutureGradClick}
        highlight
        color='primary'
        text='Upgrade to Future Grad'
      />
    }
  </div>
)

const success = (response, callback) => {
  const email = response && response.profileObj && response.profileObj.email
  const idToken = response && response.tokenId

  callback(email, idToken)
}

const failure = (response) => {
  console.log(response)
}

const GoogleSignIn = props => (
  <UserConsumer>
    {({googleLoginForFakeEmail}) =>
      <GoogleLogin
        clientId={googleClientId}
        render={renderProps => <Button
          text="Save my information"
          variant="contained"
          onClick={renderProps.onClick}
        />}
        onSuccess={response => success(response, googleLoginForFakeEmail)}
        onFailure={failure}
      />
    }
  </UserConsumer>
)