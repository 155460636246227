import { includes, range } from 'ramda'
import React from 'react'
import { Fragment } from 'react'
import { SwitchButtonGrid } from '../common/switch-button-grid'
import { SwitchButton } from '../common/switch-button.component'

export const SemestersAttended = ({semestersAttended, yearEnrolled, onSemesterClick, error}) =>
  <SwitchButtonGrid>
    {
      // TODO: Use current term
      range(parseInt(yearEnrolled, 10), 2022).map(year =>
        <Fragment key={year}>
          <SwitchButton
            label={`Spring '${(year).toString().substr(2, 2)}`}
            onClick={onSemesterClick(`${year}02`)}
            active={includes(`${year}02`, semestersAttended)}
            error={error}
          />
          {/*{year !== 2021 && (*/}
            <SwitchButton
              label={`Fall '${year.toString().substr(2, 2)}`}
              onClick={onSemesterClick(`${year}09`)}
              active={includes(`${year}09`, semestersAttended)}
              error={error}
            />
          {/*)}*/}
        </Fragment>
      )
    }
</SwitchButtonGrid>
