import { withStyles } from '@material-ui/core'
import MaterialButton from '@material-ui/core/Button'
import classnames from 'classnames'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  linkButton: {
    width: `220px`,
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'none'
  },
  disabledPrimary: {
    color: 'white !important',
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  disabledSecondary: {
    color: 'white !important',
    backgroundColor: `${theme.palette.secondary.light} !important`,
  },
  highlight: {
    color: 'white',
    backgroundColor: theme.palette.highlight.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  marginBottom: {
    marginBottom: theme.spacing.unit
  },
  borderPrimary: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  borderSecondary: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  borderInherit: {
    border: `1px solid`
  },
  borderRadius: {
    borderRadius: '2px'
  }
})

export const Button = pipe(
  withStyles(styles)
)(({onClick, text, classes, disabled, variant, color, highlight, marginBottom, dataTour}) =>
  <div data-tour={dataTour || "button"} className={classnames({
    [classes.container]: true,
    [classes.marginBottom]: marginBottom
  })}>
    <MaterialButton
      onClick={onClick}
      className={classnames({
        [classes.linkButton]: true,
        [classes.borderPrimary]: variant === "outlined" && color === "primary",
        [classes.borderSecondary]: variant === "outlined" && color === "secondary",
        [classes.borderInherit]: variant === "outlined" && color === "inherit",
        [classes.highlight]: highlight
      })}
      disabled={disabled}
      classes={{
        disabled: color === "primary" ? classes.disabledPrimary : classes.disabledSecondary,
        root: classes.borderRadius
      }}
      size="small"
      variant={variant || "contained"}
      color={color || "secondary"}
    >
      {text}
    </MaterialButton>
  </div>
)