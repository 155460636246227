import React, { useEffect, useState } from "react"
import { programNames } from "./utils"
import { fetchData } from "../../utils/api-utils"
import { StudentList } from "./student-list.component"

export const AllStudentList = ({ match }) => {
  const { programId } = match.params;
  const [studentGroups, setStudentGroups] = useState(undefined)

  useEffect(() => {
    fetchData(
      '/api/cbo/students',
      ({data}) => {
        if (programId !== "ALL") {
          setStudentGroups({ [programNames[programId]]: data[programNames[programId]] });
        } else {
          setStudentGroups(data);
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }, [programId])

  return (
    <>
      {studentGroups && (
        Object.entries(studentGroups).map(([programName, students]) => (
          <StudentList key={programName} programName={programName} students={students} />
        ))
      )}
    </>
  );
};