import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import SvgIcon from '@material-ui/core/SvgIcon'
import { routes } from "../routing/routes-paths"
import futureGradLogotypeWhite from "../../assets/images/futuregrad_logotype_white.svg"

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  content: {
    maxWidth: 960,
    margin: 'auto',
    padding: theme.spacing.unit * 2
  },
  logotype: {
    width: theme.spacing.unit * 20,
  },
})

const footerSectionStyles = theme => ({
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  section: {
    marginBottom: theme.spacing.unit * 2
  }
})

const FooterSection = withStyles(footerSectionStyles)(({classes, title, children}) =>
  <div className={classes.section}>
    <Typography color="inherit" className="bolder">{title}</Typography>
    <ul className={classes.list}>
      {children.map((c, i) => <li key={i}>{c}</li>)}
    </ul>
  </div>
)

const Copyright = () =>
  <FooterItem>Copyright 2019 Yenko</FooterItem>

const textStyles = {
  undecorated: {
    textDecoration: 'none',
    color: 'inherit'
  }
}

const FooterItem = withStyles(textStyles)(({classes, children, ...props}) =>
  <Typography color="inherit" className={classes.undecorated} {...props}>{children}</Typography>
)

export const Footer = withStyles(styles)(({classes}) =>
  <div className={classes.container}>
    <Grid container className={classes.content}>
      <Grid item xs={12} sm={4}>
        <img src={futureGradLogotypeWhite} className={classes.logotype} alt="FutureGrad logo" />
        <Typography color="inherit" gutterBottom><em>byYenko</em></Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FooterSection title="Company">
          <FooterItem component={Link} to={routes.ABOUT}>About</FooterItem>
          <FooterItem component={Link} to={routes.TERMS_OF_SERVICE}>Terms of Service</FooterItem>
          <FooterItem component={Link} to={routes.PRIVACY_POLICY}>Privacy Policy</FooterItem>
        </FooterSection>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FooterSection title="Contact">
          <FooterItem component="a" href="mailto:support@future-grad.com">support@future-grad.com</FooterItem>
          <FooterItem>150 Court St. Brooklyn, NY 11201</FooterItem>
          <SocialIcons/>
        </FooterSection>
      </Grid>
      <Grid item xs={12}>
        <Copyright/>
      </Grid>
    </Grid>
  </div>
)

const socialIconsStyles = theme => ({
  container: {
    marginLeft: -4,
    marginTop: theme.spacing.unit
  }
})

const SocialIcons = withStyles(socialIconsStyles)(({classes}) => {
  return (
    <div className={classes.container}>
      <FooterIconButton url="https://facebook.com/futuregrad">
        <FacebookIcon fontSize="large"/>
      </FooterIconButton>
      <FooterIconButton url="https://twitter.com/FutureGrad1">
        <TwitterIcon fontSize="large"/>
      </FooterIconButton>
      <FooterIconButton url="https://www.instagram.com/future_grad/">
        <InstagramIcon fontSize="large"/>
      </FooterIconButton>
    </div>
  )
})

const FooterIconButton = withStyles(textStyles)(({classes, url, children}) =>
  <a className={classes.undecorated} href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const FacebookIcon = props =>
  <SvgIcon {...props}>
    <path
      d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"/>
  </SvgIcon>

const TwitterIcon = props =>
  <SvgIcon {...props}>
    <path
      d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"/>
  </SvgIcon>

const InstagramIcon = props =>
  <SvgIcon {...props}>
    <path
      d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"/>
  </SvgIcon>