import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: `0 ${theme.spacing.unit * 3}px`,
    '&:focus': {
      outline: 'none'
    }
  }
})

const ConfirmationDialog = withStyles(styles)(class extends React.Component {
  state = {open: false}

  open = (callback) => this.setState({open: true, callback})
  close = () => this.setState({open: false}, this.props.onClose)

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.close}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            Cancel
          </Button>
          <Button onClick={this.state.callback} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
})

export const withConfirmationDialog = WrappedComponent =>
  class extends React.Component {
    dialog = null

    withConfirm = callback => this.dialog.open(callback)

    render() {
      return (
        <>
          <ConfirmationDialog
            innerRef={ref => this.dialog = ref}
          />
          <WrappedComponent {...this.props} withConfirm={this.withConfirm} />
        </>
      )
    }
  }