import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'
import { Input } from '../../registration/common/input.component'

export const MajorGpaRequirement = pipe(
  excelsiorRegistrationStep('majorGpaRequirement', 'About your major'),
  withExcelsiorRegistrationData
)(({handleChange, data: {majorGpaRequirement}, errors: {majorGpaRequirement: error}}) =>
  <StepSection
    helpText="One of the requirements to renew the Excelsior Scholarship is that you are meeting the GPA requirements for your major. While most degree programs require a 2.0 for graduation, they are a few that require a higher GPA."
    header="What is the minimum GPA required for your major?"
  >
    <Input
      onChange={event => handleChange({majorGpaRequirement: event.target.value})}
      placeholder='Ex 3.0'
      value={majorGpaRequirement}
      error={error}
    />
  </StepSection>
)