import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MaterialTable from 'material-table'
import { pipe } from 'ramda'
import React from 'react'
import { deleteData, postData } from '../../utils/api-utils'
import { withApiData } from '../common/with-api-data'

const styles = theme => ({
  paper: {
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})

export const Admins = pipe(
  withApiData('/api/admin/cuny/admins'),
  withStyles(styles)
)(({classes, data, refreshData}) =>
  <div>
    <Paper className={classes.paper}>
      <Typography variant='body1'>
        Total accounts: {data.totalAccounts}
      </Typography>
      <Typography variant='body1'>
        Registered accounts: {data.registeredAccounts}
      </Typography>
    </Paper>
    <MaterialTable
      title='Administrators'
      columns={[
        {title: 'First Name', field: 'firstName'},
        {title: 'Last Name', field: 'lastName'},
        {title: 'Email', field: 'email'},
        {title: 'Role', field: 'role', lookup: {'CUNY_ADMIN': 'admin', 'CUNY_SUPERADMIN': 'superadmin'}, editComponent: props => null},
        {title: 'Status', field: 'accountStatus', editComponent: props => null},
        {title: 'Last Seen', field: 'lastSeen', editComponent: props => null},
      ]}
      data={data.users}
      options={{
       filtering: true,
       pageSize: 10
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve =>
            postData(
              '/api/admin/cuny/admins',
              {email: newData.email, firstName: newData.firstName, lastName: newData.lastName},
              () => {
                refreshData()
                resolve()
              }
            )
          ),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve =>
            postData(
              '/api/admin/cuny/admins/update',
              {
                email: oldData.email,
                newEmail: oldData.email === newData.email ? null : newData.email,
                newFirstName: newData.firstName,
                newLastName: newData.lastName
              },
              () => {
                refreshData()
                resolve()
              }
            )
          ),
        onRowDelete: oldData =>
          new Promise(resolve =>
            deleteData(
              '/api/admin/cuny/admins',
              {email: oldData.email},
              () => {
                refreshData()
                resolve()
              }
            )
          ),
      }}
    />
  </div>
)