import Typography from '@material-ui/core/Typography'
import { any, pick, pipe } from 'ramda'
import React from 'react'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import { withHelpModal } from '../help/with-help-modal'
import { Section } from '../common/section.component'
import { AcademicPrediction } from './academic-prediction.component'
import { withEligibilityData } from './eligibility-data.provider'
import { EligibilityStatus } from './eligibility-status.component'
import { WithdrawalConsequences } from './withdrawal-consequences.component'
import { Header } from "../common/header.component"
import { routes } from "../routing/routes-paths"
import { Button } from "../common/button.component"
import { ActionSection } from "../common/action-section.component"
import { withUser } from "../../providers/user.provider"
import { trackEvent } from "../../utils/intercom-analytics"

export const EligibilityDashboard = pipe(
  withHelpModal(SimpleHelpModal),
  withEligibilityData,
  withUser,
)(class extends React.Component {
  state = {
    disclaimerWasShown: false
  }
  componentDidMount() {
    if (any(({targetGradeId}) => targetGradeId === null, this.props.goals))
      this.props.history.push(routes.COURSES_GRADE_PICKER)
    else if (this.props.financialAid.length === 0)
      this.props.getEligibilityByGoals()

    // this.showDisclaimer()
  }

  componentDidUpdate() {
    if (this.props.financialAid.length === 0)
      this.props.history.push(routes.ELIGIBILITY_START)

    // this.showDisclaimer()
  }

  // showDisclaimer = () => {
  //   const disclaimer = this.props.academicPrediction.disclaimer
  //   if (disclaimer && !this.state.disclaimerWasShown) {
  //     this.setState({disclaimerWasShown: true}, () =>
  //       this.props.openModal(disclaimer.title, disclaimer.body, 'Got it!')
  //     )
  //   }
  // }

  handleSaveAsGoalClick = () => {
    trackEvent('saved-goals', pick(['semesterGpa', 'semesterEarnedCredits', 'cumulativeGpa'], this.props.academicPrediction))

    this.props.saveGoals(() =>
      this.props.user.refreshUserData(() =>
        this.props.history.push(routes.DASHBOARD)
      )
    )
  }

  handleRecalculateClick = () => {
    trackEvent('updated-goal', {source: 'eligibility/recalculate_goal'})
    this.props.history.push(routes.COURSES_GRADE_PICKER)
  }

  render() {
    if (this.props.financialAid.length === 0) return null
    return (
      <Section fullHeight noMarginBottom>
        <Typography variant="h4">Your GPA and enrollment goals</Typography>
        <AcademicPrediction academicPrediction={this.props.academicPrediction}/>
        {this.props.isWithdrawalPresent() &&
          <WithdrawalConsequences
            goals={this.props.goals}
            withdrawalPoint={this.props.getWithdrawalPoint().label}
          />
        }

        <EligibilityStatus
          title="What will happen to my financial aid eligibility status?"
          financialAid={this.props.financialAid}
          detailsPath="eligibility"
        />

        <Header
          title="Save grades as your goal"
          subtitle="Save these grades as you goals for this semester"
        />

        <ActionSection>
          <Button dataTour="save_goal_button" text="Save as my goal" onClick={this.handleSaveAsGoalClick} marginBottom />
          <Button text="Recalculate goal" onClick={this.handleRecalculateClick} color="primary" />
        </ActionSection>
        </Section>
    )
  }
})
