import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { StylesProvider, createGenerateClassName } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import './App.css'
import { IdleTimer } from './components/idle-timer/idle-timer.component'
import { Layout } from './components/layout/layout.component'
import UserProvider from './providers/user.provider'
import SignIn from './components/signin/admin-signin.component'
import { EligibilityPage } from "./components/cbo/eligibility-page.component"
import { theme } from './styles/theme'
import Intercom from './components/analytics/intercom.component'
import GoogleAnalytics from './components/analytics/google-analytics.component'
import Helphero from "./components/analytics/helphero.component"
import { routes } from "./components/routing/routes-paths"
import { AllStudentList } from "./components/cbo/all-student-list.component"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'fg',
})

const App = () =>
  <StylesProvider generateClassName={generateClassName}>
    <UserProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <div className="App">
            <IdleTimer/>
            <GoogleAnalytics/>
            <Intercom/>
            <Helphero/>
            <Switch>
              <Route
                exact
                path={routes.ADMIN_LOGIN}
                component={SignIn}
              />
              <Route
                exact
                path={routes.CBO_SNAPSHOT}
                component={EligibilityPage}
              />
              <Route
                exact
                path={routes.CBO_STUDENTS}
                component={AllStudentList}
              />
              <Layout/>
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    </UserProvider>
  </StylesProvider>

export default App
