import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { Button } from "./button.component"
import { Link } from "react-router-dom"

const styles = theme => ({
  navLink: {
    textDecoration: 'none',
    color: 'inherit'
  }
})

export const LinkButton = pipe(
  withStyles(styles)
)(({classes,to, label, disabled, variant, color, highlight, marginBottom}) =>
  <Link to={to} className={classes.navLink}>
    <Button
      color={color || "secondary"}
      text={label}
      disabled={disabled}
      variant={variant}
      highlight={highlight}
      marginBottom={marginBottom}
    />
  </Link>
)