import { withStyles } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import { omit, pipe } from 'ramda'
import React from 'react'
import checkEmail from '../../assets/images/check-email.png'
import { postDataWithHandler } from '../../utils/api-utils'
import { TextInput } from '../common/text-input.component'
import { withHelpModal } from '../help/with-help-modal'
import { CheckboxWithLabel } from '../registration/common/checkbox.component'
import { withDataValidation } from '../registration/common/with-data-validation'
import { PasswordStrengthMeter } from './password-strength-meter.component'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import { Header } from '../common/header.component'
import {ActionSection} from "../common/action-section.component"
import {Button} from "../common/button.component"
import {TextLink} from "../common/text-link.component"
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"
import { Section } from "../common/section.component"

const styles = theme => ({
  image: {
    width: '70%',
    maxWidth: 280,
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto ${theme.spacing.unit * 5}px auto`
  }
})

export const CunyRegistration = pipe(
  withStyles(styles),
  withHelpModal(SimpleHelpModal),
  withDataValidation({
    firstName: {msg: "Please enter your first name"},
    lastName: {msg: "Please enter your last name"},
    schoolEmail: {
      guard: ({schoolEmail}) => /.+@((.+\.edu)|u2i.com|getyenko.com)$/.test(schoolEmail),
      msg: "Please enter your school email - it ends with .edu"
    },
    preferredEmail: {
      required: false,
      guard: ({preferredEmail}) => Boolean(preferredEmail) === false || /.+@.+\.[A-Za-z]+$/.test(preferredEmail),
      msg: "Please enter valid email"
    },
    password: {
      guard: ({password}) => password.length >= 8,
      msg: "Your passwords must be at least 8 characters long"
    },
    passwordConfirmation: {
      guard: ({password, passwordConfirmation}) => password === passwordConfirmation,
      msg: "Password and Password Confirmation don't match."
    },
    termsAndAgreements: {
      guard: ({termsAndAgreements}) => Boolean(termsAndAgreements),
      msg: "Please agree to Terms of Service"
    }
  })
)(class extends React.Component {
  displayName = "CunyRegistration"

  state = {
    firstName: '', lastName: '', schoolEmail: '', preferredEmail: undefined,
    password: '', passwordConfirmation: '', termsAndAgreements: false,
    success: false
  }

  handleInputChange = key => event => this.setState({[key]: event.target.value})
  handleTermsAndAgreementsChange = event => this.setState({termsAndAgreements: !this.state.termsAndAgreements})

  handleRegisterClick = () => this.props.validate(this.state) && postDataWithHandler(
    '/api/user/register',
    omit(['success'], this.state),
    (status, {data}) => {
      switch (status) {
        case 200: {
          this.setState({success: true})
          break
        }
        default: {
          this.props.openModal(...getErrorMessage(data.reason))
          break
        }
      }
    }
  )

  propsForTextInput = key => ({
    value: this.state[key],
    onChange: this.handleInputChange(key),
    error: this.props.errors[key]
  })

  render() {
    return this.state.success
    ? <Section>
        <Header
          title="You're almost done!"
          subtitle="Check your school email to verify your account. (Check your SPAM folder if you don't see it)"
        />
        <img className={this.props.classes.image} src={checkEmail} alt=""/>
      </Section>
    : <Section fullHeight noMarginBottom>
        <Header
          title="Let's get started"
          subtitle="Tell us about yourself"
        />

        <TextInput label="First name" {...this.propsForTextInput('firstName')} noMarginBottom />
        <TextInput label="Last name" {...this.propsForTextInput('lastName')} noMarginBottom />
        <TextInput label="CUNY email" type="email" {...this.propsForTextInput('schoolEmail')} noMarginBottom />
        <TextInput label="Preferred email (optional)" type="email" {...this.propsForTextInput('preferredEmail')} noMarginBottom />
        <TextInput label="Password" type="password" {...this.propsForTextInput('password')} noMarginBottom />
        <PasswordStrengthMeter password={this.state.password}/>
        <TextInput  label="Confirm password" type="password" {...this.propsForTextInput('passwordConfirmation')} />

        <FormGroup row>
          <CheckboxWithLabel
            checked={this.state.termsAndAgreements}
            onChange={this.handleTermsAndAgreementsChange}
            label={<TermsAndAgreementsLabel />}
            error={Boolean(this.props.errors.termsAndAgreements)}
          />
        </FormGroup>
        {
          Boolean(this.props.errors.termsAndAgreements) &&
          <FormHelperText error>{this.props.errors.termsAndAgreements}</FormHelperText>
        }

        <ActionSection>
          <Button text="Sign Up" onClick={this.handleRegisterClick} />
          <TextLink label="Log In" to={routes.CUNY_LOGIN} />
        </ActionSection>
      </Section>
  }
})

const TermsAndAgreementsLabel = () =>
  <div>
    <span>I accept</span>
    {" "}
    <TextLink
      noMarginTop
      inline
      label={'terms and agreements.'}
      target="_blank"
      to={routes.TERMS_OF_SERVICE}
    />
  </div>