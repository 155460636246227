import { compose } from 'ramda'
import { CunyAdmin } from '../cuny-admin/cuny-admin.component'
import { CunyForgotPassword } from '../cuny/cuny-forgot-password.component'
import { CunyLogin } from '../cuny/cuny-login.component'
import { CunyRegistration } from '../cuny/cuny-registration.component'
import { CunyResetPassword } from '../cuny/cuny-reset-password.component'
import { CunyVerification } from '../cuny/cuny-verification.component'
import { EligibilityContainer } from '../eligibility/eligibility.container'
import { EligibilityStart } from "../eligibility/eligibility-start.component"
import { EligibilityCalculation } from "../eligibility/eligibility-calculation.component"
import { CoursesGradePicker } from "../courses/courses-grade-picker.component"
import { CoursesDegreePicker } from "../courses/courses-degree-picker.component"
import { EligibilityDashboard } from "../eligibility/eligibility-dashboard.component"
import { AidEligibilityDetails } from "../eligibility/aid-eligibility-details.component"
import { ExcelsiorEligibility } from '../excelsior/excelsior-eligibility.component'
import { ExcelsiorLanding } from '../excelsior/excelsior-landing.component'
import { ExcelsiorRegistration } from '../excelsior/excelsior-registration.component'
import { CourseInfo } from '../course-info/course-info.component'
import { InstitutionsList } from '../institutions/institutions-list.component'
import { DocumentsList } from '../landing/documents-list.component'
import { Homepage } from '../landing/homepage.component'
import { About } from '../public/about.component'
import { PrivacyPolicy } from '../public/privacy-policy.component'
import { TermsOfService } from '../public/terms-of-service.component'
import { Registration } from '../registration/registration.component'
import { Home } from '../routing/home.component'
import RulesEditor from '../rules/rules-edit.component'
import { RulesSummary } from '../rules/rules-summary.component'
import { GoogleSignIn } from '../signin/google-signin.component'
import { StudentsList } from '../students/students-list.component'
import { Sms } from '../sms/sms.component'
import { TermEnrollment } from '../term-transition/term-enrollment.component'
import { TermRevision } from '../term-transition/term-revision.component'
import { Dashboard } from "../dashboard/dashboard.component"
import { AidDashboardDetails } from "../dashboard/aid-dashboard-details.component"
import { Quote } from "../quote/quote.component"
import { Students } from "../cuny-admin/students.component"
import { Admins } from "../cuny-admin/admins.component"
import { routes } from "./routes-paths"
import {
  withAdminAccess,
  withCompleteMilestones,
  withCourses,
  withIncompleteMilestone,
  withRegistrationFinished,
  withSavedGoals
} from "./with-guard"
import { CunyResendVerification } from "../cuny/cuny-resend-verification.component"
import { ClaimProfile } from "../cbo/claim-profile.component"

const routesList = [
  {
    path: routes.CBO_CLAIM_PROFILE,
    type: 'route',
    component: ClaimProfile
  },
  {
    path: routes.GOOGLE_SIGN_IN,
    type: 'route',
    component: GoogleSignIn
  },
  {
    path: routes.ABOUT,
    type: 'route',
    component: About
  },
  {
    path: routes.TERMS_OF_SERVICE,
    type: 'route',
    component: TermsOfService
  },
  {
    path: routes.PRIVACY_POLICY,
    type: 'route',
    component: PrivacyPolicy
  },
  {
    path: routes.HOMEPAGE,
    type: 'route',
    component: Homepage
  },
  {
    path: routes.DOCUMENTS_LIST,
    type: 'route',
    component: DocumentsList
  },
  {
    path: routes.CUNY_REGISTRATION,
    type: 'route',
    component: CunyRegistration
  },
  {
    path: routes.CUNY_LOGIN,
    type: 'route',
    component: CunyLogin,
    topBar: {landing: true}
  },
  {
    path: routes.CUNY_FORGOT_PASSWORD,
    type: 'route',
    component: CunyForgotPassword
  },
  {
    path: routes.CUNY_VERIFICATION,
    type: 'route',
    component: CunyVerification
  },
  {
    path: routes.CUNY_RESEND_VERIFICATION,
    type: 'route',
    component: CunyResendVerification
  },
  {
    path: routes.CUNY_RESET_PASSWORD,
    type: 'route',
    component: CunyResetPassword
  },
  {
    path: routes.QUOTE,
    type: 'route',
    component: Quote
  },
  {
    path: routes.EXCELSIOR_ELIGIBILITY,
    type: 'excelsior',
    component: ExcelsiorEligibility
  },
  {
    path: routes.EXCELSIOR_LANDING,
    type: 'excelsior',
    component: ExcelsiorLanding
  },
  {
    path: routes.EXCELSIOR_REGISTRATION,
    type: 'excelsior',
    component: ExcelsiorRegistration
  },
  {
    path: routes.REGISTRATION,
    type: 'private',
    component: Registration
  },
  {
    group: 'ELIGIBILITY',
    path: '/eligibility',
    type: 'private',
    component: compose(
      withRegistrationFinished,
      withCompleteMilestones,
      withCourses
    )(EligibilityContainer),
    subRoutes: [
      {
        path: routes.ELIGIBILITY_START,
        type: 'private',
        component: EligibilityStart,
        topBar: {title: 'Eligibility Calculator'}
      },
      {
        path: routes.ELIGIBILITY_CALCULATING,
        type: 'private',
        component: EligibilityCalculation,
        topBar: {title: 'Eligibility Calculator'}
      },
      {
        path: routes.COURSES_DEGREE_PICKER,
        type: 'private',
        component: CoursesDegreePicker,
        topBar: {title: 'Eligibility Calculator'}
      },
      {
        path: routes.COURSES_GRADE_PICKER,
        type: 'private',
        component: CoursesGradePicker,
        topBar: {title: 'Degree Courses', backPath: '/eligibility/courses/degree'}
      },
      {
        path: routes.ELIGIBILITY_DASHBOARD,
        type: 'private',
        component: EligibilityDashboard,
        topBar: {title: 'Set Goal', backPath: '/eligibility/courses/grades'}
      },
      {
        path: routes.AID_ELIGIBILITY_DETAILS,
        type: 'private',
        component: AidEligibilityDetails,
        topBar: {title: 'Eligibility Results', backPath: '/eligibility/dashboard'}
      }
    ]
  },
  {
    path: routes.AID_DASHBOARD_DETAILS,
    type: 'private',
    component: compose(withRegistrationFinished, withSavedGoals)(AidDashboardDetails),
    topBar: {title: 'Dashboard', backPath: '/dashboard'}
  },
  {
    path: routes.DASHBOARD,
    type: 'private',
    component: compose(withRegistrationFinished, withSavedGoals)(Dashboard)
  },
  {
    path: routes.TERM_ENROLLMENT,
    type: 'private',
    component: withIncompleteMilestone('TERM_ENROLLMENT')(TermEnrollment)
  },
  {
    path: routes.TERM_REVISION,
    type: 'private',
    component: withIncompleteMilestone('ACADEMIC_RECORD_CONFIRMATION')(TermRevision)
  },
  {
    path: routes.COURSE_INFO,
    type: 'private',
    component: withRegistrationFinished(CourseInfo)
  },
  {
    path: routes.ADMIN_RULES_EDITOR,
    type: 'private',
    component: withAdminAccess('ADMIN')(RulesEditor)
  },
  {
    path: routes.ADMIN_RULES_SUMMARY,
    type: 'private',
    component: withAdminAccess('ADMIN')(RulesSummary)
  },
  {
    path: routes.ADMIN_INSTITUTIONS_LIST,
    type: 'private',
    component: withAdminAccess('ADMIN')(InstitutionsList)
  },
  {
    path: routes.ADMIN_SMS,
    type: 'private',
    component: withAdminAccess('ADMIN')(Sms)
  },
  {
    path: routes.ADMIN_STUDENTS_LIST,
    type: 'private',
    component: withAdminAccess('ADMIN')(StudentsList)
  },
  {
    group: 'ADMIN_CUNY',
    path: routes.ADMIN_CUNY,
    type: 'private',
    component: withAdminAccess('CUNY_ADMIN')(CunyAdmin),
    subRoutes: [
      {
        path: routes.ADMIN_CUNY_STUDENTS,
        type: 'private',
        component: withAdminAccess('CUNY_ADMIN')(Students)
      },
      {
        path: routes.ADMIN_CUNY_ADMINS,
        type: 'private',
        component: withAdminAccess('CUNY_ADMIN')(Admins)
      },
    ]
  },
  {
    path: routes.ROOT,
    type: 'private',
    component: Home
  }
]

export const getRouteList = group => {
  return group
    ? routesList.find(route => route.group === group).subRoutes
    : routesList
}