import React from 'react'
import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import { Header } from '../common/header.component'
import loadingAnimation from '../../assets/images/loading-animation.gif'
import { routes } from "../routing/routes-paths"

const styles = theme => ({
  image: {
    width: '40%',
    maxWidth: 280,
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto`
  }
})

export const EligibilityCalculation = pipe(
  withStyles(styles)
)(class extends React.Component {
  componentDidMount() {
    setTimeout(() => this.props.history.push(routes.ELIGIBILITY_DASHBOARD), 5000)
  }

  render() {
    return (
      <div>
        <img className={this.props.classes.image} src={loadingAnimation} alt=""/>

        <Header
          title="Hold tight, it will just take a second"
          subtitle="We're currently getting your course information"
        />
      </div>
    )
  }
})
