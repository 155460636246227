import { withStyles } from '@material-ui/core'
import * as classnames from 'classnames'
import React from 'react'

const styles = theme => ({
  button: {
    background: 'none',
    color: theme.palette.primary.main,
    border: 'none',
    padding: 0,
    font: 'inherit',
    fontSize: '14px',
    fontStyle: 'oblique',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '&:focus': {
      outlineColor: theme.palette.primary.main
    }
  }
})

export const HelpButton = withStyles(styles)(({classes, className, text, onClick}) =>
  <div>
    <button className={classnames(classes.button, className)} onClick={onClick}>{text}</button>
  </div>
)