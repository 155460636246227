import { complement, T, filter, map, pipe } from 'ramda'

const receiving = relation => relation === 'RECEIVING'
const notReceiving = complement(receiving)
const notEnrolled = relation => relation === 'NOT_ENROLLED'

const buildItem = (key, title, checklistTitle, help, eligibleInfo, notEligibleInfo, guard) => ({
  key, title, checklistTitle, help, eligibleInfo, notEligibleInfo, guard
})

export const checklistItems = (relation) => pipe(
  filter(({guard}) => guard(relation)),
  map(({key, title, checklistTitle, help, eligibleInfo, notEligibleInfo}) => ({
    key, title, checklistTitle, help: help(relation), eligibleInfo: eligibleInfo(relation), notEligibleInfo: notEligibleInfo(relation)
  })),
)(CHECKLIST_ITEMS)

const CHECKLIST_ITEMS = [
  buildItem(
    'diplomaOrGed',
    'High school diploma or GED',
    'I have or will have a high school diploma or GED',
    () => 'I have or will have a certificate of graduation from a high school in the United States or have earned a GED or passed the “Ability to Benefit” test before enrollment',
    () => 'You meet the high school diploma/GED requirement',
    () => 'The Excelsior Scholarship is only for students who can prove completion of secondary education',
    notEnrolled
  ),
  buildItem(
    'nysResident',
    'New York State Residency',
    'I am a resident of New York State',
    () => 'I am a resident of New York State or I have lived in New York State for 12 continuous months prior to the beginning of the semester or term',
    () => 'You meet the New York State residency requirement',
    () => 'The Excelsior Scholarship is for New York State Residents only. As a nonresident, you would not be eligible',
    notReceiving
  ),
  buildItem(
    'usCitizen',
    'US Residency',
    'I am a US citizen or eligible non-citizen',
    () => 'I am a US Citizen, have established permanent residency (have a green card) or I have been designated as a refugee paroled by the attorney general of the United States',
    () => 'You meet the citizenship/residency requirement',
    () => 'The Excelsior Scholarship is only for US citizens or green card holders so you wouldn’t be eligible',
    notReceiving
  ),
  buildItem(
    'incomeLimit',
    'Family income',
    'My family income is $125,000 or less',
    () => 'For dependent students, eligibility is based on both the student and their parents’ combined federal adjusted gross income (AGI) on 2017 tax return. Of $125,000 or less. For dependent students, it is based on the custodial parent’s income and for independent students, it is based on combined income of the student and spouse if they are married.',
    () => 'You meet the income requirement',
    () => 'The Excelsior Scholarship is only for applicants who meet the $125,000 income requirement (verified through TAP application)',
    notReceiving
  ),
  buildItem(
    'loanDefaultStatus',
    'Loan or financial aid award default status',
    'I have not defaulted on any loans',
    () => 'If I have State or Federal loans or currently repaying a New York State award, I am not currently in default',
    () => 'You meet the No Default loan requirement',
    () => 'Students in default of a state or federal loan are not eligible for the Excelsior Scholarship',
    notReceiving
  ),
  buildItem(
    'workInNys',
    'Living and working in New York after graduation',
    'I expect to live and work in New York State after graduation',
    () => 'The Excelsior Scholarship requires that you live and work in New York for the same number of years that you received the scholarship. So if you receive it for 4 years, you must work in New York State for 4 years after graduation. If you do not, it converts into a loan',
    () => 'You meet the requirement to work in New York State after graduation',
    () => 'If you do not plan to work in New York State after graduation, the scholarship will convert into a loan',
    notReceiving
  ),
  buildItem(
    'fafsaApplication',
    'Completed 2019-2020 FAFSA Application',
    'I completed FAFSA application for the upcoming year',
    (relation) => receiving(relation)
      ? 'You will need to complete the FAFSA application for the upcoming year by June 30th, 2019'
      : 'You will need to complete FAFSA at least 7-10 days before completing the Excelsior Scholarship Application',
    () => 'You have met the 2019-20 FAFSA application requirement',
    (relation) => receiving(relation)
      ? 'Make sure to complete the FAFSA application by June 30th, 2019'
      : 'Make sure to complete FAFSA at least 7-10 days before applying for the Excelsior Scholarship',
    T
  ),
  buildItem(
    'tapApplication',
    'Completed 2019-2020 TAP application',
    'I have completed the TAP application for the incoming year',
    (relation) => receiving(relation)
      ? 'You will need to complete the NYS State Payment (TAP) Application for the upcoming year by June 30th, 2019'
      : 'You will need to complete the NYS State Payment Application  at least 3-5 days before completing the Excelsior Application',
    () => 'You have met the 2019-20 TAP application requirement',
    (relation) => receiving(relation)
      ? 'Make sure to complete the TAP application by June 30th, 2019'
      : 'Make sure to complete the TAP Application  at least 3-5 days before completing the Excelsior Application',
    T
  ),
]
