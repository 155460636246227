import React from 'react'
import { withRouter } from 'react-router-dom'
import { initialize, trackPageView } from '../../utils/google-analytics'

class GoogleAnalytics extends React.Component {

  componentDidMount() {
    initialize()
    trackPageView(this.props.location.pathname)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const currentPage = this.props.location.pathname
    const nextPage = nextProps.location.pathname

    if (currentPage !== nextPage) {
      document.title = "FutureGrad - " + nextPage
      trackPageView(nextPage)
    }
  }

  render() {
    return null
  }
}

export default withRouter(GoogleAnalytics)
