import Typography from '@material-ui/core/Typography'
import { pipe, prop, filter, sum, map } from 'ramda'
import React from 'react'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const inMajor = goals => filter(prop('inMajor'), goals)
const withoutWithdrawals = goals => filter(({targetGrade}) => targetGrade !== 'W', goals)
const sumCredits = goals => sum(map(prop('credits'), goals))

export const WithdrawalConsequences = pipe(
  e => e
)(({withdrawalPoint, goals}) =>
  <SubSection secondary noPaddingBottom marginBottom>
    <Typography variant='h5'>
      What happens when I withdraw?
    </Typography>
    <div>
      <TableCell heading>Date of Withdrawal</TableCell>
      <Typography variant='body2'>{withdrawalPoint}</Typography>
    </div>
    <SubSection>
      <Typography variant='h5'>
        Change in enrolled credits this semester
      </Typography>
      <Row>
        <TableCell heading>Enrolled Credits</TableCell>
        <TableCell heading center>Current</TableCell>
        <TableCell/>
        <TableCell heading center>With Withdrawal</TableCell>
      </Row>
      <Row>
        <TableCell>Towards degree</TableCell>
        <TableCell center>{pipe(inMajor, sumCredits)(goals)}</TableCell>
        <TableCell center><PlayArrowIcon color='error'/></TableCell>
        <TableCell center>{pipe(inMajor, withoutWithdrawals, sumCredits)(goals)}</TableCell>
      </Row>
      <Row>
        <TableCell>Total</TableCell>
        <TableCell center>{pipe(sumCredits)(goals)}</TableCell>
        <TableCell center><PlayArrowIcon color='error'/></TableCell>
        <TableCell center>{pipe(withoutWithdrawals, sumCredits)(goals)}</TableCell>
      </Row>
    </SubSection>
  </SubSection>
)