import { FormControl } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'
import { StepSection } from './step-section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { SchoolSearch } from '../../registration/basics/school-search.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

export const School = pipe(
  excelsiorRegistrationStep('institutionId', 'About your school'),
  withExcelsiorRegistrationData
)(({handleChange, data: {institutionId, relation}, errors: {institutionId: error}}) =>
  <StepSection
    helpText="The Excelsior Scholarship is only available to undergraduate students enrolled in one of the CUNY, SUNY or statutory colleges at Cornell and Alfred University."
    header={relation === 'NOT_ENROLLED' ? 'Tell us which college will you attend' : 'Tell us which college you attend'}
  >
    <FormControl fullWidth>
      <SchoolSearch
        onChange={institution => handleChange({institutionId: institution.id})}
        value={institutionId}
        error={error}
        placeholder="Select your institution"
        institutionsListPath='/api/institutions/excelsior'
        noLabel
      />
    </FormControl>
  </StepSection>
)