import { pipe } from 'ramda'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { withUser } from '../../providers/user.provider'
import { routes } from "./routes-paths"

export const PrivateRoute = pipe(
  withUser
)(({component: Component, user: {loaded, authenticated}, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      loaded
        ? authenticated
          ? <Component {...props} {...rest} />
          : <Redirect to={{pathname: routes.HOMEPAGE, state: {from: props.location}}}/>
        : null
    }
  />
)

