import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CloseIcon from '@material-ui/icons/Close'
import { keys, toPairs, map } from 'ramda'
import React from 'react'
import { CustomTableCell } from '../course-info/custom-table-cell.component'
import { Section } from '../common/section.component'

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: `0 ${theme.spacing.unit * 3}px`,
    '&:focus': {
      outline: 'none'
    }
  }
})

const HelpDialog = withStyles(styles)(class extends React.Component {
  state = {open: false, data: undefined, activeTerm: '201902'}

  open = (data) => this.setState({open: true, data})
  close = () => this.setState({open: false}, this.props.onClose)
  handleTabChange = (event, value) => this.setState({ activeTerm: value })

  render() {
    return (
      <Dialog
        fullScreen
        open={this.state.open}
        onClose={this.close}
      >
        <div style={{width: '100%', display: 'flex', padding: '8px'}}>
          <div style={{marginLeft: 'auto'}}>
            <CloseIcon nativeColor="#979797" onClick={this.close}/>
          </div>
        </div>
        <div className={this.props.classes.paper}>
          <StudentData activeTerm={this.state.activeTerm} handleTabChange={this.handleTabChange} {...this.state.data}/>
        </div>
      </Dialog>
    )
  }
})

const StudentData = ({activeTerm, handleTabChange, userId, firstName, lastName, email, registrationStep, institutionName, createDateTime, terms, counselingOrganizations, appliedToExcelsior, excelsiorChecklist}) =>
  <div>
    <Section header='General Info'>
      <DataRow label='User Id' value={userId}/>
      <DataRow label='First Name' value={firstName}/>
      <DataRow label='Last Name' value={lastName}/>
      <DataRow label='Email' value={email}/>
      <DataRow label='Institution' value={institutionName}/>
      <DataRow label='Registration Step' value={registrationStep}/>
      <DataRow label='Created at' value={createDateTime}/>
      <DataRow label='Counseling organizations' value={counselingOrganizations.join(', ')}/>
      <DataRow label='Applied to excelsior' value={appliedToExcelsior}/>
    </Section>
    <Section header='Pick proper term'>
      <Tabs value={activeTerm} onChange={handleTabChange} indicatorColor='primary' textColor='primary'>
        {keys(terms).map(term =>
          <Tab key={term} label={term} value={term} />
        )}
      </Tabs>
    </Section>
    {
      terms[activeTerm] &&
      <>
        <Section header='Academic Record'>
          {keys(terms[activeTerm].academicRecord).map(data =>
            <DataRow key={data} label={data} value={terms[activeTerm].academicRecord[data]}/>
          )}
        </Section>
        <Section header='Financial Aid'>
          {keys(terms[activeTerm].financialAid).map(aid =>
            <div>
              <Typography variant='h6'>{aid}</Typography>
              {keys(terms[activeTerm].financialAid[aid]).map(data =>
                <DataRow key={data} label={data} value={terms[activeTerm].financialAid[aid][data]}/>
              )}
            </div>
          )}
        </Section>
        <Section header='Courses'>
          <Table>
            <TableHead>
              <TableRow style={{height: 24}}>
                <CustomTableCell>COURSE NAME</CustomTableCell>
                <CustomTableCell>CREDITS</CustomTableCell>
                <CustomTableCell>TARGET GRADE</CustomTableCell>
                <CustomTableCell>REPEATED GRADES</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {terms[activeTerm].courses.map(({name, credits, targetGradeLabel, targetGradeValue, repeatedGradesLabels}) =>
                <TableRow key={name}>
                  <CustomTableCell>{name}</CustomTableCell>
                  <CustomTableCell>{credits}</CustomTableCell>
                  <CustomTableCell>{`${targetGradeLabel} (${targetGradeValue})`}</CustomTableCell>
                  <CustomTableCell>{repeatedGradesLabels.join(', ')}</CustomTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Section>
      </>
    }
    {excelsiorChecklist &&
      <Section header="Excelsior Checklist">
        {map(
          ([key, value]) => <DataRow key={key} label={key} value={value}/>,
          toPairs(excelsiorChecklist)
        )}
      </Section>
    }
  </div>

const DataRow = ({label, value}) =>
  <div style={{display: 'flex', borderBottom: '1px solid black'}}>
    <div style={{width: 420}}>{label}</div>
    <div style={{width: 360}}>{value !== null ? value.toString() : null}</div>
  </div>

export const withStudentDialog = WrappedComponent => {
  return class extends React.Component {
    dialog = null

    openDialog = (data) => () => this.dialog.open(data)

    render() {
      return (
        <>
          <HelpDialog
            innerRef={ref => this.dialog = ref}
          />
          <WrappedComponent {...this.props} openDialog={this.openDialog} />
        </>
      )
    }
  }
}