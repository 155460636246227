import React from 'react'
import { pipe } from 'ramda'
import { Section } from './section.component'
import { FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/styles'

const styles = (theme) => ({
  formControl: {
    width: "80%"
  },
  formControlFullWidth: {
    width: "100%"
  },
  shrink: {
    textTransform: "uppercase",
    fontSize: "14px !important"
  },
  input: {
    fontSize: 16
  }
})

export const TextInput = pipe(
  withStyles(styles)
)(({label, value, onChange, error, type, classes, noMarginBottom, fullWidth}) =>
  <Section noMarginBottom={noMarginBottom}>
    <FormControl classes={{root: fullWidth ? classes.formControlFullWidth : classes.formControl}}>
      <TextField
        label={label}
        value={value}
        onChange={onChange}
        error={Boolean(error)}
        helperText={error}
        type={type}
        InputProps={{classes: {root: classes.input}}}
        InputLabelProps={{classes: {root: classes.input, shrink: classes.shrink}}}
      />
    </FormControl>
  </Section>
)