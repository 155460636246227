import { find } from 'ramda'

export const FINANCIAL_AID_TYPES = ["TAP", "PELL", "STAFFORD", "EXCELSIOR", "MACAULAY", "FSEOG", "NYC_COUNCIL"]

const TAP = {type: "TAP", name: "TAP", link: "tap"}
const PELL = {type: "PELL", name: "Pell", link: "pell"}
const EXCELSIOR = {type: "EXCELSIOR", name: "Excelsior ", link: "excelsior", applyForLink: "http://apply.for/excelsior"}
const STAFFORD = {type: "STAFFORD", name: "Stafford", link: "stafford"}
const MACAULAY = {type: "MACAULAY", name: "Macaulay", link: "macaulay"}
const NYC_COUNCIL = {type: "NYC_COUNCIL", name: "City Council", link: "nyc_council"}
const FSEOG = {type: "FSEOG", name: "FSEOG", link: "fseog"}
const FEDERAL = {type: "FEDERAL", name: "Federal", link: "federal"}

export const FINANCIAL_AID_LIST = [TAP, PELL, EXCELSIOR, STAFFORD, MACAULAY, NYC_COUNCIL, FSEOG, FEDERAL]
export const getFinancialAidByType = aidType => find(({type}) => type === aidType, FINANCIAL_AID_LIST) || {}
export const aidNameForType = type => getFinancialAidByType(type.toUpperCase()).name
