import React from 'react'
import { AlertDialog } from '../common/alert-dialog.component'

export const withItemActionDialog = WrappedComponent => {
  return class ComponentWithItemActionDialog extends React.Component {
    dialogProps = () => {
      if (this.props.checked) {
        return {
          onConfirm: this.props.onChange,
          confirmation: true
        }
      }

      switch (this.props.type) {
        case 'ADD_COURSE': {
          return {
            title: 'Excellent! Now, update your enrolled credits',
            onConfirm: () => this.props.onChange(() => this.props.history.push('/registration/academic-record'))
          }
        }
        case 'DECLARE_MAJOR': {
          return {
            title: 'Great! This puts on track to keep receiving your TAP grant',
            onConfirm: this.props.onChange
          }
        }
        case 'WITHDRAWAL': {
          return {
            title: 'If you dropped a class, please update your enrolled credits',
            onConfirm: () => this.props.onChange(() => this.props.history.push('/registration/academic-record'))
          }
        }
        default:
          return {
            onConfirm: this.props.onChange,
            confirmation: true
          }
      }
    }

    openDialog = () => console.log('open') || this.dialog.open()

    render() {
      return (
        <>
          <AlertDialog ref={ref => this.dialog = ref} {...this.dialogProps()} />
          <WrappedComponent {...this.props} openDialog={this.openDialog}/>
        </>
      )
    }
  }
}