import React from 'react'
import { pipe } from 'ramda'
import { withApiData } from '../common/with-api-data'
import { withUser } from '../../providers/user.provider'
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { SubSection } from "../common/sub-section.component"
import { Goals } from "./goals.component"
import { EligibilityStatus } from "../eligibility/eligibility-status.component"
import { ExploreCalculator } from "./explore-calculator.component"
import { Reminders } from './reminders.component'
import { Tips } from "./tips.component"
import {DashboardSection} from "./dashboard-section.component"
import { withHelpModal } from "../help/with-help-modal"
import { SimpleHelpModal } from "../common/simple-help-modal.component"

const styles = theme => ({
  dashboard: {
    marginTop: -theme.spacing.unit * 2.5
  }
})

export const Dashboard = pipe(
  withUser,
  withStyles(styles),
  withApiData('/api/goals', {}, 'goals'),
  withApiData('/api/eligibility', {}, 'eligibility'),
  withHelpModal(SimpleHelpModal)
)(
  class extends React.Component {
    state = {
      reminders: []
    }

    componentDidMount() {
      // const disclaimer = this.props.eligibility.academicPrediction.disclaimer
      // if (disclaimer) {
      //   this.props.openModal(disclaimer.title, disclaimer.body, 'Got it!')
      // }

      this.initReminders()
    }

    initReminders() {
      let reminders = this.props.eligibility.financialAid.flatMap(aid =>
        aid.requirements
          .map(({info}) => info.reminder)
          .filter(reminder => reminder != null)
      )

      if (reminders.length === 0) {
        reminders = ["Set up time with your advisor to discuss a Spring (and maybe winter) courseload that is manageable and will get you to graduation faster!"]
      }

      this.setState({reminders})
    }

    render() {
      const {classes, user, goals: {goals}, eligibility} = this.props
      const name = user.name.split(' ')[0]
      const {reminders} = this.state

      return (
        <div className={classes.dashboard}>
          <DashboardSection header={`Hi ${name}!`}>
            {reminders.length !== 0 && <Reminders reminders={reminders}/>}
          </DashboardSection>

          <DashboardSection header="My Projected Goals for Fall 2021">
            <Goals
              goals={goals}
              academicPrediction={eligibility.academicPrediction}
            />
          </DashboardSection>

          <DashboardSection header="Financial Aid Eligibility">
            <EligibilityStatus
              title="Projected Financial Aid Status"
              financialAid={eligibility.financialAid}
              detailsPath="dashboard"
            />

            <ExploreCalculator />
          </DashboardSection>

          <DashboardSection header="Tips from Students">
            <Tips />
          </DashboardSection>

          <DashboardSection header="Next Steps and Timelines">
            <SubSection secondary>
              <Typography variant="body2">
                Get ahead by completing the FAFSA for next year. Forms are available now.
              </Typography>
            </SubSection>
          </DashboardSection>
        </div>
      )
    }
  }
)