import React from 'react'
import { UserConsumer } from '../../providers/user.provider'
import Intercom from 'react-intercom'
import { withRouter } from 'react-router-dom'
import { intercomAppId } from '../../config'
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"

const styles = theme => ({
  noPrint: {
    "@media print": {
      display: 'none'
    }
  }
})

const IntercomReporter =  pipe(
  withStyles(styles)
)(({location, classes}) => (
  <UserConsumer>
    {({id, name, email, institution, degree, yearEnrolled, role, affiliation: {counselingOrganization, consent} = {}}) => {
      return (
        <div className={classes.noPrint}>
          <Intercom appID={intercomAppId} {...{user_id: id, name, email, institution, degree, yearEnrolled, location, role, counselingOrganization, consent, application: "future-grad" }} />
        </div>
      )
    }}
  </UserConsumer>
))

export default withRouter(IntercomReporter)
