export const AFTER_SPRING = "AFTER_SPRING"
export const AFTER_FALL_SPRING = "AFTER_FALL_SPRING"
export const AFTER_FALL_SPRING_SUMMER = "AFTER_FALL_SPRING_SUMMER"
export const SAP_EVALUATION_FREQUENCY_TYPES = [
  {value: AFTER_SPRING, label: "After Spring semester only"},
  {value: AFTER_FALL_SPRING, label: "After Fall and Spring semester"},
  {value: AFTER_FALL_SPRING_SUMMER, label: "After Fall, Spring and Summer semesters"}
]

export const FLAT = "FLAT"
export const EARNED_CREDITS = "EARNED_CREDITS"
export const ATTEMPTED_CREDITS = "ATTEMPTED_CREDITS"
export const SEMESTERS_COMPLETED = "SEMESTERS_COMPLETED"
export const YEARS_COMPLETED = "YEARS_COMPLETED"

export const RULE_TYPES = [
  {value: ATTEMPTED_CREDITS, label: "Attempted credits"},
  {value: SEMESTERS_COMPLETED, label: "Semesters completed"},
  {value: YEARS_COMPLETED, label: "Years completed"},
]
export const GPA_RULE_TYPES = [
  {value: FLAT, label: "Same GPA requirement for all students"},
  {value: EARNED_CREDITS, label: "Earned credits"},
  ...RULE_TYPES
]

export const PACE_RULE_TYPES = [
  {value: FLAT, label: "Same Pace requirement for all students"},
  ...RULE_TYPES
]

export const PERCENTAGE = "PERCENTAGE"
export const PACE_RULE_VALUE_TYPES = [
  {value: PERCENTAGE, label: "%"},
  {value: EARNED_CREDITS, label: "Earned Credits"}
]

export const DEGREE_TYPES = [
  {value: 'ANY', label: "any"},
  {value: 'BACHELOR', label: "bachelor"},
  {value: 'ASSOCIATE', label: "associate"}
]

export const FULL_TIME_STATUSES = [
  {value: 'ANY', label: "any"},
  {value: 'FULL_TIME', label: "full-time"},
  {value: 'PART_TIME', label: "part-time"}
]

export const RULE_VALUE_TYPES = [
  {value: PERCENTAGE, label: "Pace (Enrolled/Attempted Credits - % requirement)"},
  {value: EARNED_CREDITS, label: "Pace (Earned Credits)"},
  {value: 'GPA', label: 'GPA'}
]

export const getLabel = (ruleTypes, type) => ruleTypes.find(({value}) => value === type).label
