
const enrolledInText = year => `Enrolled in ${year}`

export const FIRST_ENROLL_OPTIONS = ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020']
  .map(year => ({value: year, label: enrolledInText(year)}))

export const DEGREE_TYPE_OPTIONS = [
  {value: "BACHELOR", label: "Bachelor's"},
  {value: "ASSOCIATE", label: "Associate's"}
]
