import React from 'react'
import { Redirect } from 'react-router-dom'
import { withUser } from '../../providers/user.provider'
import { isEmpty, pipe } from "ramda"
import { routes } from "./routes-paths"

const withGuard = (guard, redirectTo) => (WrappedComponent) => pipe(
  withUser
)(({user, ...props}) =>
  guard(user)
    ? <WrappedComponent {...props} />
    : <Redirect to={redirectTo} />
)

const isPermittedFor = (role, adminLevel) =>
  (role === 'ADMIN') ||
  (role === 'CUNY_SUPERADMIN' && (adminLevel === 'CUNY_SUPERADMIN' || adminLevel === 'CUNY_ADMIN')) ||
  (role === 'CUNY_ADMIN' && adminLevel === 'CUNY_ADMIN')

export const withAdminAccess = (adminLevel) => withGuard(
  ({role}) => isPermittedFor(role, adminLevel),
  routes.ROOT
)

export const withCompleteMilestones = withGuard(
  ({incompleteMilestone}) => isEmpty(incompleteMilestone),
  routes.REGISTRATION
)

export const withCourses = withGuard(
  ({hasCourses}) => hasCourses,
  routes.COURSE_INFO
)

export const withIncompleteMilestone = milestone => withGuard(
  ({incompleteMilestone}) => incompleteMilestone.milestone === milestone,
  routes.DASHBOARD
)

export const withSavedGoals = withGuard(
  ({hasGoals}) => hasGoals,
  routes.ELIGIBILITY_START
)

export const withRegistrationFinished = withGuard(
  ({registrationStatus}) => registrationStatus === 'finished',
  routes.REGISTRATION
)