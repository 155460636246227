import { Grid, IconButton, Input } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'

const HeadersRow = ({rangeLabel, valueTypeComponent}) =>
  <>
    <Grid item xs={1}>
    </Grid>
    <Grid item xs={5}>
      {rangeLabel}
    </Grid>
    <Grid item xs={2}>
    </Grid>
    <Grid item xs={2}>
      {valueTypeComponent}
    </Grid>
    <Grid item xs={2}>
    </Grid>
  </>

class RangeRuleRow extends React.Component {
  componentDidMount() {
    this.props.to === "" && this.props.lastRow && this.fromInput.focus()
  }

  onChange = key => event =>
    this.props.onRuleChange({
      from: this.props.from, to: this.props.to, value: this.props.value, [key]: event.target.value
    })

  onKeyDown = event =>
    this.props.lastRow && event.key === 'Enter' && this.props.addRow()

  render() {
    const {from, to, value, onRuleDelete, lastRow} = this.props
    return (
      <>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={2}>
          <Input inputRef={ref => this.fromInput = ref} placeholder="range start" onChange={this.onChange('from')} value={from}/>
        </Grid>
        <Grid item xs={1}>
          -
        </Grid>
        <Grid item xs={2}>
          <Input placeholder="range end" onChange={this.onChange('to')} value={to}/>
        </Grid>
        <Grid item xs={2}>
        </Grid>
        <Grid item xs={2}>
          <Input placeholder="value" onChange={this.onChange('value')} value={value} onKeyDown={this.onKeyDown}/>
        </Grid>
        <Grid item xs={2}>
          {
            lastRow &&
            <IconButton onClick={onRuleDelete} aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          }
        </Grid>
      </>
    )
  }
}

const AddRuleRow = ({onClick}) =>
  <>
    <Grid item xs={1}>
      <IconButton onClick={onClick} aria-label="Delete">
        <AddIcon />
      </IconButton>
    </Grid>
    <Grid item xs={11}>
    </Grid>
  </>

export const RangeRule = ({values, onChange, label, valueTypeComponent}) => {
  const handleChangeRow = index => ({from, to, value}) => {
    const newValues = [...values]
    newValues.splice(index, 1, {from, to, value})
    onChange(newValues)
  }

  const handleAddRow = () => {
    onChange([...values, {from: '', to: '', value: ''}])
  }

  const handleDeleteRow = index => () => {
    const newValues = [...values]
    newValues.splice(index, 1)
    onChange(newValues)
  }

  return (
    <div style={{flexGrow: 1, width: '600px', margin: '16px auto'}}>
      <Grid container spacing={8}>
        <Grid container item xs={12} spacing={24}>
          <HeadersRow rangeLabel={label} valueTypeComponent={valueTypeComponent}/>
          {values.map((rule, index) =>
            <RangeRuleRow
              key={index}
              {...rule}
              onRuleChange={handleChangeRow(index)}
              onRuleDelete={handleDeleteRow(index)}
              lastRow={index === values.length - 1}
              addRow={handleAddRow}
            />
          )}
          <AddRuleRow onClick={handleAddRow}/>
        </Grid>
      </Grid>
    </div>
  )
}