import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { Section } from '../../common/section.component'
import { excelsiorRegistrationStep } from '../excelsior-registration-step.component'
import { withExcelsiorRegistrationData } from '../excelsior-registration-data.provider'

const relations = [
  {key: 'RECEIVING', label: 'I currently have the Excelsior Scholarship'},
  {key: 'INTERESTED_IN', label: 'I’m in college and applying for the Excelsior Scholarship'},
  {key: 'NOT_ENROLLED', label: 'I’m not yet in college and applying for the Excelsior Scholarship'},
]

export const Relation = pipe(
  excelsiorRegistrationStep('relation'),
  withExcelsiorRegistrationData
)(({handleChange, handleNextClick, data: {relation}, errors: {relation: error}}) =>
    <Section header='Start by telling us about yourself'>
      {
        Boolean(error) &&
        <Typography variant="body2" color="error">{error}</Typography>
      }
      {relations.map(({key, label}) =>
        <Button
          key={key}
          onClick={() => handleChange({relation: key}, handleNextClick('relation'))}
          variant={relation === key ? 'contained' : 'outlined'}
          color='primary'
          style={{width: '100%', marginBottom: 8}}
        >
          {label}
        </Button>
      )}
    </Section>
)