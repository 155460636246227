import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import graduationCapIcon from '../../assets/images/graduation_cap_icon.svg'
import checklistIllustration from '../../assets/images/checklist_illustration.png'
import { withUser } from '../../providers/user.provider'
import { TipBox } from '../common/tip-box.component'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import { withApiData } from '../common/with-api-data'
import { withEligibilityData } from '../eligibility/eligibility-data.provider'
import { withHelpModal } from '../help/with-help-modal'
import { Dropdown } from '../registration/common/dropdown.component'
import { Section } from '../common/section.component'
import { WithdrawalPastDeadlineAlert } from './withdrawal-past-deadline-alert.component'
import { WithdrawalPoint } from './withdrawal-point.component'
import { Image } from "../common/image.component"
import { ActionSection } from "../common/action-section.component"
import { Button } from "../common/button.component"
import { routes } from "../routing/routes-paths"
import { TextLink } from "../common/text-link.component"
import { trackEvent } from "../../utils/intercom-analytics"

export const CoursesGradePicker = pipe(
  withStyles({}),
  withEligibilityData,
  withUser,
  withHelpModal(WithdrawalPastDeadlineAlert)
)(class extends React.Component {
  displayName = 'CoursesGradePicker'

  trackIntercomEvent = () => {
    const goals = this.props.goals.reduce((obj, goal) => {
      obj[goal.name] = goal.credits + " " + goal.targetGrade
      return obj
    }, {})
    trackEvent('used-eligibility-calculator', goals)
  }

  handleNextClick = () => {
    if (this.props.isPastWithdrawalDeadline()) {
      return this.props.openModal()
    }

    this.trackIntercomEvent()

    this.props.saveGoals(() =>
      this.props.user.refreshUserData(() =>
        this.props.getEligibilityByGoals(() =>
          this.props.history.push(routes.ELIGIBILITY_CALCULATING)
        )
      )
    )
  }

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <Typography variant="h4">Set your goal for your grades this semester</Typography>
        <TipBox image={<Image src={checklistIllustration} />} title="Did you know" primary>
          You need to pass all 12 credits this semester to maintain your financial aid.
        </TipBox>
        <SubSection>
          <Typography variant="h5">What grades do you plan to get this semester?</Typography>
          <Row>
            <TableCell heading>CLASSES FOR FALL 2021</TableCell>
            <TableCell heading center>CREDITS</TableCell>
            <TableCell heading center>TARGET GRADE</TableCell>
          </Row>
          <CourseList courses={this.props.goals} handleGradeChange={this.props.handleGradeChange}/>
        </SubSection>
        {this.props.isWithdrawalPresent() && <WithdrawalPoint
          getWithdrawalPoint={this.props.getWithdrawalPoint}
          handleWithdrawalPointChange={this.props.handleWithdrawalPointChange}
        />}

        <ActionSection>
          <Button dataTour="calculate_button" text="Calculate eligibility" onClick={this.handleNextClick} disabled={this.props.notAllGoalsSet()}/>
          <TextLink label="Edit courses" to={routes.COURSE_INFO} />
        </ActionSection>
      </Section>
    )
  }
})

const CourseList = pipe(
  withApiData('/api/grades')
)(({data: grades, courses, handleGradeChange}) =>
  <>
    {courses.map((course, idx) =>
      <CourseRow
        key={idx}
        grades={grades}
        course={course}
        handleGradeChange={handleGradeChange(idx)}
      />
    )}
  </>
)

const styles = theme => ({
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  graduationCapCell: {
    display: 'flex',
    marginRight: 2,
    width: theme.spacing.unit * 2
  },
  graduationIcon: {
    width: theme.spacing.unit * 2
  }
})

const filterOutWs = (grades, _userEmail) => grades
  // /.+@(u2i.com|getyenko.com)$/.test(userEmail) ? grades : grades.filter(({label}) => label !== 'W')

const CourseRow = pipe(
  withStyles(styles),
  withUser
)(({classes, grades, handleGradeChange, course: {name, credits, inMajor, targetGradeId}, user: {email}}) => {
  const changeGrade = event =>
    handleGradeChange({value: event.target.value, label: grades.find(({id}) => id === event.target.value).label})

  return (
    <Row withBorder>
      <TableCell>
        <div className={classes.nameCell}>
          <div className={classes.graduationCapCell}>
            {inMajor && <img src={graduationCapIcon} alt="In major" className={classes.graduationIcon} />}
          </div>
          <div>{name}</div>
        </div>
      </TableCell>
      <TableCell center>{credits}</TableCell>
      <TableCell center>
        <Dropdown
          placeholder="Select"
          displayEmpty
          filled
          wide
          options={filterOutWs(grades, email).map(({id, label}) => ({value: id, label: label}))}
          value={targetGradeId || ''}
          onChange={changeGrade}
        />
      </TableCell>
    </Row>
  )
})