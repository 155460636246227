import { Typography } from '@material-ui/core'
import React from 'react'
import { Input } from '../common/input.component'
import { PellLifetimeEligibilityHelp } from '../help/pell-lifetime-eligibility-help.component'
import { Section } from '../../common/section.component'

export const PellDetails = ({lifetimeEligibilityUsed, handleLifetimeEligibilityUsedChange, errors}) =>
  <>
    <Typography variant="h5">Pell Grant</Typography>
    <Section
      header="What is your Pell Lifetime Eligibility (LEU) Used?"
      helpText="What is this?"
      helpModal={PellLifetimeEligibilityHelp}
      trackingLabel="Pell - eligibility"
    >
      <Input
        value={lifetimeEligibilityUsed}
        onChange={handleLifetimeEligibilityUsedChange}
        error={errors.lifetimeEligibilityUsed}
        adornment={'%'}
      />
    </Section>
  </>
