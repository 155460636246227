import { apiUrl } from '../config'
import URLSearchParams from '@ungap/url-search-params'

const handleResponse = responseHandler => (onSuccess, onError, onResponse) => response =>
  responseHandler(response).then((data) => {
    if (onResponse) {
      onResponse(response.status, data)
    } else {
      switch (response.status) {
        case 200: {
          onSuccess && onSuccess(data)
          break
        }
        default: {
          onError && onError(new Error((data && data.data && data.data.message) || 'API Error'))
        }
      }
    }
  })

const handleJsonResponse = handleResponse(response => response.json())

const handleFileResponse = handleResponse(response => response.blob())

const _fetch = (contentType, responseHandler) => (path, onSuccess, onError) =>
  fetch(apiUrl(path), {
    method: 'GET',
    headers: {
      'Content-Type': contentType
    },
    credentials: 'same-origin'
  })
  .then(responseHandler(onSuccess, apiError(onError, path)))
  .catch(error => {
    if(onError)
      apiError(onError, path)()
    else
      throw error
  })

export const fetchData = _fetch('application/json', handleJsonResponse)

export const fetchCsv = _fetch('text/csv', handleFileResponse)

export const postData = (path, body, onSuccess, onError) =>
  fetch(apiUrl(path), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(body)
  })
  .then(handleJsonResponse(onSuccess, apiError(onError, path, body)))
  .catch(error => {
    if(onError)
      apiError(onError, path, body)()
    else
      throw error
  })

export const postDataWithHandler = (path, body, onResponse) =>
  fetch(apiUrl(path), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(body)
  })
  .then(handleJsonResponse(null, null, onResponse))

export const deleteData = (path, body, onSuccess, onError) =>
  fetch(apiUrl(path), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(body)
  })
  .then(handleJsonResponse(onSuccess, apiError(onError, path)))
  .catch(error => {
    if(onError)
      apiError(onError, path)()
    else
      throw error
  })

export const paramFromQueryString = (paramName, queryString) => {
  const params = new URLSearchParams(queryString)
  return params.get(paramName)
}

const apiError = (onError, path, body = {}) => (errorMessage) => {
  const error = new Error(`${errorMessage || 'API Error'} | path: ${path} | body: ${JSON.stringify(body)}`)
  if (onError) onError(error); else throw error
}

