import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import * as React from 'react'
import { withUser } from '../../providers/user.provider'
import { postData } from '../../utils/api-utils'
import { withHelpModal } from '../help/with-help-modal'
import { Input } from '../registration/common/input.component'
import { NavigationButtons } from '../registration/common/navigation-buttons.component'
import { Section } from '../common/section.component'
import { withDataValidation } from '../registration/common/with-data-validation'
import { YesNoButtons } from '../registration/common/yes-no-buttons.component'
import { SuccessAlert } from './success-alert.component'
import { routes } from "../routing/routes-paths"

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    margin: `${theme.spacing.unit * 2}px 0`
  }
})

export const TermEnrollment = pipe(
  withStyles(styles),
  withUser,
  withHelpModal(SuccessAlert),
  withDataValidation({
    enrolledCredits: {
      guard: ({enrollInSummer, enrolledCredits}) => enrollInSummer === false || parseInt(enrolledCredits, 10) > 0,
      msg: 'Required',
      required: false
    }
  })
)(class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {enrollInSummer: false, enrolledCredits: '0', term: this.props.user.incompleteMilestone.term}
  }

  handleNextClick = () => this.props.validate(this.state) && postData(
    '/api/term-enrollment',
    this.state,
    () => {
      this.props.openModal()
      setTimeout(
        () => this.props.user.refreshUserData(
          () => this.props.history.push(routes.ROOT)
        ),
        2000
      )
    }
  )

  handleEnrollInSummerChange = enrollInSummer => () => this.setState({enrollInSummer})
  handleEnrolledCreditsChange = event => this.setState({enrolledCredits: event.target.value})

  render() {
    const {classes} = this.props
    return (
      <div className={classes.root}>
        <Typography variant="h3" color="inherit">Summer Enrollment</Typography>
        <div className={classes.body}>
          <Section header='Do you plan to take summer classes?'>
            <YesNoButtons
              value={this.state.enrollInSummer}
              handleYesClick={this.handleEnrollInSummerChange(true)}
              handleNoClick={this.handleEnrollInSummerChange(false)}
            />
          </Section>

          {this.state.enrollInSummer && <Section header='How many credits do you plan to enroll to?'>
            <Input
              onChange={this.handleEnrolledCreditsChange}
              placeholder='Ex 3'
              value={this.state.enrolledCredits}
              error={this.props.errors.enrolledCredits}
            />
          </Section>
          }
        </div>
        <NavigationButtons handleNextClick={this.handleNextClick} nextLabel='Update data'/>
      </div>
    )
  }
})