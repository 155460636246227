import { pipe } from 'ramda'
import React from 'react'
import { withStyles } from '@material-ui/core'

import { withUser } from '../../providers/user.provider'
import { Header } from '../common/header.component'
import { TextInput } from '../common/text-input.component'
import { TextLink } from '../common/text-link.component'
import { withHelpModal } from '../help/with-help-modal'
import { withDataValidation } from '../registration/common/with-data-validation'
import { SimpleHelpModal } from '../common/simple-help-modal.component'
import cunyLandingImage from '../../assets/images/cuny_landing.jpg'
import { ActionSection } from "../common/action-section.component"
import { Button } from "../common/button.component"
import { routes } from "../routing/routes-paths"
import { getErrorMessage } from "./error-messages"
import { Section } from "../common/section.component"

const styles = theme => ({
  landingImage: {
    width: 'calc(100% + 40px)',
    margin: '-20px 0 10px -20px',
  }
})

export const CunyLogin = pipe(
  withDataValidation({
    username: {msg: "Please enter your email address"},
    password: {msg: "Please enter your password"}
  }),
  withUser,
  withHelpModal(SimpleHelpModal),
  withStyles(styles)
)(class extends React.Component {
  displayName = 'CunyLogin'

  state = {username: '', password: ''}

  handleInputChange = key => event => this.setState({[key]: event.target.value})

  handleLoginClick = () => this.props.validate(this.state) && this.props.user.login(
    this.state.username, this.state.password,
    () => this.props.history.push(routes.ROOT),
    ({data: {reason}}) => this.props.openModal(...getErrorMessage(reason))
  )

  propsForTextInput = key => ({
    value: this.state[key],
    onChange: this.handleInputChange(key),
    error: this.props.errors[key]
  })

  render() {
    return (
      <Section fullHeight noMarginBottom>
        <img src={cunyLandingImage} className={this.props.classes.landingImage} alt="" />

        <Header
          title="Hey future grad!"
          subtitle="Login with your CUNY email"
        />

        <TextInput label="Email" {...this.propsForTextInput('username')} noMarginBottom />
        <TextInput label="Password" type="password" {...this.propsForTextInput('password')} noMarginBottom />

        <TextLink to={routes.CUNY_FORGOT_PASSWORD} label="I forgot my password" />

        <ActionSection>
          <Button text="Log In" onClick={this.handleLoginClick} />
          <TextLink label="Sign Up" to={routes.CUNY_REGISTRATION} />
        </ActionSection>
      </Section>
    )
  }
})
