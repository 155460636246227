import React from 'react'
import { pipe } from 'ramda'
import { Link } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withUser } from '../../providers/user.provider'
import { Menu } from '../layout/menu.component'
import futureGradLogotypeColor from '../../assets/images/futuregrad_logotype_color.svg'
import futureGradLogotypeWhite from '../../assets/images/futuregrad_logotype_white.svg'
import futureGradLogomarkWhite from '../../assets/images/futuregrad_logomark_white.svg'
import backArrow from '../../assets/images/back_arrow.svg'
import { routes } from '../routing/routes-paths'

const styles = theme => ({
  heading: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '-3px 0 0 6px',
    fontSize: 20
  },
  logotype: {
    width: theme.spacing.unit * 20,
  },
  logomark: {
    width: 28,
    color: 'white'
  },
  backArrow: {
    width: 24,
    padding: 2,
    marginLeft: 4,
    color: 'white'
  },
  toTheRight: {
    marginLeft: 'auto'
  }
})

const TopBar = pipe(
  withStyles(styles),
  withUser
)(({classes, children, color, user: {authenticated, logout}}) =>
  <AppBar position="static" color={color || "primary"}>
    <Toolbar variant="dense">
      <div className={classes.heading}>
        {children}
      </div>

      <div className={classes.toTheRight}>
      {authenticated && 
        <Menu logout={logout}/>
      }
      </div>
    </Toolbar>
  </AppBar>
)

const BackLink = pipe(
  withStyles(styles),
)(({classes, path}) =>
  <Link to={path} className={classes.undecoratedLink}>
    <img src={backArrow} className={classes.backArrow} alt="Go back" />
  </Link>
)

const LandingTopBar = pipe(
  withStyles(styles)
)(({classes}) =>
  <TopBar color="inherit">
    <img src={futureGradLogotypeColor} className={classes.logotype} alt="FutureGrad logo" />
  </TopBar>
)

const TopBarWithLogotype = pipe(
  withStyles(styles)
)(({classes}) => 
  <TopBar>
    <img src={futureGradLogotypeWhite} className={classes.logotype} alt="FutureGrad logo" />
  </TopBar>
)

const TopBarWithLogomarkAndTitle = pipe(
  withStyles(styles)
)(({classes, title}) => 
  <TopBar>
    <Link to={routes.DASHBOARD}>
      <img src={futureGradLogomarkWhite} className={classes.logomark} alt="FutureGrad logo" />
    </Link>
    <Typography variant="h4" className={classes.title} color="inherit">{title}</Typography>
  </TopBar>
)

const TopBarWithBackButtonAndTitle = pipe(
    withStyles(styles)
)(({classes, backPath, title}) =>
  <TopBar>
    <BackLink path={backPath}/>
    <Typography variant="h4" className={classes.title} color="inherit">{title}</Typography>
  </TopBar>
)

export const TopBarContainer = ({landing, title, backPath}) => {
  if (landing) {
    return <LandingTopBar/>
  } else if (title && backPath) {
    return <TopBarWithBackButtonAndTitle title={title} backPath={backPath}/>
  } else if (title) {
    return <TopBarWithLogomarkAndTitle title={title} />
  } else {
    return <TopBarWithLogotype/>
  }
}

// const TopBar = pipe(
//   withStyles(logoStyles)
// )(({classes}) =>
//  <AppBar position="static" color="white">
//    <Toolbar>
//      <div className={classes.heading}>
//        <div className={classes.logo}><FutureGradLogomarkColor /></div>
//        <Typography className="bold" color="primary" variant="h6">Future</Typography>
//        <Typography className="bold" color="secondary" variant="h6">Grad</Typography>
//      </div>
//    </Toolbar>
//  </AppBar>
// )