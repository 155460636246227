import React from "react"
// import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core"
import { pipe } from "ramda"
// import { TipBox } from "../common/tip-box.component"
import placeholderImage from '../../assets/images/tips_placeholder.png'
// import { routes } from "../routing/routes-paths"

const styles = theme => ({
  link: {
    textDecoration: 'none'
  },
  tips: {
    margin: '0 -20px',
    // padding: '20px',
    // backgroundColor: theme.palette.background.yellow,
    // color: theme.palette.primary.main
  },
  placeholder: {
    width: '100%'
  }
})

export const Tips = pipe(
  withStyles(styles)
)(({classes}) =>
  <div className={classes.tips}>
    <img src={placeholderImage} className={classes.placeholder} alt="tips from students"/>
  {/*  <TipBox title="Tips from classmates" fullWidth>*/}
  {/*    Get a planner at the beginning of the year and actually use it*/}
  {/*  </TipBox>*/}
  {/*  <TipBox title="Video" fullWidth>*/}
  {/*    Learn about how Gabe uses a syllabus to stay on track*/}
  {/*  </TipBox>*/}

  {/*  <div>*/}
  {/*    <Link to={routes.DASHBOARD} className={classes.link}>*/}
  {/*      <Typography variant="subtitle2" color="primary">READ MORE ></Typography>*/}
  {/*    </Link>*/}
  {/*  </div>*/}
  </div>
)