import { withStyles } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Button } from '../common/button.component'
import { LinkButton } from "../common/link-button.component"

const styles = theme => ({
  paper: {
    position: 'absolute',
    margin: 'auto',
    top: '80px',
    left: '16px',
    right: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 4,
    maxWidth: '960px',
    '&:focus': {
      outline: 'none'
    }
  },
  action: {
    marginTop: theme.spacing.unit * 6
  }
})

export const HelpModal = withStyles(styles)(class extends React.Component {
  state = {open: false, title: undefined, content: undefined, actionLabel: undefined, linkTo: undefined}

  open = (title, content, actionLabel, linkTo) => this.setState({open: true, title, content, actionLabel, linkTo})
  close = () => this.setState({open: false}, this.props.onClose)

  render() {
    const title = this.state.title || this.props.title
    const content = this.state.content || this.props.children
    const actionLabel = this.state.actionLabel || this.props.actionLabel
    const linkTo = this.state.linkTo || this.props.linkTo

    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.close}
        >
          <div className={this.props.classes.paper}>
            <Typography variant="body1" className="bold" id="modal-title" gutterBottom>
              {title}
            </Typography>

            {content instanceof Object
              ? <Typography variant="body2" id="simple-modal-description">
                  {content}
                </Typography>
              : <Typography variant="body2" id="simple-modal-description" dangerouslySetInnerHTML={{__html: content}}/>
            }

            { actionLabel &&
              <div className={this.props.classes.action}>
                {linkTo
                  ? <LinkButton label={actionLabel} to={linkTo} />
                  : <Button text={actionLabel} onClick={this.close} />
                }
              </div>
            }
          </div>
        </Modal>
      </div>
    )
  }
})