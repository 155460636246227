import Typography from '@material-ui/core/Typography'
import React from 'react'

import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = theme => ({
  section: {
    margin: '0 -20px',
    padding: '20px'
  },
  primary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  secondary: {
    backgroundColor: theme.palette.background.grey,
    color: theme.palette.common.black
  },
  marginBottom: {
    marginBottom: theme.spacing.unit * 2
  },
  noPaddingBottom: {
    paddingBottom: 0
  }
})

export const SubSection = withStyles(styles)(({classes, primary, secondary, border, children, marginBottom, noPaddingBottom, header, dataTour}) =>
  <div data-tour={dataTour} className={classNames({
    [classes.section]: true,
    [classes.primary]: primary,
    [classes.secondary]: secondary,
    [classes.border]: border,
    [classes.marginBottom]: marginBottom,
    [classes.noPaddingBottom]: noPaddingBottom
  })}>
    {
      header &&
      <Typography
        color='inherit'
        variant={primary ? 'h3' : 'h4'}
        style={{marginBottom: 0}}
      >{header}</Typography>
    }
    {children}
  </div>
)