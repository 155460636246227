import { FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { pipe } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import { HelpLinkSmall } from '../../help/help-link-small.component'
import { withDataValidation } from '../common/with-data-validation'
import { Section } from '../../common/section.component'
import { Dropdown } from '../common/dropdown.component'
import { DEGREE_TYPE_OPTIONS, FIRST_ENROLL_OPTIONS } from './basics-options'
import { RegistrationStep } from '../common/registration-step.component'
import { SchoolSearch } from './school-search.component'

export const Basics = pipe(
  withDataValidation({
    name: {
      guard: ({firstName, lastName}) => firstName !== undefined && lastName !== undefined,
      msg: 'First and last name is required'
    },
    institutionId: {}, degreeType: {}, yearEnrolled: {}
  })
)(class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {data: this.props.data, name: `${this.props.data.firstName} ${this.props.data.lastName}`}
  }

  handleInstitutionChange = institution => this.setState({data: {...this.state.data, institutionId: institution.id}})
  handleDegreeTypeChange = event => this.setState({data: {...this.state.data, degreeType: event.target.value}})
  handleFirstEnrolledChange = event => this.setState({data: {...this.state.data, yearEnrolled: event.target.value}})
  handleNameChange = event => {
    const name = event.target.value
    if (name.trim().includes(' ')) {
      const [firstName, ...lastName] = name.split(' ').filter(Boolean)
      this.setState({name, data: {...this.state.data, firstName, lastName: lastName.join(' ')}})
    } else {
      this.setState({name, data: {...this.state.data, firstName: name, lastName: undefined}})
    }
  }

  handleNextClick = () => {
    this.props.validate(this.state.data) && this.props.onSubmit(this.state.data, true)
  }

  render() {
    return (
      <RegistrationStep
        stepTitle="The basics (1/5)"
        title="The basics"
        handleNextClick={this.handleNextClick}
      >
        <Section>
          <FormControl fullWidth>
            <TextField
              label="What's your name?"
              value={this.state.name}
              onChange={this.handleNameChange}
              error={Boolean(this.props.errors.name)}
              helperText={this.props.errors.name}
            />
          </FormControl>
        </Section>
        <Section>
          <FormControl fullWidth>
            <SchoolSearch
              institutions={this.state.institutions}
              onChange={this.handleInstitutionChange}
              value={this.state.data.institutionId}
              error={this.props.errors.institutionId}
            />
          </FormControl>
          <Link to='/registration/missing-school'>
            <HelpLinkSmall text="Can't find your school?"/>
          </Link>
        </Section>
        <Section>
          <FormControl fullWidth>
            <Dropdown
              placeholder="When did you first enroll?"
              label="When did you first enroll?"
              options={FIRST_ENROLL_OPTIONS}
              value={this.state.data.yearEnrolled}
              onChange={this.handleFirstEnrolledChange}
              error={this.props.errors.yearEnrolled}
            />
          </FormControl>
        </Section>
        <Section>
          <FormControl fullWidth>
            <Dropdown
              placeholder="What degree are you seeking?"
              label="What degree are you seeking?"
              options={DEGREE_TYPE_OPTIONS}
              value={this.state.data.degreeType}
              onChange={this.handleDegreeTypeChange}
              error={this.props.errors.degreeType}
            />
          </FormControl>
        </Section>
      </RegistrationStep>
    )
  }
})
