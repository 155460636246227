import { includes, without } from 'ramda'
import React from 'react'

export const withAidSelection = (WrappedComponent) => {
  class ComponentWithAidSelection extends React.Component {
    state = {financialAid: []}

    componentDidMount() {
      this.setState({financialAid: this.props.values})
    }

    handleAidSelection = (aid) => () =>
      includes(aid, this.state.financialAid)
        ? this.setState({financialAid: without(aid, this.state.financialAid)})
        : this.setState({financialAid: [...this.state.financialAid, aid]})

    handleNextClick = () => this.props.onSubmit(this.state.financialAid, true)
    handleBackClick = () => this.props.onSubmit(this.state.financialAid, false)

    render() {
      return (
        <WrappedComponent
          {...this.props}
          financialAid={this.state.financialAid}
          handleAidSelection={this.handleAidSelection}
          handleNextClick={this.handleNextClick}
          handleBackClick={this.handleBackClick}
        />
      )
    }
  }

  return ComponentWithAidSelection
}