import React from 'react'
import { pipe } from 'ramda'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { postData } from '../../utils/api-utils'
import { CheckboxWithLabel } from "../registration/common/checkbox.component"

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  marginBottom: {
    marginBottom: theme.spacing.unit * 4
  }
})

export const Sms = pipe (
  withStyles(styles),
)(class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      body: '',
      mediaUrl: '',
      checkbox: false,
      status: '',
      legalInfo: 'Msg&Data rates may apply. Reply STOP to cancel.'
    }
  }

  handleFiledChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCheckboxChange = () => {
    const legalInfo = '\n\n' + this.state.legalInfo
    this.setState(prevState => ({
      checkbox: !prevState.checkbox,
      body: !prevState.checkbox ? prevState.body + legalInfo : prevState.body.replace(legalInfo, '')
    }))
  }

  handleSendClick = () => {
    this.setState({ status: '' }, () =>
      postData('/api/admin/sms', this.state, this.onSuccess, this.onError)
    )
  }

  onSuccess = () => {
    this.setState({
      status: 'Success',
      phone: '',
      body: '',
      mediaUrl: '',
      checkbox: false,
    })
  }

  onError = () => {
    this.setState({ status: 'Error' })
  }

  render() {
    const {classes} = this.props

    return (
      <div>
        <Typography variant="h3" color="inherit">SMS/MMS</Typography>

        <TextField
          fullWidth
          name="phone"
          label="Phone number"
          value={this.state.phone}
          onChange={this.handleFiledChange}
        />

        <TextField
          fullWidth
          multiline
          name="body"
          label="Message body"
          value={this.state.body}
          onChange={this.handleFiledChange}
        />

        <TextField
          fullWidth
          className={classes.marginBottom}
          name="mediaUrl"
          label="GIF URL (optional)"
          value={this.state.mediaUrl}
          onChange={this.handleFiledChange}
        />

        <div className={classes.marginBottom}>
          <CheckboxWithLabel
            checked={this.state.checkbox}
            onChange={this.handleCheckboxChange}
            label={`Include: "${this.state.legalInfo}"`}
          />
        </div>

        <div className={classes.container}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSendClick}
          >
            Send
          </Button>
        </div>
        <div className={classes.container}>
            {this.state.status}
          </div>
      </div>
    )
  }
})