import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withApiData } from '../common/with-api-data'
import { pipe } from 'ramda'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import RulesEditor from './editor/rules-editor.component';
import { DEGREE_TYPES, getLabel, PERCENTAGE, FULL_TIME_STATUSES } from './rules';
import { ActionButtons } from './editor/action-buttons.component';
import { postData } from '../../utils/api-utils';

const MIN_RULES_COUNT = 0
const MAX_RULES_COUNT = 4

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`
 }
});

const tabContainerStyles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  }
})

const TabContainer = withStyles(tabContainerStyles)(({classes, children}) => {
  return (
    <Typography component="div" className={classes.root}>
      {children}
    </Typography>
  );
})

const RulesEdit = pipe(
  withApiData(props => `/api/admin/rules/${props.match.params.institutionId}`),
  withStyles(styles)
)(class extends React.Component {
  state = {
    institutionName: this.props.data[0].institutionName,
    institutionId: undefined,
    value: 0,
    data: this.props.data
  }

  gpaDefault = {type: undefined, value: undefined}
  paceDefault = {type: undefined, value: undefined, valueType: PERCENTAGE}
  defaultRule = {degreeType: DEGREE_TYPES[0].value, fullTimeStatus: FULL_TIME_STATUSES[0].value, gpa: {...this.gpaDefault}, pace: {...this.paceDefault}}

  handleTabChange = (event, value) => {
    this.setState({value: value})
  }

  handleRulesChange = (idx) => (data) => {
    this.setState(state => ({
      ...state, data: [...state.data.slice(0, idx), data, ...state.data.slice(idx + 1)]
    }))
  }

  // TODO: add some smarts to degree type
  handleAddRules = () => {
    this.setState(state => ({
      ...state,
      data: [...state.data, {...this.defaultRule}],
      value: state.data.length
    }))
  }

  handleDeleteRules = () => {
    this.setState(state => ({
      ...state,
      data: [...state.data.slice(0, state.value), ...state.data.slice(state.value + 1)],
      value: Math.max(state.value - 1, 0)
    }))
  }

  handleSave = institutionId => () =>
    postData(
      `/api/admin/rules/${institutionId}`,
      this.state.data,
      () => alert('Success'),
      () => alert('Error')
    )

  render() {
    const hasRules = this.state.data.length > 0
    const addDisabled = this.state.data.length === MAX_RULES_COUNT
    const deleteDisabled = this.state.data.length === MIN_RULES_COUNT
    const saveDisabled = false

    return (
      <div className={this.props.classes.root}>
        <h2>Federal SAP requirements for {this.state.institutionName}</h2>

        {hasRules && <Tabs value={this.state.value}
              onChange={this.handleTabChange}
              classes={{
                root: this.props.classes.tabsRoot
              }}>
          {this.state.data.map((rules, idx) =>
            <Tab key={idx} label={`${getLabel(DEGREE_TYPES, rules.degreeType)} / ${getLabel(FULL_TIME_STATUSES, rules.fullTimeStatus)}`} />
          )}
        </Tabs>}

        {hasRules && <TabContainer>
          <RulesEditor
            data={this.state.data[this.state.value]}
            onChange={this.handleRulesChange(this.state.value)}
          />
        </TabContainer>}

        {!hasRules && <Typography gutterBottom>
          There are no rules for this institution, if saved, students won't be able to select it during registration
        </Typography>}

        <ActionButtons
          addDisabled={addDisabled}
          onAdd={this.handleAddRules}
          deleteDisabled={deleteDisabled}
          onDelete={this.handleDeleteRules}
          saveDisabled={saveDisabled}
          onSave={this.handleSave(this.props.match.params.institutionId)}
          institutionId={this.props.match.params.institutionId}
        />

        {/* TODO: Copy to other institution controls */}
      </div>
    )
  }
})

export default RulesEdit
