import * as React from 'react'

const InstitutionContext = React.createContext({})

export const InstitutionConsumer = InstitutionContext.Consumer

class InstitutionProvider extends React.Component {
  render() {
    return (
      <InstitutionContext.Provider value={{...this.props.institution}}>
        {this.props.children}
      </InstitutionContext.Provider>
    )
  }
}

export default InstitutionProvider

export const withInstitution = WrappedComponent => props =>
  <InstitutionConsumer>
    {value => <WrappedComponent institution={value} {...props} />}
  </InstitutionConsumer>
