import React from 'react'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { boxShadow } from '../../../styles/theme'
import {
  getLabel
} from '../rules'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  dropdown: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  inline: {
    display: 'inline-flex',
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.common.black}`
  },
  select: {
    boxShadow: boxShadow
  }
})

export const InlineMenu = withStyles(styles)(
  class extends React.Component {
    state = {
      anchorEl: null,
    };

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (value = null) => {
      this.setState({ anchorEl: null });
      value && this.props.onChange(value)
    };

    render() {
      const { anchorEl } = this.state;
      const {value, options} = this.props

      return (
        <>
          <Typography
            className={this.props.classes.inline}
            variant="h6"
            onClick={this.handleClick}
          >
            {getLabel(options, value)}
          </Typography>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => this.handleClose(null)}
            classes={{ paper: this.props.classes.select }}
          >
            {options.map(({value, label}) => 
              <MenuItem
                className={this.props.classes.dropdown}
                onClick={() => this.handleClose(value)}
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            )}
          </Menu>
        </>
      );
    }
  }
)
