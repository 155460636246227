import { FormControl, withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { pipe } from 'ramda'
import React from 'react'
import { Dropdown } from '../registration/common/dropdown.component'
import { Button } from '../common/button.component'
import { postData } from '../../utils/api-utils'
import { Input } from '../registration/common/input.component'
import { Section } from '../common/section.component'
import { withDataValidation } from '../registration/common/with-data-validation'
import DeleteIcon from '@material-ui/icons/Delete'
import Modal from "@material-ui/core/Modal"

const styles = theme => ({
  paper: {
    position: 'absolute',
    margin: 'auto',
    top: '80px',
    left: '16px',
    right: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    maxWidth: '960px',
    '&:focus': {
      outline: 'none'
    }
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const HelpDialog = pipe(
  withStyles(styles)
)(class extends React.Component {
  defaultData = {name: undefined, credits: undefined, targetGrade: undefined}

  constructor(props) {
    super(props)

    this.state = {
      data: this.defaultData,
      open: this.props.open
    }
  }

  open = (data) => this.setState({open: true, data: {...this.defaultData, ...data}})
  close = () => this.setState({open: false}, this.props.onClose)

  render() {
    const { classes } = this.props

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.close}
      >
        <div className={classes.paper}>
          <div className={classes.closeButton}>
            <CloseIcon nativeColor="#979797" onClick={this.close}/>
          </div>
          <div>
            <CourseForm
              grades={this.props.grades}
              data={this.state.data}
              refreshCourseData={this.props.refreshCourseData}
              close={this.close}
            />
          </div>
        </div>
      </Modal>
    )
  }
})

const CourseForm = pipe(
  withDataValidation({
    name: {},
    credits: {}
  })
)(class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.data,
      previousGrades: this.props.data.previousGrades || [],
      repeating: (this.props.data.previousGrades || []).length > 0,
      gradeOptions: this.props.grades.map(({label}) => ({value: label, label: label}))
    }
  }

  handleInputChange = inputName => event => this.setState({[inputName]: event.target.value})
  setRepeating = repeating => () => this.setState({repeating})
  addPreviousGrade = event => this.setState({previousGrades: [...this.state.previousGrades, event.target.value]})
  changePreviousGrade = idx => event => {
    const updatedGrades = this.state.previousGrades.slice(0)
    updatedGrades.splice(idx, 1, event.target.value)
    this.setState({previousGrades: updatedGrades})
  }

  deletePreviousGrade = idx => () => {
    const updatedGrades = this.state.previousGrades.slice(0)
    updatedGrades.splice(idx, 1)
    this.setState({previousGrades: updatedGrades})
  }

  handleUpdateClick = () => this.props.validate(this.state) && postData(
    '/api/courses' + (this.state.id ? `/${this.state.id}` : ''),
    this.state,
    () => {
      this.props.close()
      this.props.refreshCourseData()
    },
    (error) => this.setState({error})
  )

  render() {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          Add a course
        </Typography>
        <Section>
          <div style={{width: 272}}>
            <FormControl fullWidth>
              <TextField
                label="Course name"
                value={this.state.name || ''}
                onChange={this.handleInputChange('name')}
                error={Boolean(this.props.errors.name)}
                helperText={this.props.errors.name}
              />
            </FormControl>
          </div>
        </Section>

        <Section
          header="How many credits is this class?"
        >
          <Input
            value={this.state.credits}
            onChange={this.handleInputChange('credits')}
            error={this.props.errors.credits}
            placeholder='Ex. 2'
          />
        </Section>

        {
          this.state.repeating &&
          <Section header="What grades did you get previously?">
            {this.state.previousGrades.map((grade, idx) =>
              <div key={idx} style={{display: 'flex', alignItems: 'center', width: 272}}>
                <FormControl fullWidth>
                  <Dropdown
                    options={this.state.gradeOptions}
                    value={grade}
                    onChange={this.changePreviousGrade(idx)}
                  />
                </FormControl>
                <DeleteIcon onClick={this.deletePreviousGrade(idx)}/>
              </div>
            )}
            <FormControl fullWidth>
              <Dropdown
                placeholder="Add grade"
                options={this.state.gradeOptions}
                onChange={this.addPreviousGrade}
              />
            </FormControl>
          </Section>
        }

        <div style={{textAlign: 'center', width: 272}}>
          <Button
            text="Save"
            onClick={this.handleUpdateClick}
            variant="contained"
            color="secondary"
          />
        </div>
      </div>
    )
  }
})

export const withCourseDialog = WrappedComponent => {
  return class extends React.Component {
    dialog = null

    openDialog = (data) => () => this.dialog.open(data)

    render() {
      return (
        <>
          <HelpDialog
            innerRef={ref => this.dialog = ref}
            open={false}
            grades={this.props.grades}
            refreshCourseData={this.props.refreshCourseData}
          />
          <WrappedComponent {...this.props} openDialog={this.openDialog} />
        </>
      )
    }
  }
}