import { pipe } from 'ramda'
import React from 'react'
import { withConfirmationDialog } from '../common/with-confirmation-dialog.component'
import { deleteData } from '../../utils/api-utils'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { Row } from "../common/row.component"
import { TableCell } from "../common/table-cell.component"
import { IconButton, withStyles } from "@material-ui/core"

const styles = theme => ({
  actions: {
    display: 'flex'
  },
  button: {
    margin: `-12px 0`
  }
})

export const CourseItem = pipe(
  withStyles(styles),
  withConfirmationDialog,
)(({classes, openDialog, withConfirm, data, refreshCourseData}) => {

  const deleteCourse = () => withConfirm(
      () => deleteData(`/api/courses/${data.id}`,{}, refreshCourseData)
    )

  const {name, credits} = data

  return (
    <Row withBorder>
      <TableCell>{name}</TableCell>
      <TableCell center>{credits}</TableCell>
      <TableCell>
        <div className={classes.actions}>
          <IconButton onClick={openDialog(data)} classes={{root: classes.button}}>
            <EditIcon/>
          </IconButton>

          <IconButton onClick={deleteCourse} classes={{root: classes.button}}>
            <DeleteIcon/>
          </IconButton>
        </div>
      </TableCell>
    </Row>
  )
})