import { any, mapObjIndexed, values, has } from 'ramda'
import { isFunction } from 'ramda-adjunct'
import React from 'react'

export const withDataValidation = config => WrappedComponent =>
  class extends React.Component {
    state = {errors: {}, config}

    validate = (data) => {
      const errors = this._getErrors(data)
      this.setState({errors})

      return !any(m => m, values(errors))
    }

    validateField = (data, field) => {
      const errors = this._getErrors(data)
      this.setState({errors: {...this.state.errors, [field]: errors[field]}})

      return !Boolean(errors[field])
    }

    _getErrors = (data) =>
      mapObjIndexed(
        (num, key, obj) => {
          if (has(key, data) && data[key] !== 0 && !Boolean(data[key]) && obj[key].required !== false) return obj[key].msg || 'Required'
          if (isFunction(obj[key].guard) && !obj[key].guard(data)) return isFunction(obj[key].msg) ? obj[key].msg(data) : obj[key].msg
          return null
        },
        this.state.config
      )

    updateDataValidationConfig = config => this.setState({config})

    render() {
      return <WrappedComponent
        {...this.props}
        errors={this.state.errors}
        validate={this.validate}
        validateField={this.validateField}
        updateDataValidationConfig={this.updateDataValidationConfig}
      />
    }
  }