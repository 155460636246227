import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import { apiUrl } from '../../config'
import { TAP_RULES } from './tap-rules'
import { getLabel, RULE_VALUE_TYPES, SAP_EVALUATION_FREQUENCY_TYPES, AFTER_FALL_SPRING, GPA_RULE_TYPES, PACE_RULE_TYPES } from './rules'
import { timeAgo } from '../../utils/timeAgo'

const styles = theme => ({
  grid: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: 'darkgrey',
  },
  root: {
    ...theme.mixins.gutters(),
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    paddingBottom: theme.spacing.unit * 2
  },
  table: {
    minWidth: 700,
  },
  header: {
    display: 'flex',
  },
  editLink: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textDecoration: 'none'
  }
})

const EditButton = ({institutionId}) =>
  <Link to={`/admin/rules/${institutionId}/edit`} style={{textDecoration: 'none'}}>
    <Button size="small" variant="contained" color="primary">EDIT</Button>
  </Link>

const FinancialAidCard = withStyles(styles)(({classes, title, rulesComponent, institutionId, editable}) =>
  <div>
    <div className={classes.header}>
      <h2>{title}</h2>
      <div className={`${classes.editLink} no-print`}>
      { editable
        ? <EditButton institutionId={institutionId} />
        : <Button size="small" disabled variant="contained" color="primary">Cannot be edited</Button>
      }
      </div>
    </div>
    <div>
      {rulesComponent}
    </div>
  </div>
)

const SimpleTable = withStyles(styles)(({classes, title, headers, rows}) =>
  <Paper className={classes.root}>
    <h3>{title}</h3>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {headers.map(({title}, idx) => <TableCell key={idx}>{title}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, idx) =>
          <TableRow key={idx}>
            {headers.map(({key}, idx) =>
              <TableCell key={idx} component="th" scope="row">
                {row[key]}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Paper>
)

const tapHeaders = [
  {title: "Payment Number", key: "paymentNumber"},
  {title: "GPA", key: "gpa"},
  {title: "Earned Credits", key: "earnedCredits"},
  {title: "Completed Credits In Previous Semester", key: "previousSemesterCompletedCredits"},
]

const TapRules = () => {
  return (
    <>
      <div>
        <FlatRule title="Evaluation Frequency" value={getLabel(SAP_EVALUATION_FREQUENCY_TYPES, AFTER_FALL_SPRING)}/>
      </div>
      <SimpleTable
        title={"Bachelor Degree Requirements"}
        headers={tapHeaders}
        rows={TAP_RULES.bachelor}
      />
      <SimpleTable
        title={"Bachelor Degree Requirements for Opportunity Program & Remedial Students"}
        headers={tapHeaders}
        rows={TAP_RULES.bachelorInOpportunityProgram}
      />
      <SimpleTable
        title={"Associates' Degree Requirements"}
        headers={tapHeaders}
        rows={TAP_RULES.associate}
      />
      <SimpleTable
        title={"Associates Degree Requirements for Opportunity Program & Remedial Students"}
        headers={tapHeaders}
        rows={TAP_RULES.associateInOpportunityProgram}
      />
    </>
  )
}

const ValueType = ({valueType}) =>
  valueType !== undefined && <>{getLabel(RULE_VALUE_TYPES, valueType)}</>

const FlatRule = withStyles(styles)(({classes, title, value, valueType}) =>
  <Paper className={classes.root}>
    <h3>{title}</h3>
    <p>{value} <ValueType valueType={valueType}/></p>
  </Paper>
)

const RangeRule = withStyles(styles)(({classes, type, value, valueType, title, ruleTypes}) =>
  <Paper className={classes.root}>
    <h3>{title}</h3>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>{getLabel(ruleTypes, type)}</TableCell>
          <TableCell><ValueType valueType={valueType}/></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {value.map((row, idx) =>
          <TableRow key={idx}>
            <TableCell component="th" scope="row">
              {`${row.from} - ${row.to}`}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.value}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </Paper>
)

const SapRules = ({data}) => {
  const ruleComponent = ({type, value, valueType}, title, ruleTypes) =>
    type === 'FLAT'
      ? <FlatRule title={title} value={value} valueType={valueType}/>
      : <RangeRule title={title} type={type} value={value} valueType={valueType} ruleTypes={ruleTypes}/>

  return (
    <>
      {data.map(({degreeType, fullTimeStatus, evaluation, gpa, pace}) =>
        <div key={`${degreeType}/${fullTimeStatus}`}>
          {degreeType && <FlatRule title="Degree Type" value={degreeType}/>}
          {fullTimeStatus && <FlatRule title="Full Time Status" value={fullTimeStatus}/>}
          <div>
            <FlatRule title="Evaluation Frequency" value={getLabel(SAP_EVALUATION_FREQUENCY_TYPES, evaluation)}/>
          </div>
          <div>
            {ruleComponent({...gpa, valueType: 'GPA'}, 'GPA', GPA_RULE_TYPES)}
          </div>
          <div>
            {ruleComponent(pace, 'PACE', PACE_RULE_TYPES)}
          </div>
        </div>
      )}
    </>
  )
}

export const RulesSummary = withStyles(styles)(class extends React.Component {
  state = {}

  componentDidMount() {
    fetch(apiUrl(`/api/admin/rules/${this.props.match.params.institutionId}`))
      .then(response => response.json())
      .then(({data}) => this.setState({data}))
      .catch(error => {
        alert("Something went wrong!")
        console.log(error)
      })
  }

  render() {
    if (!this.state.data) return null
    const lastUpdated = (updatedAt, updatedBy) =>
      updatedAt !== null && updatedBy != null
        ? `Updated ${timeAgo(new Date(updatedAt))} by ${updatedBy.name}`
        : 'Not configured yet'

    return (
      <div>
        <div className={this.props.classes.header}>
          <div>
            <h1>Financial Aid rules for {this.state.data[0].institutionName}</h1>
            <h5>{lastUpdated(this.state.data[0].updatedAt, this.state.data[0].updatedBy)}</h5>
          </div>
        </div>
        {
          this.state.data[0].evaluation !== undefined && (this.state.data[0].evaluation !== null
            ? <FinancialAidCard
                title={'Federal Satisfactory Academic Progress Requirements'}
                rulesComponent={<SapRules data={this.state.data}/>}
                institutionId={this.props.match.params.institutionId}
                editable
              />
            : <FinancialAidCard
                title={'Federal Satisfactory Academic Progress Requirements'}
                rulesComponent={<div>Rules are missing</div>}
                institutionId={this.props.match.params.institutionId}
                editable
              />

          )
        }
        <FinancialAidCard title={'New York State TAP Grant Requirements'} rulesComponent={TapRules()}/>
        <FinancialAidCard title={'Excelsior Scholarship Requirements'}/>
      </div>
    )
  }
})