export const routes = {
  'GOOGLE_SIGN_IN': '/login',
  'ABOUT': '/about',
  'TERMS_OF_SERVICE': '/terms',
  'PRIVACY_POLICY': '/privacy',
  'HOMEPAGE': '/home',
  'DOCUMENTS_LIST': '/docs',
  'CUNY_REGISTRATION': '/cuny-registration',
  'CUNY_LOGIN': '/cuny',
  'CUNY_FORGOT_PASSWORD': '/cuny-forgot-password',
  'CUNY_RESEND_VERIFICATION': '/cuny-resend-verification',
  'CUNY_VERIFICATION': '/user/verify',
  'CUNY_RESET_PASSWORD': '/user/reset-password',
  'QUOTE': '/quote',
  'EXCELSIOR_LANDING': '/excelsior',
  'EXCELSIOR_REGISTRATION': '/excelsior-registration',
  'EXCELSIOR_ELIGIBILITY': '/excelsior-eligibility',
  'REGISTRATION': '/registration',
  'ELIGIBILITY_START': '/eligibility/start',
  'ELIGIBILITY_CALCULATING': '/eligibility/calculating',
  'COURSES_DEGREE_PICKER': '/eligibility/courses/degree',
  'COURSES_GRADE_PICKER': '/eligibility/courses/grades',
  'ELIGIBILITY_DASHBOARD': '/eligibility/dashboard',
  'AID_ELIGIBILITY_DETAILS': '/eligibility/details/:type',
  'DASHBOARD': '/dashboard',
  'AID_DASHBOARD_DETAILS': '/dashboard/details/:type',
  'TERM_ENROLLMENT': '/term-enrollment',
  'TERM_REVISION': '/term-revision',
  'COURSE_INFO': '/course-info',
  'ADMIN_LOGIN': '/admin/login',
  'ADMIN_RULES_EDITOR': '/admin/rules/:institutionId/edit',
  'ADMIN_RULES_SUMMARY': '/admin/rules/:institutionId',
  'ADMIN_INSTITUTIONS_LIST': '/admin/institutions',
  'ADMIN_SMS': '/admin/sms',
  'ADMIN_STUDENTS_LIST': '/admin/students',
  'ADMIN_STUDENTS_ALL_SNAPSHOTS_LIST': '/cbo/students/ALL',
  'ADMIN_CUNY': '/admin/cuny',
  'ADMIN_CUNY_STUDENTS': '/admin/cuny/students',
  'ADMIN_CUNY_ADMINS': '/admin/cuny/admins',
  'CBO_SNAPSHOT': '/cbo/eligibility/:programId/:cboStudentId',
  'CBO_STUDENTS': '/cbo/students/:programId',
  'CBO_CLAIM_PROFILE': '/claim-profile',
  'ROOT': '/'
}