import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { RiskIcon } from '../common/risk-icon.component'
import { Row } from '../common/row.component'
import { SubSection } from '../common/sub-section.component'
import { TableCell } from '../common/table-cell.component'
import { getFinancialAidByType } from '../registration/financial-aid/financial-aid-types'
import {withRouter} from "react-router-dom"
import { trackEvent } from "../../utils/intercom-analytics"

const styles = (theme) => ({
  marginBottom: {
    marginBottom: theme.spacing.unit * 2
  }
})

export const EligibilityStatus = pipe(
  withStyles(styles),
  withRouter
)(class extends React.Component {
  handleClick = ({aidGroup: type, nextSemesterMaxRisk, thisSemesterMaxRisk}) => {
    trackEvent('viewed-aid-risk-details', {
      sourcePage: this.props.detailsPath,
      aid: type,
      thisSemesterMaxRisk: thisSemesterMaxRisk,
      nextSemesterMaxRisk: nextSemesterMaxRisk
    })

    this.props.history.push(`/${this.props.detailsPath}/details/${getFinancialAidByType(type).link}`)
  }

  render() {
    const {classes, financialAid, title} = this.props

    return (
      <SubSection dataTour="eligibility_status" secondary noPaddingBottom marginBottom>
        <Typography variant='h5'>
          {title}
        </Typography>
        <Typography variant='body2' className={classes.marginBottom}>
          Click on each financial aid to learn more.
        </Typography>
        <Row>
          <TableCell heading>FINANCIAL AID</TableCell>
          <TableCell heading center>THIS SEMESTER</TableCell>
          <TableCell heading center>NEXT SEMESTER</TableCell>
        </Row>
        {financialAid.map(aid => {
          const {aidGroup: type, nextSemesterMaxRisk, thisSemesterMaxRisk} = aid

          return <EligibilityStatusRow
              key={type}
              type={type}
              onClick={() => this.handleClick(aid)}
              nextSemesterRisk={nextSemesterMaxRisk}
              thisSemesterRisk={thisSemesterMaxRisk === 'NA' ? 'NA' : thisSemesterMaxRisk}
          />
        })}
      </SubSection>
    )
  }
})

const EligibilityStatusRow = ({type, onClick, thisSemesterRisk, nextSemesterRisk}) =>
  <Row withDarkBorder withBackground onClick={onClick}>
    <TableCell>{getFinancialAidByType(type).name}</TableCell>
    <TableCell center><RiskIcon risk={thisSemesterRisk}/></TableCell>
    <TableCell center><RiskIcon risk={nextSemesterRisk}/></TableCell>
  </Row>
