import React from 'react'
import { pipe } from "ramda"
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import { routes } from "../routing/routes-paths"

const styles = theme => ({
  dialog: {
    padding: '40px',
    backgroundColor: theme.palette.background.torquoise,
  },
  quote: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white
  },
  author: {
    marginTop: theme.spacing.unit * 2
  }
})

export const Quote = pipe(
  withStyles(styles),
)(class extends React.Component {
  componentDidMount() {
    setTimeout(() => this.props.history.push(routes.ROOT), 5000)
  }

  render () {
    return (
      <Dialog
        fullScreen
        open
        classes={{paper: this.props.classes.dialog}}
      >
        <div className={this.props.classes.quote}>
          <Typography align="center" variant="h6" color="inherit" gutterBottom className="italic">
            "Don't let the fear of failure hold you back!
            Hope is not a good financial aid strategy"
          </Typography>
          <div className={this.props.classes.author}>
            <Typography variant="h6" color="inherit" className="italic">
              -Margo Wright
            </Typography>
          </div>
        </div>
      </Dialog>
    )
  }
})