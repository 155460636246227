import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { TopBarContainer } from '../layout/top-bar.component'
import { PrivateRoute } from '../routing/private-route.component'
import { ExcelsiorRoute } from './excelsior-route.component'
import { getRouteList } from "./routes-list"

const createRoute = (key, {type, path, component}) => {
  const RoutComponent = (type === "private" ? PrivateRoute : (type === "excelsior" ? ExcelsiorRoute : Route))

  return <RoutComponent key={key} path={path} component={component}/>
}

const createTopBarRoute = (key, {path, topBar: {landing, title, backPath} = {}}) => {
  const topBarContainer = <TopBarContainer landing={landing} title={title} backPath={backPath}/>

  return <Route key={key} path={path} render={() => topBarContainer}/>
}

const mainRoutes = getRouteList()
  .map((route, index) => createRoute(index, route))

const eligibilityRoutes = getRouteList("ELIGIBILITY")
  .map((route, index) => createRoute(index, route))

const cunyAdminRoutes = getRouteList("ADMIN_CUNY")
  .map((route, index) => createRoute(index, route))

const topBarRoutes = getRouteList()
  .flatMap((route, index) =>
    route.subRoutes
      ? route.subRoutes.map((subRoute, index) => createTopBarRoute(`${route.group}_${index}`, subRoute))
      : createTopBarRoute(index, route)
  )

export const TopBarRoutes = () =>
  <Switch>
    {topBarRoutes}
  </Switch>

export const MainRoutes = () =>
  <Switch>
    {mainRoutes}
  </Switch>

export const EligibilityRoutes = () =>
  <Switch>
    {eligibilityRoutes}
  </Switch>

export const CunyAdminRoutes = () =>
  <Switch>
    {cunyAdminRoutes}
  </Switch>