import React from 'react'
import { pipe } from "ramda"
import { withStyles } from "@material-ui/core"
import { Emoji } from '../common/emoji.component'
import { Header } from '../common/header.component'
import errorAnimation from '../../assets/images/error-animation.gif'
import { Section } from "../common/section.component"

const styles = theme => ({
  image: {
    width: '70%',
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto ${theme.spacing.unit * 5}px auto`
  }
})
export const ErrorPage = pipe(
  withStyles(styles)
)(({classes}) =>
  <Section>
    <Header
      title={<>Looks like something is not working <Emoji symbol="🤔"/></>}
      subtitle="We're currently working to fix this problem. Please check back later or reach out to us with the intercom button below for any questions."
    />
    <img src={errorAnimation} alt="Error animation" className={classes.image}/>
  </Section>
)