import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import classnames from "classnames"

const styles = theme => ({
  box: {
    display: 'flex',
    padding: theme.spacing.unit * 2.5,
    borderRadius: theme.spacing.unit * 0.5,
    color: theme.palette.primary.main
  },
  imageContainer: {
    width: theme.spacing.unit * 9,
    height: theme.spacing.unit * 9,
    marginRight: theme.spacing.unit * 2
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  fullWidth: {
    margin: '0 -20px',
    borderRadius: 0
  },
  carousel: {
    borderRadius: 0,
    width: '100vw'
  },
  title: {
    textTransform: 'uppercase'
  }
})

export const TipBox = pipe(
  withStyles(styles)
)(({classes, children, image, title, primary, secondary, fullWidth, carousel}) =>
  <div data-tour="did_you_know" className={classnames(
    classes.box, {
      [classes.primary]: primary,
      [classes.secondary]: secondary,
      [classes.fullWidth]: fullWidth,
      [classes.carousel]: carousel
    }
  )}>
    {image && <div className={classes.imageContainer}>{image}</div>}
    <div>
      <Typography className={classes.title} variant='subtitle2' component='p' color='inherit' gutterBottom>
        {title}
      </Typography>
      <Typography variant='subtitle1' component='p' color='inherit'>
        {children}
      </Typography>
    </div>
  </div>
)