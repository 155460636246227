import { withStyles } from '@material-ui/core'
import React from 'react'

const styles = theme => ({
  buttonLink: {
    color: theme.palette.primary.main,
    background: 'none',
    font: 'inherit',
    borderBottom: 'none',
    textDecoration: "underline",
    fontSize: "14px",
    fontStyle: "oblique",
    border: 'none',
    padding: 0,
    '&:hover': {
      textDecoration: 'none'
    },
    '&:focus': {
      textDecoration: 'none',
      outlineColor: theme.palette.primary.main
    }
  }
})

export const HelpLinkSmall = withStyles(styles)(({classes, text, onClick}) =>
  <button className={classes.buttonLink} onClick={onClick}>{text}</button>
)