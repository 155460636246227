import { withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import { ACTIONS, CATEGORIES } from '../../utils/google-analytics'
import { withTracking } from '../common/trackClicks'
import { sortByRequirementType } from '../common/requirements-utils'
import { withUser } from '../../providers/user.provider'
import { withApiOrLocalStorageData } from './withApiOrLocalStorageData'
import { checklistItems } from './checklist-items'
import { EXCELSIOR_REQUIREMENT_LABELS, EXCELSIOR_EDIT_LINK } from '../common/requirements-utils'
import { Row } from '../common/row.component'

const styles = theme => ({
  expansionPanel: {
    margin: '2px -20px',
    '&:before': {
      opacity: 0
    }
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.background.grey,
    padding: '0 20px'
  },
  pushLeft: {
    flex: '1',
    minWidth: '0'
  },
  pushRight: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit
  },
  editIcon: {
    paddingRight: theme.spacing.unit * 1.5
  },
  setGpaButton: {
    marginTop: theme.spacing.unit * 2
  }
})

export const ExcelsiorRequirements = pipe(
  withApiOrLocalStorageData('/api/excelsior-microsite/requirements', 'excelsiorRequirements'),
  withUser,
)(({data}) =>
  data && <div style={{marginTop: 16}}>
    <div style={{display: 'flex'}}>
      <Typography variant='body2' className='bold'>Requirement</Typography>
    </div>
    <div>
      {sortByRequirementType(data.requirements['201906'] || []).map(({type, requiredValue, rating, description}) =>
        <RequirementRow
          key={type}
          type={type}
          title={EXCELSIOR_REQUIREMENT_LABELS[type]}
          rating={rating}
          description={description}
          editLink={EXCELSIOR_EDIT_LINK[type]}
        />
      )}
      {checklistItems(data.relation).map(({key, title, eligibleInfo, notEligibleInfo}) =>
        <RequirementRow
          key={key}
          title={title}
          type={key}
          rating={data.checklist[key] ? 'LOW' : 'HIGH'}
          description={data.checklist[key] ? eligibleInfo : notEligibleInfo}
          editLink='/excelsior-registration/checklist'
        />
      )}
    </div>
  </div>
)

const RatingIcon = ({rating}) =>
  rating === 'LOW' || rating === 'MEDIUM'
    ? <CheckCircleIcon color='secondary' fontSize='small'/>
    : <CancelIcon color='error' fontSize='small'/>

const RequirementRow = pipe(
  withStyles(styles),
  withUser,
  withTracking(props => ({category: CATEGORIES.excelsior, action: ACTIONS.openRiskHelp, label: props.type}))
)(class extends React.Component {
  state = {expanded: false}

  handlePanelChange = () => this.setState({expanded: !this.state.expanded})

  render() {
    return (
      <ExpansionPanel
        expanded={this.state.expanded}
        onChange={this.props.track(this.handlePanelChange)}
        className={this.props.classes.expansionPanel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon fontSize="large"/>}
          className={this.props.classes.expansionPanelSummary}
        >
          <Row>
            <div className={this.props.classes.pushLeft}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className={this.props.classes.editIcon}>
                  <EditLink to={this.props.editLink} fontSize='small'/>
                </div>
                <Typography variant='body2'>{this.props.title}</Typography>
              </div>
            </div>
            <div className={this.props.classes.pushRight}>
              <RatingIcon rating={this.props.rating} className={this.props.classes.pushRight}/>
            </div>
          </Row>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <Typography variant="body2">{this.props.description}</Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
})

const EditLink = ({to}) =>
  <Link to={to} style={{textDecoration: 'none'}}>
    <EditIcon fontSize='small' nativeColor='rgba(0, 0, 0, 0.54)'/>
  </Link>