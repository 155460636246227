import { Typography, withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'

const styles = (theme) => ({
  bodyGrey: {
    background: theme.palette.grey[200],
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
})

export const GreyBox = pipe(
  withStyles(styles)
)(({classes, children}) =>
  <Typography variant="subtitle1" className={classes.bodyGrey}>
    {children}
  </Typography>
)