import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { withStyles } from '@material-ui/core'
import { pipe } from 'ramda'

const styles = theme => ({
  center: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})

export const Spinner = pipe(
  withStyles(styles)
)(
  ({classes}) =>
    <CircularProgress className={classes.center}/>
)