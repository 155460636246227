import { Card, CardContent, CardHeader, Chip, withStyles } from '@material-ui/core'
import { pipe } from 'ramda'
import React from 'react'

const styles = theme => ({
  grid: {
    marginBottom: theme.spacing.unit * 2
  },
  chip: {
    marginRight: theme.spacing.unit * 2
  }
})

const Title = pipe(
  withStyles(styles)
)(({classes, number, title}) =>
  <div>
    <Chip className={classes.chip} color="primary" label={number}/>
    {title}
  </div>
)

export const RuleStep = pipe(
  withStyles(styles)
)(({classes, stepNumber, title, children}) =>
  <Card className={classes.grid}>
  <CardHeader title={<Title number={stepNumber} title={title}/>}/>
  <CardContent>
    {children}
  </CardContent>
</Card>)