import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import * as classnames from 'classnames'
import { pipe } from 'ramda'
import React from 'react'
import { withOptionalHelpModal } from '../../help/with-help-modal'
import { HelpLinkSmall } from '../../help/help-link-small.component'
import { NavigationButtons } from './navigation-buttons.component'
import { ACTIONS, CATEGORIES } from '../../../utils/google-analytics'
import { withTracking } from '../../common/trackClicks'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  withSubTitle: {
    marginBottom: 0
  }
})

export const RegistrationStep = pipe(
  withStyles(styles),
  withOptionalHelpModal(props => props.helpModal),
  withTracking(props => ({category: CATEGORIES.onboarding, action: ACTIONS.openHelp, label: props.trackingLabel}))
)(({classes, stepTitle, title, subTitle, subTitleHelpText, openModal, children, handleBackClick, handleNextClick, nextLabel, track}) =>
    <div className={classes.root}>
      {stepTitle && <Typography variant="subtitle1" color="inherit">{stepTitle}</Typography>}
      <Typography
        variant="h3"
        color="inherit"
        className={classnames({[classes.withSubTitle]: Boolean(subTitle)})}
      >
        {title}
      </Typography>
      {subTitle && <Typography variant="body1">
        {subTitle} {subTitleHelpText && <HelpLinkSmall onClick={track(openModal)} text={subTitleHelpText}/>}
      </Typography>}
      <div className={classes.body}>
        {children}
      </div>
      <NavigationButtons handleBackClick={handleBackClick} handleNextClick={handleNextClick} nextLabel={nextLabel}/>
    </div>
)