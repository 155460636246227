import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import { pipe } from 'ramda'
import React from 'react'
import { boxShadow } from '../../../styles/theme'
import classnames from "classnames"
import Input from "@material-ui/core/Input"

const styles = theme => ({
  option: {
    backgroundColor: theme.palette.common.white,
    fontSize: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.background.grey,
    }
  },
  menu: {
    boxShadow: boxShadow,
  },
  narrow: {
    width: theme.spacing.unit * 6.5,
    textAlign: 'left',
  },
  wide: {
    width: theme.spacing.unit * 11,
    textAlign: 'left',
  },
  filledInput: {
    fontSize: 'inherit',
    backgroundColor: theme.palette.background.grey,
  },
  noBorder: {
    borderBottom: 'none'
  }
})

const FilledInput = withStyles(
  styles
)(({classes, ...props}) =>
  <Input {...props} className={classes.filledInput} disableUnderline />
)

export const Dropdown = pipe(
  withStyles(styles)
)(({classes, placeholder, displayEmpty, options, onChange, value, error, filled, disabled, narrow, wide, label}) =>
  <FormControl classes={{root: classes.noMargin}}>
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      displayEmpty={displayEmpty}
      onChange={onChange}
      value={value || ''}
      error={Boolean(error)}
      MenuProps={{classes: {paper: classes.menu}}}
      disabled={disabled}
      SelectDisplayProps={{style: {paddingLeft: 8}}}
      classes={{root: classnames({[classes.narrow]: narrow, [classes.wide]: wide})}}
      input={filled ? <FilledInput /> : <Input/>}
    >
      {placeholder && <MenuItem className={classes.option} value="" disabled>{placeholder}</MenuItem>}
      {options.map(({value, label}) =>
        <MenuItem className={classes.option} key={value} value={value}>{label}</MenuItem>
      )}
    </Select>
    {Boolean(error) && <FormHelperText error>{error}</FormHelperText>}
  </FormControl>
)