import * as classnames from 'classnames'
import React from 'react'
import { pipe } from 'ramda'
import { Link } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'

const styles = (theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  marginTop: {
    marginTop: theme.spacing.unit * 2
  },
  inline: {
    display: 'inline-block'
  }
})

export const TextLink = pipe(
  withStyles(styles)
)(({to, label, classes, target, noMarginTop, inline}) =>
  <div className={classnames({
    [classes.marginTop]: !Boolean(noMarginTop),
    [classes.inline]: inline
  })}>
    <Link to={to} className={classes.link} target={target}>
      <Typography variant="body2" color="inherit">{label}</Typography>
    </Link>
  </div>
)