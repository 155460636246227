import { pipe } from 'ramda'
import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  marginBottom: {
    marginBottom: `${theme.spacing.unit * 3}px`
  },
  noMargin: {
    margin: 0
  }
})

export const Header = pipe(
  withStyles(styles)
)(({classes, title, subtitle}) =>
  <div className={classes.marginBottom}>
    <Typography variant="h4" className={classes.noMargin}>{title}</Typography>
    <Typography variant="body2">{subtitle}</Typography>
  </div>
)