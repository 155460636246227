import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { pipe } from 'ramda'
import React from 'react'
import { withHelpModal } from '../help/with-help-modal'
import { NationalStudentLoanDatabaseHelp } from '../registration/help/national-student-loan-database-help.component'
import { Header } from '../common/header.component'
import downloadDocIcon from '../../assets/images/download-doc.svg'
import { ACTIONS, CATEGORIES } from '../../utils/google-analytics'
import { withTracking } from '../common/trackClicks'
import { routes } from '../routing/routes-paths'
import {ActionSection} from "../common/action-section.component"
import {LinkButton} from "../common/link-button.component"
import { Section } from "../common/section.component"

const styles = theme => ({
  docRow: {
    display: 'flex',
    padding: theme.spacing.unit
  },
  downloadDocIcon: {
    marginRight: theme.spacing.unit * 2
  }
})

const DocRow = withStyles(styles)(({classes, name, onClick}) =>
  <div className={classes.docRow} onClick={onClick}>
    <img src={downloadDocIcon} className={classes.downloadDocIcon} alt='Download Doc'/>
    <Typography variant="body2" color={onClick && 'primary'}>{name}</Typography>
  </div>
)

export const DocumentsList = pipe(
  withStyles(styles),
  withHelpModal(NationalStudentLoanDatabaseHelp),
  withTracking({category: CATEGORIES.onboarding, action: ACTIONS.openHelp, label: 'Documents list'}),
)(({openModal, track}) =>
  <Section fullHeight noMarginBottom>
    <Header
      title="Let's get started"
      subtitle="Download/print your documents"
    />

    <DocRow name="Unofficial transcript from your institution's website"/>
    <DocRow name="financial aid record from your institution's website"/>
    <DocRow name="MyStudentDownload from FAFSA website" onClick={track(openModal)}/>

    <ActionSection>
      <LinkButton to={routes.GOOGLE_SIGN_IN} label="I have all my documents" />
    </ActionSection>
  </Section>
)
