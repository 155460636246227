import Rollbar from 'rollbar'
import { environment, rollbarAccessToken, rollbarEnabled } from './config'

const rollbar = new Rollbar({
  accessToken: rollbarAccessToken,
  enabled: rollbarEnabled,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: 'unknown',
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
})

export default rollbar;
