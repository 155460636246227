import React from "react"
import {pipe} from "ramda"
import {withStyles} from "@material-ui/core"
import calculatorIllustration from "../../assets/images/calculator_illustration.png"
import {Header} from "../common/header.component"
import { routes } from "../routing/routes-paths"
import {ActionSection} from "../common/action-section.component"
import {LinkButton} from "../common/link-button.component"
import { Section } from "../common/section.component"


const styles = theme => ({
  image: {
    width: '70%',
    maxWidth: 280,
    display: 'block',
    margin: `${theme.spacing.unit * 3}px auto ${theme.spacing.unit * 5}px auto`
  }
})

export const EligibilityStart = pipe(
  withStyles(styles)
)(({classes}) =>
  <Section fullHeight noMarginBottom>
    <img src={calculatorIllustration} className={classes.image} alt="" />

    <Header
      title="Let's get started with the eligibility calculator"
      subtitle="Explore how your grades can affect financial aid eligibility"
    />
    <ActionSection>
      <LinkButton label="Start by setting your goal" to={routes.COURSES_DEGREE_PICKER} />
    </ActionSection>
  </Section>
)