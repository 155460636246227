import { Typography } from '@material-ui/core'
import React from 'react'
import { Input } from '../common/input.component'
import { SisLink } from '../common/sis-link.component'
import { TotalAmountLoanHelp } from '../help/total-amount-loan-help.component'
import { DependencyStatusHelp } from '../help/dependency-status-help.component'
import { Section } from '../../common/section.component'
import { YesNoButtons } from '../common/yes-no-buttons.component'

export const StaffordDetails = (
  {
    financialDependencyStatus, handleFinancialDependencyChange, subsidizedBorrowedTotal,
    handleSubsidizedBorrowedTotalChange, unsubsidizedBorrowedTotal, handleUnsubsidizedBorrowedTotalChange,
    borrowedThisSemester, handleBorrowedThisSemesterChange, errors
  }
) =>
  <>
    <Typography variant="h5">Stafford Loan</Typography>
    <Section
      header="For financial aid purposes, which are you considered?"
      helpText="How does this impact eligibility?"
      helpModal={DependencyStatusHelp}
      trackingLabel="Stafford - financial dependency"
    >
      <YesNoButtons
        value={financialDependencyStatus === 'INDEPENDENT'}
        noLabel="Dependent"
        yesLabel="Independent"
        handleYesClick={handleFinancialDependencyChange('INDEPENDENT')}
        handleNoClick={handleFinancialDependencyChange('DEPENDENT')}
      />
    </Section>
    <Section
      header="How much in subsidized loans have you borrowed?"
      helpText="Why is this important?"
      helpModal={TotalAmountLoanHelp}
      trackingLabel="Stafford - total subsidized amount"
    >
      <Input
        value={subsidizedBorrowedTotal}
        onChange={handleSubsidizedBorrowedTotalChange}
        error={errors.subsidizedBorrowedTotal}
        adornment={'$'}
      />
    </Section>
    <Section
      header="How much in unsubsidized loans have you borrowed?"
      helpText="Why is this important?"
      helpModal={TotalAmountLoanHelp}
      trackingLabel="Stafford - total unsubsidized amount"
    >
      <Input
        value={unsubsidizedBorrowedTotal}
        onChange={handleUnsubsidizedBorrowedTotalChange}
        error={errors.unsubsidizedBorrowedTotal}
        adornment={'$'}
      />
    </Section>
    <Section
      header="How much are you borrowing in federal subsidized or unsubsidized loans?"
      helpText={<SisLink text="Verify this on"/>}
      trackingLabel="Stafford - current amount"
    >
      <Input
        value={borrowedThisSemester}
        onChange={handleBorrowedThisSemesterChange}
        error={errors.borrowedThisSemester}
        adornment={'$'}
      />
    </Section>
  </>
